import calculateProgress from 'utils/calculateProgress'

const meta = {
  score: calculateProgress,

  fields: {
    applicable: {
      type: 'options',
      options: [true, false],
    },
    typeOfParticipation: {
      type: 'options',
      options: [
        'formal',
        'respresentational',
        'direct',
        'committees',
        'managementSystem',
        'other',
      ],
      visible: data => data.applicable,
    },
    otherDescription: {
      type: 'textArea',
      visible: data => data.typeOfParticipation.includes('other'),
    },
    committee: {
      label: 'Committee',
      type: 'section',
      visible: data => data.applicable,
      fields: {
        applicable: {
          type: 'options',
          options: [true, false],
        },
        responsibilities: {
          type: 'textArea',
          visible: data => data.committee.applicable,
        },
        meetingFrequency: {
          type: 'textArea',
          visible: data => data.committee.applicable,
        },
        authority: {
          type: 'textArea',
          visible: data => data.committee.applicable,
        },
        representation: {
          type: 'options',
          options: [true, false],
          visible: data => data.committee.applicable,
        },
        representationDescription: {
          type: 'textArea',
          visible: data => data.committee.representation === false,
        },
      },
    },
  },

  initialValues: {
    applicable: null,
    typeOfParticipation: [],
    otherDescription: '',
    committee: {
      applicable: null,
      responsibilities: '',
      meetingFrequency: '',
      authority: '',
      representation: null,
      representationDescription: '',
    },
  },
}

export default meta
