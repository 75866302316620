import { useState } from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import firebase from 'firebase/app'

import FormField from 'components/form/FormField'
import Button from 'components/Button'

const FormWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`

const StyledForm = styled(Form)`
  width: 30rem;
  max-width: 100%;
  text-align: right;
  p {
    text-align: left;
  }
`

const ErrorMessage = styled.p``

const LoginButton = styled(Button)`
  margin: 1rem 0;
`

const Login = ({ history, location }) => {
  const [error, setError] = useState()
  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      onSubmit={async ({ email, password }, { setSubmitting, resetForm }) => {
        try {
          await firebase.auth().signInWithEmailAndPassword(email, password)
          const nextLocation =
            location.state.redirect === undefined ||
            location.state.redirect.includes('login')
              ? '/'
              : location.state.redirect
          history.replace(nextLocation)
        } catch (e) {
          console.error(e)
          setError(e)
        }
      }}
    >
      {({ isSubmitting }) => (
        <FormWrapper>
          <StyledForm>
            <p>Welcome to the Sciigo/Ethos GRI Data Entry App</p>
            <FormField name="email" type="email" label="Email" />
            <FormField name="password" type="password" label="Password" />
            <LoginButton disabled={isSubmitting} type="submit">
              Login
            </LoginButton>
            {error && <ErrorMessage>{error.message}</ErrorMessage>}
          </StyledForm>
        </FormWrapper>
      )}
    </Formik>
  )
}

export default Login
