export { default as get } from 'lodash.get'

export const and =
  (...fns) =>
  (...args) => {
    const result = fns.map(fn => fn(...args)).filter(Boolean)
    return result.length ? result.join('\n') : undefined
  }
export const or =
  (...fns) =>
  (...args) => {
    const result = fns.map(fn => fn(...args)).filter(Boolean)
    return result.length === fns.length ? result.join(' or ') : undefined
  }

export const required = (value, _, meta, prefix) =>
  (Array.isArray(value) && value.length === 0) || value === '' || value == null
    ? `value is required`
    : undefined

export const min = minValue => (value, _, meta) =>
  value < minValue ? `value should be more than ${minValue}` : undefined

export const max = maxValue => (value, _, meta) =>
  value > maxValue ? `value should be less than ${maxValue}` : undefined

export const branch =
  (predicate, a, b = () => undefined) =>
  (...args) =>
    predicate(...args) ? a(...args) : b(...args)
