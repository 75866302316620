import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'

const Wrapper = styled.footer`
  height: 3rem;
  background: ${theme('colors.medium')};
  z-index: 3;
`

const Footer = () => <Wrapper></Wrapper>

export default Footer
