import styled from 'styled-components'
import { ifProp, theme } from 'styled-tools'
import Card from 'components/Card'

const StyledDiv = styled.div`
  margin: 1rem 0;
`

const StyledH3 = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`

const StyledUl = styled.ul`
  padding: 0;
  margin: 0;
`

const StyledLi = styled.li`
  list-style: none;
  display: flex;
  text-align: left;
  align-items: stretch;
`

const Label = styled.p`
  font-weight: bold;
  width: 30%;
  flex-shrink: 0;
  background-color: ${theme('colors.light')};
  border-bottom: 1px solid ${theme('colors.border')};
  ${ifProp('last', 'border-bottom: none;', '')}
  margin: 0;
  padding: 0.5rem;
`

const Definition = styled.p`
  margin: 0;
  padding: 0.5rem;
`

const Introduction = props => (
  <Card title="302 Energy TR">
    <StyledDiv>
      <b>Note:</b> Avoid the double-counting of fuel consumption, when reporting
      self-generated energy consumption. If the organization generates
      electricity from a non-renewable or renewable fuel source and then
      consumes the generated electricity, the energy consumption shall be
      counted once under fuel consumption. Only report energy consumed by
      entities owned or controlled by the organization
    </StyledDiv>
    <StyledH3>Definitions</StyledH3>
    <StyledUl>
      <StyledLi>
        <Label>Fuel consumption</Label>
        <Definition>
          Can include fuel for combustion in boilers, furnaces, heaters,
          turbines, flares, incinerators, generators and vehicles that are{' '}
          <em>owned or controlled by the organisation</em>.
        </Definition>
      </StyledLi>
      <StyledLi>
        <Label>Non-renewable fuel or energy</Label>
        <Definition>
          Energy or fuels from fossil fuels such as oil, coal and natural gas.
        </Definition>
      </StyledLi>
      <StyledLi>
        <Label last="true">Renewable fuel or energy</Label>
        <Definition>
          Energy or fuels from renewable sources such as wind, sun, water, and
          biofuels and biomass.
        </Definition>
      </StyledLi>
    </StyledUl>
  </Card>
)

export default Introduction
