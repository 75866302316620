import React from 'react'
import QualitativeFormResults from './QualitativeFormResults'
import DataTableFormResults from './DataTableFormResults'

export { default as RespondentSelectSection } from './RespondentSelectSection'

const UnknownFormResults = ({ type }) => (
  <div>No form results for type {type}</div>
)

const formResults = {
  qualitative: QualitativeFormResults,
  dataTable: DataTableFormResults,
}

const FormResults = ({ id, type, form, meta, data, respondent, t }) =>
  React.createElement(formResults[type] ?? UnknownFormResults, {
    id,
    type,
    form,
    meta,
    data,
    respondent,
    t,
  })

export default FormResults
