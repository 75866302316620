import enrichDraft from 'utils/enrichDraft'
import calculateProgress from 'utils/calculateProgress'

const getProgress = (sectionKey, formKey) => data => ({
  type: 'progress',
  value:
    Object.keys(sections[sectionKey].forms[formKey].fields)
      .map(k => +!!data?.[k])
      .reduce((a, b) => a + b, 0) /
    Object.keys(sections[sectionKey].forms[formKey].fields).length,
})

export const sections = enrichDraft({
  waste: {
    type: 'static',
    forms: {
      generation: {
        initialValues: {
          inputs: '',
          outputs: '',
          activities: '',
          scope: '',
        },
        score: getProgress('waste', 'generation'),
        fields: {
          inputs: {
            type: 'textArea',
          },
          outputs: {
            type: 'textArea',
          },
          activities: {
            label: 'Activities',
            type: 'textArea',
          },
          scope: {
            type: 'textArea',
          },
        },
      },
      management: {
        score: calculateProgress,
        initialValues: {
          ownActions: '',
          upstreamActions: '',
          downStreamActions: '',
          thirdPartyApplicable: false,
          thirdParty: '',
          dataCollection: '',
        },
        fields: {
          ownActions: {
            type: 'textArea',
          },
          upstreamActions: {
            type: 'textArea',
          },
          downStreamActions: {
            type: 'textArea',
          },
          thirdPartyApplicable: {
            type: 'options',
            options: [
              { value: true, label: 'Yes' },
              { value: false, label: 'No' },
            ],
          },
          thirdParty: {
            type: 'textArea',
            visible: ({ thirdPartyApplicable }) => {
              console.log(`Third party applicable is ${thirdPartyApplicable}`)
              return thirdPartyApplicable
            },
          },
          dataCollection: {
            type: 'textArea',
          },
        },
      },
      generated: {
        initialValues: {
          hazardous: { amount: '', unit: null },
          nonHazardous: { amount: '', unit: null },
        },
        score: data => ({
          type: 'quantity',
          value: Object.values(data ?? {})
            .map(item =>
              isNaN(parseFloat(item.amount)) ? 0 : parseFloat(item.amount) ?? 0
            )
            .reduce((a, b) => a + b, 0),
          unit: 'tonnes',
        }),
        fields: {
          hazardous: {
            type: 'amount',
            options: [{ label: 'tonnes', value: 'tonnes' }],
          },
          nonHazardous: {
            type: 'amount',
            options: [{ label: 'tonnes', value: 'tonnes' }],
          },
        },
      },
      dataSource: {
        score: calculateProgress,
        initialValues: {
          dataCollectionMethod: '',
        },
        fields: {
          dataCollectionMethod: {
            type: 'textArea',
          },
        },
      },
    },
  },
})
