import enrichDraft from 'utils/enrichDraft'

export const sections = enrichDraft({
  materials: {
    type: 'static',
    forms: {
      materialsUsed: {
        score: data =>
          data !== undefined
            ? {
                type: 'quantity',
                value: [
                  data.materialsUsed.renewable.amount,
                  data.materialsUsed.nonRenewable.amount,
                ]
                  .map(Number)
                  .reduce((acc, cur) => acc + cur),
                unit: 'kg',
              }
            : undefined,
        initialValues: {
          doMeasure: null,
          materialsUsed: {
            nonRenewable: {
              amount: '',
              unit: null,
            },
            renewable: {
              amount: '',
              unit: null,
            },
          },
          description: '',
          dataSource: '',
        },
        fields: {
          doMeasure: { type: 'options', options: [true, false] },
          materialsUsed: {
            type: 'section',
            visible: ({ doMeasure }) => doMeasure,
            fields: {
              nonRenewable: { type: 'amount', options: ['kg'] },
              renewable: { type: 'amount', options: ['kg'] },
            },
          },
          description: {
            type: 'textArea',
            visible: ({ doMeasure }) => doMeasure,
          },
          dataSource: {
            type: 'options',
            options: ['data', 'estimated'],
            visible: ({ doMeasure }) => doMeasure,
          },
        },
      },
      recycledMaterialsUsed: {
        score: data =>
          data !== undefined
            ? {
                type: 'quantity',
                value: Number(data.materialsUsed.amount),
                unit: 'kg',
              }
            : undefined,
        initialValues: {
          doMeasure: null,
          materialsUsed: {
            amount: '',
            unit: null,
          },
          description: '',
          dataSource: '',
        },
        fields: {
          doMeasure: { type: 'options', options: [true, false] },
          materialsUsed: {
            visible: ({ doMeasure }) => doMeasure,
            type: 'amount',
            options: ['kg'],
          },
          description: {
            type: 'textArea',
            visible: ({ doMeasure }) => doMeasure,
          },
          dataSource: {
            type: 'options',
            options: ['data', 'estimated'],
            visible: ({ doMeasure }) => doMeasure,
          },
        },
      },
    },
  },
})
