import _t from 'utils/translation'
import BaseTemplate from '../BaseTemplate.jsx'
import { sections } from '../../Draft/416/meta'

const resultSections = {
  quantitative: {
    id: 'customerHealthAndSafety',
    forms: {
      significantItems: {
        title: 'Significant Items',
        type: 'dataTable',
        tableMeta: {
          fields: {
            numberOfSignificantItems: {
              label: '# significant items',
              format: 'number',
            },
            numberOfAssessedSignificantItems: {
              label: '# assessed significant itmes',
              format: 'number',
            },
            assessedPercentage: {
              label: '% assessed',
              format: 'percentage',
              fn: ({
                numberOfSignificantItems,
                numberOfAssessedSignificantItems,
              }) => numberOfAssessedSignificantItems / numberOfSignificantItems,
            },
            respondentEmail: { label: 'Respondent' },
            tag: { label: 'Tag' },
          },
          columns: [
            'numberOfSignificantItems',
            'numberOfAssessedSignificantItems',
            'assessedPercentage',
          ],
          rowKeys: ['respondentEmail', 'tag'],
          columnTotals: true,
        },
        transformData: report => report.customerHealthAndSafety,
      },
      incidents: {
        title: 'Incidents of Non-Compliance',
        type: 'dataTable',
        tableMeta: {
          fields: {
            resultingInPenalty: {
              label: 'Resulting in penalty',
              format: 'number',
            },
            resultingInWarning: {
              label: 'Resulting in warning',
              format: 'number',
            },
            voluntaryCodes: { label: 'Voluntary Codes', format: 'number' },
            respondentEmail: { label: 'Respondent' },
            tag: { label: 'Tag' },
          },
          columns: [
            'resultingInPenalty',
            'resultingInWarning',
            'voluntaryCodes',
          ],
          rowKeys: ['respondentEmail', 'tag'],
          columnTotals: true,
        },
        transformData: report => report.incidents.numberOfIncidents,
      },
    },
  },
  qualitative: {
    id: 'customerHealthAndSafety',
    respondentSelect: true,
    forms: {
      customerHealthAndSafety: {
        type: 'qualitative',
        fields: {
          numberOfSignificantItems: { visible: false },
          numberOfAssessedSignificantItems: { visible: false },
        },
      },
      // incidents: {
      //   type: 'qualitative',
      //   fields: {
      //   }
      // }
    },
  },
}

const Template = ({ projectId, data }) => (
  <BaseTemplate
    t={_t(`drafts.doc416.sections`)}
    meta={sections}
    resultSections={resultSections}
    data={data}
  />
)

export default Template
