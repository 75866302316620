import { useState } from 'react'
import styled from 'styled-components'
import { ifProp, theme } from 'styled-tools'
import { firebase } from 'providers/Firestore'
import { Container } from 'components/Layout'
import Card from 'components/Card'
import MetaForm from 'components/MetaForm'
import { IconButton } from 'components/Button'
import _t from 'utils/translation'
import Section from './Section'
import summarizeItem from './summarizeItem'
import getAsideItem from './getAsideItem'
import useDownloadMeta from './useDownloadMeta' //eslint-disable-line no-unused-vars
import Wrapper from './Wrapper'
import Main from './Main'
import useAuth from 'providers/useAuth'
import SubmissionControl from './SubmissionControl'
import { getNextStatus } from 'components/Template/Status'

export const Aside = styled.aside`
  width: 27rem;
  box-shadow: -2px 2px 10px 2px ${theme('colors.border')};
  background: white;
  height: 100%;
  padding: 2rem 1rem;
  transform: translateX(${ifProp('visible', 0, '100%')});
  transition: 0.3s transform;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  overflow-y: auto;
`

const CloseButton = styled(IconButton)`
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  padding: 0;
  height: 2rem;
  width: 2rem;
`

const BaseTemplate = ({
  children,
  meta,
  data,
  update,
  className,
  projectMeta,
  t,
  submit,
  disabled,
  ...props
}) => {
  //useDownloadMeta(data._type, meta)
  t ??= _t

  const { user } = useAuth()
  const [aside, setAside] = useState()
  const openSection = sectionId => id => setAside({ sectionId, id })
  const asideType = aside ? meta[aside.sectionId].type : undefined

  const save = newData => {
    const formKey = asideType === 'list' ? aside.sectionId : aside?.id
    if (props.save !== undefined) {
      props.save(asideType)(formKey)(newData)
    } else {
      const newStatus = user
        ? ['readyForReview', 'reviewing'].includes(data._status)
          ? 'reviewing'
          : undefined
        : [undefined, 'new', 'started', 'changeRequired'].includes(data._status)
        ? 'started'
        : undefined

      if (newStatus === undefined) {
        console.error(`Illegal status/login combination (${data._status})`)
        return
      }

      const draftMeta = {
        _status: newStatus,
        _modified: firebase.firestore.FieldValue.serverTimestamp(),
      }

      if (asideType === 'static') {
        update({
          [`${formKey}`]: {
            ...newData,
          },
          ...draftMeta,
        })
      } else if (asideType === 'list') {
        update({
          [`${formKey}.items.${newData.id}`]: {
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            ...newData,
          },
          ...draftMeta,
        })
      }
    }
    setAside()
  }

  const deleteItem = sectionId => id => {
    if (props.deleteItem !== undefined) {
      props.deleteItem(sectionId)(id)
    } else {
      const newStatus = getNextStatus(data._status, !!user)
      if (newStatus !== undefined) {
        update({
          [`${sectionId}.items.${id}`]: firebase.firestore.FieldValue.delete(),
          _status: newStatus,
        })
      }
    }
  }

  const summaryData = Object.entries(meta).map(
    ([sectionId, { type, visible }]) => ({
      id: sectionId,
      type,
      title: t(`sections.${sectionId}.title`),
      subtitle: t(`sections.${sectionId}.subtitle`),
      description: t(`sections.${sectionId}.description`),
      onEdit: openSection(sectionId),
      onDelete: props.deleteItem === false ? undefined : deleteItem(sectionId),
      data: summarizeItem(data, sectionId, meta, projectMeta, t),
      visible,
    })
  )

  const asideData = aside
    ? {
        id: aside.id,
        save: save,
        ...getAsideItem(aside, data, meta, projectMeta, t),
      }
    : undefined

  return (
    <Wrapper className={className}>
      <Main>
        <Container>
          <Card
            title={t(`title`)}
            subtitle={t(`subtitle`)}
            description={t(`description`)}
          >
            {submit && (
              <SubmissionControl
                respondent={data._respondent}
                status={data._status}
                reviewer={data._reviewer}
                reviewerNote={data._reviewerNote}
                user={user}
                submit={({ approved, message } = {}) =>
                  () => {
                    submit(data, { approved, message })
                  }}
              />
            )}
          </Card>
          {children}
          {summaryData.map(props =>
            props.visible?.({ _type: props.id }, projectMeta) !== false ? (
              <Section key={props.id} disabled={disabled} {...props} />
            ) : null
          )}
        </Container>
        <Aside visible={aside != null}>
          <CloseButton inverted icon="close" onClick={() => setAside()} />
          {aside && (
            <MetaForm key={asideData.id} disabled={disabled} {...asideData} />
          )}
        </Aside>
      </Main>
    </Wrapper>
  )
}

export default BaseTemplate
