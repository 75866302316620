import styled from 'styled-components'
import { BaseTemplate } from 'components/Template'
import { sections } from './meta.js'

const StyledBaseTemplate = styled(BaseTemplate)`
  aside {
    max-width: 60vw;
    width: 60rem;
  }
`

const Template = props => <StyledBaseTemplate meta={sections} {...props} />

export default Template
