import React from 'react'
import styled from 'styled-components'
import { Switch, Route } from 'react-router-dom'
import PrivateRoute from './components/PrivateRoute'
import Header from './components/Header'
import Footer from './components/Footer'
import {
  Home,
  //  ApiCheck,
  Draft,
  RespondentOverview,
  Results,
  Login,
} from './routes'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
`

const Main = styled.main`
  flex-grow: 1;
  overflow: hidden;
`

function App() {
  return (
    <Wrapper>
      <Header />
      <Main>
        <Switch>
          <Route path="/:projectId/drafts/:draftId" component={Draft} />
          <PrivateRoute
            path="/:projectId/respondents"
            component={RespondentOverview}
          />
          <PrivateRoute
            path="/:projectId/results/:sheetType"
            component={Results}
          />
          <PrivateRoute path="/:projectId/results" component={Results} />
          {/*<Route path="/api/" component={ApiCheck} />*/}
          <Route path="/login" component={Login} />
          <PrivateRoute path="/" component={Home} />
        </Switch>
      </Main>
      <Footer />
    </Wrapper>
  )
}

export default App
