const validateObject = (value, context, meta, prefix) => {
  const result = Object.entries(value).reduce((acc, [key, item]) => {
    const invalid = validate(
      item,
      context,
      meta ? meta.fields[key] : null,
      prefix ? `${prefix}.${key}` : key
    )
    if (invalid) {
      acc[key] = invalid
    }
    return acc
  }, {})
  return Object.keys(result).length ? result : undefined
}

const validate = (value, context, meta, prefix) => {
  if (!meta) {
    return undefined
  }
  if (meta.visible && !meta.visible(context)) {
    return undefined
  }

  if (value instanceof Object && !Array.isArray(value)) {
    return validateObject(value, context, meta, prefix)
  }
  if (meta.validate) {
    const result = meta.validate(value, context, meta, prefix)
    if (result) {
      return result
    }
  }
  if (meta.type === 'int' && value !== '' && !Number.isInteger(value)) {
    return 'value should be integer'
  }
  if (
    ['int', 'number'].includes(meta.type) &&
    value !== '' &&
    (typeof value !== 'number' || !isFinite(value))
  ) {
    return 'value should be a valid number'
  }
  return undefined
}

const validateByMeta =
  (meta, prefix = '') =>
  values =>
    validate(values, values, { fields: meta }, prefix)

export default validateByMeta
