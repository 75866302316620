import { useFirestore } from 'providers/Firestore'
import Loader from 'components/Loader'
import ResultsOverview from './ResultsOverview'
import ResultsView from './ResultsView'
import groupBy from 'lodash.groupby'

const formatType = t => (isFinite(t) ? `doc${t}` : t)

const newestReports = (data, type) =>
  Object.values(
    groupBy(
      data.filter(d => formatType(type) === formatType(d._type)),
      'draftId'
    )
  ).map(reports => reports.sort((a, b) => b.timestamp - a.timestamp)[0])

const Results = ({ match }) => {
  const { projectId, sheetType } = match.params

  const { data: reports, loading: loadingReports } = useFirestore(
    `projects/${projectId}/reports`
  )

  const { data: project, loading: loadingProject } = useFirestore(
    `projects/${projectId}`
  )

  return loadingReports || loadingProject ? (
    <Loader />
  ) : reports ? (
    sheetType ? (
      <ResultsView
        projectId={projectId}
        projectMeta={project._meta}
        type={sheetType}
        data={newestReports(reports, sheetType)}
      />
    ) : (
      <ResultsOverview
        projectId={projectId}
        projectMeta={project._meta}
        data={reports}
      />
    )
  ) : (
    <p>Nothing</p>
  )
}

export default Results
