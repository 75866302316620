import { validateByMeta, required } from 'utils/validation'
import calculateProgress from 'utils/calculateProgress'

const meta = {
  score: calculateProgress,

  initialValues: {
    injuries: {
      applicable: null,
      relatedTo: [],
      numberOfEmployees: {
        highConsequence: '',
        fatalities: '',
        total: '',
        description: '',
      },
      numberOfWorkers: {
        highConsequence: '',
        fatalities: '',
        total: '',
        description: '',
      },
    },
    hoursWorked: {
      employees: '',
      workers: '',
    },
    hazards: {
      applicable: null,
      description: '',
      process: '',
      contribution: '',
      mitigation: '',
    },
    workersExcluded: null,
    workersExcludedDescription: '',
    dataSource: '',
  },

  fields: {
    injuries: {
      type: 'section',
      fields: {
        applicable: {
          type: 'options',
          options: [true, false],
          validate: required,
        },
        relatedTo: {
          type: 'options',
          visible: data => data.injuries.applicable,
          options: ['employees', 'workers'],
          validate: required,
        },
        numberOfEmployees: {
          type: 'section',

          visible: data => data.injuries.relatedTo.includes('employees'),
          fields: {
            highConsequence: {
              type: 'int',
            },
            fatalities: {
              type: 'int',
            },
            total: {
              type: 'int',
            },
            description: {
              type: 'textArea',
              validate: required,
            },
          },
        },
        numberOfWorkers: {
          type: 'section',

          visible: data => data.injuries.relatedTo.includes('workers'),
          fields: {
            highConsequence: {
              type: 'int',
            },
            fatalities: {
              type: 'int',
            },
            total: {
              type: 'int',
            },
            description: {
              type: 'textArea',
              validate: required,
            },
          },
        },
      },
    },
    hoursWorked: {
      type: 'section',
      fields: {
        employees: {
          type: 'int',
        },
        workers: {
          type: 'int',
        },
      },
    },
    hazards: {
      type: 'section',

      fields: {
        applicable: {
          type: 'options',
          options: [true, false],
          validate: required,
        },
        description: {
          type: 'textArea',
          visible: data => data.hazards.applicable,
          validate: required,
        },
        process: {
          type: 'textArea',
          visible: data => data.hazards.applicable,
        },
        contribution: {
          type: 'textArea',
          visible: data => data.hazards.applicable,
        },
        mitigation: {
          type: 'textArea',
          visible: data => data.hazards.applicable,
        },
        otherMitigation: {
          type: 'textArea',
          visible: data => data.hazards.applicable,
        },
      },
    },
    workersExcluded: {
      type: 'options',
      options: [true, false],
      visible: () => true,
      validate: required,
    },
    workersExcludedDescription: {
      type: 'textArea',
      visible: ({ workersExcluded }) => workersExcluded,
    },
    dataSource: {
      type: 'textArea',
    },
  },
}
meta.validate = validateByMeta(meta.fields)

export default meta
