const meta = {
  fields: {
    memberships: {
      type: 'textArea',
    },
    externalInitiatives: {
      type: 'textArea',
    },
  },
  initialValues: {
    memberships: '',
    externalInitiatives: '',
  },
}

export default meta
