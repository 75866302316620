const meta = {
  fields: {
    statement: {
      type: 'textArea',
    },
  },
  initialValues: {
    statement: '',
  },
}

export default meta
