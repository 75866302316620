import styled from 'styled-components'
import { ifProp, theme } from 'styled-tools'

import Icon from './Icon'

const getColor =
  (isBackground = false, backgroundColor = theme('colors.white')) =>
  props => {
    const color = props.theme.colors[props.kind ?? 'primary']
    return isBackground !== (props.inverted ?? false) ? color : backgroundColor
  }

const Button = styled.button.attrs(({ type }) => ({
  type: type || 'button',
}))`
  margin: 1rem;
  background: ${getColor(true)};
  border: 1px solid;
  border-color: ${getColor(false)};
  color: ${getColor(false)};
  opacity: ${ifProp('disabled', 0.5, 1)};
`

const StyledButton = styled(Button)`
  display: block;
  width: 3rem;
  height: 3rem;
  padding: 0;
  flex-shrink: 0;
`

export const IconButton = ({ icon, ...props }) => (
  <StyledButton {...props}>
    <Icon type={icon} />
  </StyledButton>
)

export default Button
