const meta = {
  fields: {
    numberOfSuppliers: {
      type: 'int',
    },
    types: {
      type: 'textArea',
    },
    changes: {
      type: 'textArea',
    },
  },
  initialValues: {
    numberOfSuppliers: '',
    types: '',
    changes: '',
  },
}

export default meta
