import countries from '../../countries'

export const type = 'list'

export const initialValues = {
  method: null,
  activity: null,
  country: null,
  amount: { amount: '', unit: null },
  emissionFactor: '',
  dataSource: null,
}

// Weights in kg
const weights = {
  cooling: {
    GB: 0.17606,
    AU: 0.148,
    BE: 0.139,
    BG: 0.514,
    HR: 0.303,
    CY: 0.637,
    CZ: 0.59,
    DK: 0.191,
    EE: 0.872,
    FI: 0.185,
    FR: 0.053,
    DE: 0.488,
    GR: 0.62,
    HU: 0.304,
    IS: 0,
    IE: 0.442,
    IT: 0.352,
    LV: 0.244,
    LT: 0.534,
    LU: 0.2,
    MT: 0.761,
    NL: 0.459,
    NO: 0.011,
    PL: 0.836,
    PT: 0.383,
    RO: 0.525,
    SK: 0.161,
    SI: 0.348,
    ES: 0.309,
    SE: 0.012,
    CH: 0.016,
    OTH: 0.17606,
  },
  electricity: {
    GB: 0.02556,
    AU: 0.148,
    BE: 0.139,
    BG: 0.514,
    HR: 0.303,
    CY: 0.637,
    CZ: 0.59,
    DK: 0.191,
    EE: 0.872,
    FI: 0.185,
    FR: 0.053,
    DE: 0.488,
    GR: 0.62,
    HU: 0.304,
    IS: 0,
    IE: 0.442,
    IT: 0.352,
    LV: 0.244,
    LT: 0.534,
    LU: 0.2,
    MT: 0.761,
    NL: 0.459,
    NO: 0.011,
    PL: 0.836,
    PT: 0.383,
    RO: 0.525,
    SK: 0.161,
    SI: 0.348,
    ES: 0.309,
    SE: 0.012,
    CH: 0.016,
    OTH: 0.2556,
  },
  heating: {
    GB: 0.17606,
    AU: 0.148,
    BE: 0.139,
    BG: 0.514,
    HR: 0.303,
    CY: 0.637,
    CZ: 0.59,
    DK: 0.191,
    EE: 0.872,
    FI: 0.185,
    FR: 0.053,
    DE: 0.488,
    GR: 0.62,
    HU: 0.304,
    IS: 0,
    IE: 0.442,
    IT: 0.352,
    LV: 0.244,
    LT: 0.534,
    LU: 0.2,
    MT: 0.761,
    NL: 0.459,
    NO: 0.011,
    PL: 0.836,
    PT: 0.383,
    RO: 0.525,
    SK: 0.161,
    SI: 0.348,
    ES: 0.309,
    SE: 0.012,
    CH: 0.016,
    OTH: 0.17606,
  },
  steam: { GB: 0.17606, OTH: 0.17606 },
}

// Returns CO2e in tonnes
export const calculateScore = ({
  activity,
  method,
  amount,
  country,
  emissionFactor,
}) => {
  // Global
  if (['estimated', 'provided'].includes(method)) {
    return amount.amount
  } else {
    if (!!emissionFactor) {
      return (emissionFactor * amount.amount) / 1000
    } else if (weights[activity][country] != null) {
      return (weights[activity][country] * amount.amount) / 1000
    } else {
      console.warn(
        `No weights found for activity ${activity} and country ${country}`
      )
      return 0
    }
  }
}

export const format = {
  score: data => ({
    type: 'quantity',
    unit: 'tonnes CO2e',
    value: calculateScore(data),
  }),
}

const amountUnits = {
  calculated: ['kWh'],
  estimated: ['tonnes'],
}

export const fields = {
  activity: {
    type: 'options',
    options: ['electricity', 'heating', 'cooling'],
    visible: () => true,
  },
  method: {
    type: 'options',
    options: ['calculated', 'provided', 'estimated'],
    visible: ({ activity }) => activity != null,
  },
  country: {
    type: 'select',
    options: countries,
    optionsPrefix: 'countries',
    visible: ({ activity, method }) => activity != null && method != null,
  },
  emissionFactor: {
    type: 'number',
    visible: ({ activity, typeOfCarEstimation, method }) =>
      ['calculated', 'both'].includes(method),
  },
  amount: {
    type: 'amount',
    visible: ({ activity, typeOfCarEstimation, method }) =>
      ['provided', 'estimated'].includes(method) ||
      (activity != null && method),
    options: ({ method }) =>
      ['provided', 'estimated'].includes(method)
        ? amountUnits.estimated
        : amountUnits.calculated,
    optionsPrefix: 'units',
  },
  dataSource: {
    type: 'options',
    options: ['supplier', 'measured', 'estimated'],
    visible: ({ method, amount }) => method != null && amount.amount !== 0,
  },
}
