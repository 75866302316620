import mergeWith from 'lodash.mergewith'

const customizer = (src, dst) => {
  if (Array.isArray(dst)) {
    return dst
  }
  return undefined
}
const merge = (...args) => mergeWith(...args, customizer)

export default merge
