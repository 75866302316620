import calculateProgress from '../../../../utils/calculateProgress'
const meta = {
  score: calculateProgress,
  fields: {
    applicable: {
      type: 'options',
      options: [true, false],
    },
    description: {
      type: 'textArea',
      visible: data => data.applicable,
    },
    qualityAssurance: {
      type: 'textArea',
      visible: data => data.applicable,
    },
  },

  initialValues: {
    applicable: null,
    description: '',
    qualityAssurance: '',
  },
}

export default meta
