import styled from 'styled-components'
import { theme } from 'styled-tools'

import { IconButton } from '../Button'
import Metric from './Metric'

const StyledButton = styled(IconButton)`
  margin: 0.3rem;
`
const StyledOl = styled.ol`
  padding: 0;
  margin: 0;
`

const StyledLi = styled.li`
  list-style: none;
  display: flex;
  text-align: left;
  align-items: center;
  border-bottom: 1px solid ${theme('colors.border')};
  padding: 0.3rem 0;
  // height: 5rem;
`

const Source = styled.div`
  flex-grow: 1;
  margin-left: 1rem;
  overflow: hidden;
`

const Title = styled.h3`
  margin: 0.3rem 0;
  text-transform: capitalize;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Subtitle = styled.p`
  color: ${theme('colors.medium')};
  margin: 0.3rem 0;
  line-height: 1.1;
`

const ListItem = ({ title, subtitle, score, onEdit, onDelete }) => (
  <StyledLi>
    {onDelete && (
      <StyledButton icon="trash" onClick={onDelete} inverted kind="warning" />
    )}
    {onEdit && <StyledButton icon="pencil" onClick={onEdit} inverted />}
    <Source>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </Source>
    {score && <Metric {...score} />}
  </StyledLi>
)

const List = ({ data, onEdit, onDelete }) => (
  <StyledOl>
    {data.map(item => (
      <ListItem
        key={item.id}
        onEdit={onEdit !== undefined ? () => onEdit(item.id) : undefined}
        onDelete={onDelete !== undefined ? () => onDelete(item.id) : undefined}
        {...item}
      />
    ))}
  </StyledOl>
)

export default List
