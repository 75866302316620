import styled from 'styled-components'
import Select from 'components/form/Select'

const ControlsWrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
  label {
    width: auto;
    font-size: 1rem;
    font-weight: bold;
    margin-right: 1rem;
    margin-bottom: 0;
  }
  > div {
    width: 20rem;

    &.options {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      button {
        margin: 0 0.5rem;
      }
    }
  }
`

const RespondentSelectSection = ({ options, value, onSelect }) => (
  <ControlsWrapper>
    <label>Respondent:</label>
    <Select
      label="Respondent"
      options={options.map(r => ({ label: r, value: r }))}
      onChange={e => onSelect(e.target.value)}
      value={value ?? options[0]}
    />
  </ControlsWrapper>
)

export default RespondentSelectSection
