import { useState } from 'react'
import styled from 'styled-components'
import Options from 'components/form/Options'
import DataTable from 'components/DataTable'
import { sumOrConcatFields } from 'utils/reportingTools'
import flatMap from 'lodash.flatmap'
import uniq from 'lodash.uniq'

import { FormResultsWrapper, Title } from './common.jsx'

const ControlsWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  label {
    width: auto;
    font-size: 1rem;
    font-weight: bold;
    margin-right: 1rem;
    margin-bottom: 0;
  }
  > div {
    width: 20rem;

    &.options {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      button {
        margin: 0 0.5rem;
      }
    }
  }
`

const TableSection = ({ title, meta, data, initialTableControls }) => {
  const [tableControls, setTableControls] = useState({
    columns: [],
    rowKey: 'tag',
    ...initialTableControls,
  })

  const allValues = {
    tag: uniq(flatMap(data, r => r.tag.list)),
    draftId: data.map(r => r.draftId),
    id: data.map(r => r.id),
    respondentEmail: data.map(r => r.respondentEmail),
  }

  const rows = allValues[tableControls.rowKey].map(k => ({
    ...data
      .filter(r =>
        r[tableControls.rowKey].match
          ? r[tableControls.rowKey].match(k)
          : r[tableControls.rowKey] === k
      )
      .reduce(
        sumOrConcatFields(Object.keys(meta.fields), f => meta.fields[f].format),
        {}
      ),
    [tableControls.rowKey]: k,
  }))

  const keyByOptions = [
    { value: 'respondentEmail', label: 'Respondent' },
    // { value: 'draftId', label: 'Draft ID' },
    { value: 'tag', label: 'Tags' },
    // { value: 'id', label: 'Id' },
  ]

  const onChangeKeyBy = e => {
    setTableControls({ ...tableControls, rowKey: e.target.value })
  }

  return (
    <FormResultsWrapper>
      {title && <Title>{title}</Title>}
      <ControlsWrapper>
        <label>Break rows by</label>
        <Options
          className="options"
          options={keyByOptions}
          value={tableControls.rowKey}
          onChange={onChangeKeyBy}
        />
      </ControlsWrapper>
      <DataTable
        title={title}
        meta={{ ...meta, ...tableControls }}
        data={rows}
      />
      {/*<pre>{JSON.stringify(data, null, 2)}</pre>*/}
    </FormResultsWrapper>
  )
}

const DataTableFormResults = ({ id, form, meta, data, t }) => (
  <TableSection
    title={t?.('title') ?? form.title}
    meta={{ fields: form.tableMeta.fields }}
    data={data.map(report => ({
      id: report.id,
      type: report._type,
      draftId: report.draftId,
      respondent: report._respondent,
      tag: {
        list: report._respondent.tags,
        match: t => report._respondent.tags.includes(t),
        label: report._respondent.tags.join(', '),
      },
      respondentEmail: report._respondent.email,

      ...(form.transformData ? form.transformData(report, id) : report),
    }))}
    initialTableControls={{
      columns: form.tableMeta.columns,
      columnTotals: form.tableMeta.columnTotals,
      rowKey: form.tableMeta.rowKeys[0],
    }}
  />
)

export default DataTableFormResults
