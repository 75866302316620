import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  useCallback,
} from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const Timestamp = firebase.firestore.Timestamp
const FieldValue = firebase.firestore.FieldValue

const useDatabase = config => {
  const [database, setDatabase] = useState(null)

  useEffect(() => {
    if (config && firebase.apps.length === 0) {
      firebase.initializeApp(config)
      setDatabase(firebase.firestore())
    }
  }, [config, setDatabase])
  return database
}

const FirestoreContext = React.createContext()
const FirestoreProvider = ({ config, children }) => {
  const database = useDatabase(config)
  return (
    <FirestoreContext.Provider value={database}>
      {database && children}
    </FirestoreContext.Provider>
  )
}
FirestoreProvider.displayName = 'FirestoreProvider'

const transform = snapshot => {
  if (snapshot.data) {
    return snapshot.exists ? { ...snapshot.data(), id: snapshot.id } : null
  } else if (snapshot.docs) {
    return snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
  } else {
    return null
  }
}

const useFirestore = (path, filter) => {
  const isDoc = path.split('/').length % 2 === 0

  const [state, setState] = useState({
    data: undefined,
    loading: false,
    error: undefined,
  })
  const patchState = useCallback(
    data => setState(prev => ({ ...prev, ...data })),
    [setState]
  )

  const database = useContext(FirestoreContext)

  const ref = useMemo(() => {
    if (!database || !path) {
      return undefined
    }
    if (isDoc) {
      return database.doc(path)
    } else {
      return database.collection(path)
    }
  }, [database, path, isDoc])

  const filteredRef = useMemo(() => {
    if (!ref) {
      return undefined
    } else if (!filter || isDoc) {
      return ref
    } else {
      return filter(ref)
    }
  }, [ref, filter, isDoc])

  const updaters = useMemo(() => {
    if (!ref) {
      return {}
    }
    if (isDoc) {
      return {
        set: (...args) => ref.set(...args),
        update: (...args) => ref.update(...args),
        remove: (...args) => ref.delete(...args),
      }
    } else {
      return {
        add: (...args) => ref.add(...args),
        set: (id, ...args) => ref.doc(id).set(...args),
        update: (id, ...args) => ref.doc(id).update(...args),
        remove: (id, ...args) => ref.doc(id).delete(...args),
      }
    }
  }, [ref, isDoc])

  useEffect(() => {
    if (filteredRef) {
      patchState({ loading: true })
      return filteredRef.onSnapshot(
        snapshot => {
          patchState({ data: transform(snapshot), loading: false })
        },
        error => {
          patchState({ error, loading: false })
        }
      )
    }
  }, [filteredRef, patchState])

  return {
    ...state,
    ...updaters,
  }
}

export {
  FirestoreContext,
  FirestoreProvider,
  useFirestore,
  Timestamp,
  FieldValue,
  firebase,
}
