import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import { FormResultsWrapper, Title } from './common'
import _t from 'utils/translation'

const FieldWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid ${theme('colors.border')};
  padding: 0.5rem 0.5rem;
  margin-bottom: 0.5rem;
`

const QuestionWrapper = styled.div`
  width: 20rem;
  flex-shrink: 0;
  margin-right: 2rem;
  font-style: italic;
  em {
    font-weight: bold;
    display: inline;
  }
`

const SectionWrapper = styled.div`
  display: block;
  margin-bottom: 0.5rem;
  ${QuestionWrapper} {
    padding-left: 0.5rem;
  }
`

const SectionTitle = styled(Title)`
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  margin-left: 0.5rem;
  margin-top: 1rem;
`

const SectionDescription = styled.div`
  font-size: 0.8rem;
  margin-left: 0.5rem;
`

const safeSanityContent = content => {
  if (typeof content !== 'object') {
    return content
  } else {
    console.warn(
      `Content ${JSON.stringify(content)} is block content, should be text`
    )
    return '#ERR'
  }
}

const Question = ({ t, indentationLevel }) => (
  <QuestionWrapper indentationLevel={indentationLevel}>
    {t('label') && <em>{t('label')}: </em>}
    {safeSanityContent(t('description'))}
  </QuestionWrapper>
)

const TextAnswer = ({ value }) => <div>{value}</div>
const AmountAnswer = ({ value }) => (
  <div>
    {value.amount} {value.unit}
  </div>
)

const stringify = o =>
  typeof o === 'string'
    ? o
    : typeof o + o.toString()[0].toUpperCase() + o.toString().slice(1)

const OptionsAnswer = ({ value, meta, t }) => (
  <div>
    {value != null
      ? t?.(`options.${value}`) ??
        _t(`options.${meta.optionsPrefix ?? 'default'}.${stringify(value)}`) ??
        meta.options?.find(option => option.value === value)?.label ??
        value?.toString() ??
        ''
      : ''}
  </div>
)

const answerComponents = {
  textArea: TextAnswer,
  amount: AmountAnswer,
  options: OptionsAnswer,
}

const Answer = ({ type, value, meta, t, parentData }) =>
  React.createElement(answerComponents[type] ?? TextAnswer, {
    type,
    value,
    meta,
    t,
    parentData,
  })

const Field = ({
  fieldKey,
  type,
  value,
  meta,
  t,
  parentData,
  indentationLevel,
}) =>
  type === 'section' ? (
    <SectionWrapper>
      <SectionTitle>{t(`label`)}</SectionTitle>
      {t(`description`) && (
        <SectionDescription>
          {safeSanityContent(t(`description`))}
        </SectionDescription>
      )}
      {renderFields({}, value, meta, t, parentData, indentationLevel + 1)}
    </SectionWrapper>
  ) : (
    <FieldWrapper indentationLevel={indentationLevel}>
      <Question t={t} indentationLevel={indentationLevel} />
      <Answer
        type={meta.type}
        value={value}
        meta={meta}
        parentData={parentData}
        t={t}
      />
    </FieldWrapper>
  )

const isVisible = (field, data, meta) =>
  (typeof field.visible === 'function'
    ? field.visible(data)
    : field.visible !== false) && meta?.visible !== false

const renderFields = (form, data, meta, t, globalData, indentationLevel = 0) =>
  Object.entries(meta.fields).map(
    ([fieldKey, field]) =>
      isVisible(field, globalData ?? data, form.fields?.[fieldKey]) && (
        <Field
          key={fieldKey}
          fieldKey={fieldKey}
          type={field.type}
          value={data[fieldKey]}
          meta={field}
          parentData={data}
          indentationLevel={indentationLevel}
          t={t?.(`fields.${fieldKey}`)}
        />
      )
  )

const QualitativeFormResults = ({
  id,
  form,
  data: allData,
  respondent,
  meta,
  t,
}) => {
  const data = allData.find(report => report._respondent.email === respondent)[
    id
  ]
  return (
    <FormResultsWrapper>
      <Title>{t?.(`title`) ?? form.title}</Title>
      {data && renderFields(form, data, meta, t ?? _t)}
      {!data && <FieldWrapper>No data entered for this section</FieldWrapper>}
      {/*      <pre>{JSON.stringify(data, null, 2)}</pre>*/}
    </FormResultsWrapper>
  )
}

export default QualitativeFormResults
