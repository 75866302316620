export const formatNumber = (number, decimals = 0) =>
  typeof number !== 'number'
    ? number
    : number
        .toLocaleString('en-US', {
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals,
        })
        .replaceAll(',', ' ')

export const compareNumberOrString = (a, b) =>
  isFinite(a) && isFinite(b) ? a - b : a !== undefined ? a.localeCompare(b) : 0

// sort is [{column: string, order: 'asc|desc'}]
export const sortByColumn = sort => (r1, r2) => {
  const rule =
    sort.find(
      ({ column }) => compareNumberOrString(r1[column], r2[column]) !== 0
    ) ?? 0
  return (
    Math.sign(compareNumberOrString(r1[rule.column], r2[rule.column])) *
    (rule.order === 'asc' ? 1 : -1)
  )
}

export const sumOrConcatFields = (fields, format) => (r1, r2) =>
  Object.fromEntries(
    fields.map(field => [
      field,
      r1[field] === undefined
        ? r2[field]
        : format(field) === 'number'
        ? r1[field] + r2[field]
        : r1[field] === r2[field]
        ? r1[field]
        : `${r1[field]}, ${r2[field]}`,
    ])
  )
