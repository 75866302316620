const progress = data => {
  if (data == null) {
    return 0
  }
  const values = Object.entries(data)
    .filter(([key]) => key !== 'id')
    .map(([, value]) => value)
  return (
    values.reduce((acc, item) => {
      if (typeof item === 'object' && !Array.isArray(item)) {
        return acc + progress(item)
      }
      return acc + (item != null && item !== '' ? 1 : 0)
    }, 0) / values.length
  )
}

const calculateProgress = data => ({
  type: 'progress',
  value: progress(data),
})

export default calculateProgress
