import uniq from 'lodash.uniq'
import { sortByColumn } from 'utils/reportingTools'
import writeExcelFile from 'write-excel-file'

const getRowValues = (data, { rowKey, columns, fields }) =>
  [rowKey, ...columns].map(c => ({
    key: c,
    value:
      fields[c] === undefined
        ? `No value for ${c}`
        : fields[c].fn
        ? fields[c].fn(data)
        : data[c]?.label
        ? data[c].label
        : data[c],
    ...fields[c],
  }))

const addTotals = meta => r => ({
  ...r,
  total: getRowValues(r, meta).reduce(
    (acc, cur) =>
      cur.format === 'number' && isFinite(cur.value) ? acc + cur.value : acc,
    0
  ),
})

const filterFn = filters => data =>
  Object.entries(filters)
    .map(([column, filter]) => filter[data[column]] ?? true)
    .reduce((a, b) => a && b, true)

export const getTableData = (data, meta, filter, sort) => {
  const rowsWithTotals = data.map(addTotals(meta))
  const uniqueValues = Object.fromEntries(
    [meta.rowKey, ...meta.columns].map((column, i) => [
      column,
      uniq(rowsWithTotals.map(row => getRowValues(row, meta)[i].value)),
    ])
  )
  const rows = rowsWithTotals
    .filter(filterFn(filter))
    .sort(sortByColumn(sort))
    .map(row => getRowValues(row, meta))
  return { rows, uniqueValues }
}

const alignMap = {
  number: 'right',
  percentage: 'right',
}

const typeMap = {
  number: Number,
  percentage: Number,
}

const formatMap = {
  number: '#,##0',
  percentage: '#,##0.0%',
}

export const exportExcel = (meta, title) => async rows => {
  const keys = [meta.rowKey, ...meta.columns].filter(key => key !== 'total')
  const data = [
    keys.map(key => ({
      value: meta.fields[key]?.label,
      fontWeight: 'bold',
      align: alignMap[meta.fields[key]?.format],
    })),
    ...rows.map(row =>
      keys.map((_, i) => ({
        value: row[i].value,
        type: typeMap[row[i].format],
        format: formatMap[row[i].format],
      }))
    ),
  ]
  try {
    await writeExcelFile(data, {
      fileName: `${title ?? 'file'}.xlsx`,
    })
  } catch (e) {}
}
