import currencies from '../../currencies'

const meta = {
  fields: {
    name: {
      type: 'text',
    },
    location: {
      type: 'text',
    },
    numberOfEmployees: {
      type: 'int',
    },
    financial: {
      type: 'section',
      fields: {
        netSales: {
          type: 'amount',
          options: currencies,
          optionsPrefix: 'currencies',
        },
        assets: {
          type: 'amount',
          options: currencies,
          optionsPrefix: 'currencies',
        },
        debt: {
          type: 'amount',
          options: currencies,
          optionsPrefix: 'currencies',
        },
        equity: {
          type: 'amount',
          options: currencies,
          optionsPrefix: 'currencies',
        },
      },
    },
    quantity: {
      type: 'number',
    },
    changes: {
      type: 'textArea',
    },
    codeOfConduct: {
      type: 'section',
      fields: {
        applicable: {
          type: 'options',
          options: [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ],
        },
        requiresSigning: {
          type: 'options',
          options: [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ],
          visible: data => data.codeOfConduct.applicable,
        },
        responsible: {
          type: 'text',
          visible: data => data.codeOfConduct.applicable,
        },
        process: {
          type: 'textArea',
          visible: data => data.codeOfConduct.applicable,
        },
        appliesTo: {
          type: 'textArea',
          visible: data => data.codeOfConduct.applicable,
        },
        trainingProvided: {
          type: 'options',
          options: [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ],
          visible: data => data.codeOfConduct.applicable,
        },
      },
    },
    principles: {
      type: 'section',
      fields: {
        applicable: {
          type: 'options',
          options: [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ],
        },
        description: {
          type: 'textArea',
          visible: data => data.principles.applicable,
        },
      },
    },
  },
  initialValues: {
    name: '',
    location: '',
    numberOfEmployees: '',
    financial: {
      netSales: {
        amount: '',
        unit: 'kr',
      },
      assets: {
        amount: '',
        unit: 'kr',
      },
      debt: {
        amount: '',
        unit: 'kr',
      },
      equity: {
        amount: '',
        unit: 'kr',
      },
    },
    quantity: '',
    changes: '',
    codeOfConduct: {
      applicable: null,
      requiresSigning: null,
      responsible: '',
      process: '',
      appliesTo: '',
      trainingProvided: '',
    },
    principles: {
      applicable: null,
      description: '',
    },
  },
}

export default meta
