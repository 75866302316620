import styled from 'styled-components'
import { BaseTemplate } from 'components/Template'
import { sections } from './meta.js'

const StyledBaseTemplate = styled(BaseTemplate)`
  aside {
    width: 40rem;
    max-width: 100%;
  }
  form {
    fieldset {
      display: flex;
      align-items: flex-end;
      > * {
        flex-grow: 1;
        margin-right: 0.5rem;
        flex-basis: 1rem;
        &:last-child {
          margin-right: 0;
        }
      }
      &:last-of-type {
        margin-bottom: 1rem;
      }
    }
  }
  && form fieldset > div + div {
    margin-top: 0;
  }
`

const Template = props => <StyledBaseTemplate meta={sections} {...props} />

export default Template
