import enrichDraft from 'utils/enrichDraft'

export const sections = enrichDraft({
  customerPrivacy: {
    type: 'static',
    forms: {
      customerPrivacy: {
        score: data => {
          if (data === undefined) {
            return
          }
          const { numberOfComplaints, numberOfLeaks } = data
          const sum =
            Number(numberOfLeaks) +
            Number(numberOfComplaints.fromOutsideParties) +
            Number(numberOfComplaints.fromRegulatoryBodies)
          return {
            type: 'quantity',
            value: sum,
            unit: 'complaints',
          }
        },
        initialValues: {
          numberOfComplaints: {
            fromOutsideParties: '',
            fromRegulatoryBodies: '',
          },
          numberOfLeaks: '',
          noComplaintsDescriptiono: '',
        },
        fields: {
          numberOfComplaints: {
            type: 'section',
            fields: {
              fromOutsideParties: {
                type: 'int',
              },
              fromRegulatoryBodies: {
                type: 'int',
              },
            },
          },
          numberOfLeaks: {
            type: 'int',
          },
          noComplaintsDescription: {
            type: 'textArea',
          },
        },
      },
    },
  },
})
