import styled from 'styled-components'
import { BaseTemplate } from 'components/Template'
import { sections } from './meta'

const StyledBaseTemplate = styled(BaseTemplate)``

const Template = props => {
  return <StyledBaseTemplate meta={sections} {...props} />
}
export default Template
