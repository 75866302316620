import styled from 'styled-components'
import { BaseTemplate } from 'components/Template'
import { sections } from './meta'
import t from 'utils/translation'
import useAuth from 'providers/useAuth'

const StyledBaseTemplate = styled(BaseTemplate)`
  aside {
    max-width: 60vw;
    width: 60rem;
  }
`

const Template = ({ remove, set, drafts, projectMeta, setProjectMeta }) => {
  const { user } = useAuth()
  const data = { _type: 'respondentOverview' }
  drafts.forEach(({ id, _type, _status, _respondent }) => {
    const type = isFinite(_type) ? `doc${_type}` : _type
    data[type] ??= { items: {} }
    data[type].items[id] = { id, _status, ..._respondent }
  })

  const save = sectionType => formKey => data => {
    const { id, _status, ..._respondent } = data
    set(
      data.id,
      {
        id,
        _type: formKey,
        _respondent,
        _creator: { name: user.displayName, email: user.email, uid: user.uid },
      },
      { merge: true }
    )
    const usedTags = Object.fromEntries(
      _respondent.tags.map(value => [value, value])
    )
    const newTags = { ...usedTags, ...projectMeta.respondentTags }
    setProjectMeta({ respondentTags: newTags }, { merge: true })
  }

  const deleteItem = type => id => {
    remove(id)
  }

  return (
    <StyledBaseTemplate
      meta={sections}
      data={data}
      save={save}
      deleteItem={deleteItem}
      projectMeta={projectMeta}
      t={t('respondentOverview')}
    ></StyledBaseTemplate>
  )
}

export default Template
