import { useEffect, useState } from 'react'

import firebase from 'firebase/app'
import config from 'config'

import 'firebase/auth'

const useAuth = () => {
  if (config && firebase.apps.length === 0) {
    firebase.initializeApp(config.firestore)
  }

  const auth = firebase.auth()

  const [state, setState] = useState({
    user: auth.currentUser,
    isLoading: true,
  })

  useEffect(
    () =>
      auth.onAuthStateChanged(user => {
        if (user) {
          setState({
            user: {
              displayName: user.displayName,
              email: user.email,
              uid: user.uid,
            },
            loading: false,
          })

          // ...
        } else {
          setState({
            user: null,
            loading: false,
          })
        }
      }),

    [auth]
  )
  return state
}

export const logout = () =>
  firebase
    .auth()
    .signOut()
    .then(() => {
      console.log('Signed out')
    })
    .catch(e => {
      console.error(e)
    })

export default useAuth
