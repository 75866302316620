import enrichDraft from 'utils/enrichDraft'

import organization from './organization'
import ownershipGovernance from './ownershipGovernance'
import operations from './operations'
import supplyChain from './supplyChain'
import externalInitiativesAndMemberships from './externalInitiativesAndMemberships'
import reporting from './reporting'
import ceoStatement from './ceoStatement'

export const sections = enrichDraft({
  generalDisclosures: {
    title: 'General disclosures',
    type: 'static',
    forms: {
      organization,
      ownershipGovernance,
      operations,
      supplyChain,
      externalInitiativesAndMemberships,
      reporting,
      ceoStatement,
    },
  },
})
