import _t from 'utils/translation'
import BaseTemplate from '../BaseTemplate.jsx'
import { sections } from '../../Draft/302/meta'

const conversionFactor = {
  liters: 0.001,
  m3: 1,
}

const resultSections = {
  fuelConsumption: {
    id: 'fuelConsumption',
    type: 'dataTable',
    tableMeta: {
      fields: {
        nonRenewables: { label: 'Non-renewables (m3)', format: 'number' },
        renewables: { label: 'Renewables (m3)', format: 'number' },
        renewablesPercent: {
          label: 'Renewables (%)',
          format: 'percentage',
          fn: ({ nonRenewables, renewables }) =>
            renewables / (nonRenewables + renewables),
        },
        respondentEmail: { label: 'Respondent' },
        tag: { label: 'Tag' },
        total: {
          label: 'Total (m3)',
          format: 'number',
        },
      },
      columns: ['nonRenewables', 'renewables', 'renewablesPercent', 'total'],
      rowKeys: ['tag', 'respondent'],
      columnTotals: true,
    },
    transformData: report =>
      Object.values(report.fuelConsumption?.items ?? {})
        .map(item => ({
          renewables:
            item.fuelConsumption.renewables *
            conversionFactor[item.fuelConsumption.unit],
          nonRenewables:
            item.fuelConsumption.nonRenewables *
            conversionFactor[item.fuelConsumption.unit],
        }))
        .reduce(
          (acc, cur) => ({
            renewables: acc.renewables + cur.renewables,
            nonRenewables: acc.nonRenewables + cur.nonRenewables,
          }),
          { renewables: 0, nonRenewables: 0 }
        ),
  },
  dataSource: {
    id: 'misc',
    respondentSelect: true,
    forms: {
      dataSource: {
        type: 'qualitative',
      },
    },
  },
}

const Template = ({ projectId, data }) => (
  <BaseTemplate
    t={_t(`drafts.doc302.sections`)}
    meta={sections}
    resultSections={resultSections}
    data={data}
  />
)

export default Template
