import enrichDraft from 'utils/enrichDraft'
import countries from '../countries'

export const sections = enrichDraft({
  employeeHiresAndTurnover: {
    type: 'static',
    forms: {
      employeeHiresAndTurnover: {
        score: ({ hires } = {}) => {
          const { male, female } = hires?.byGender ?? {}
          const sum = Number(male ?? 0) + Number(female ?? 0)
          return {
            type: 'quantity',
            value: sum,
            unit: 'hires',
          }
        },
        initialValues: {
          country: null,
          hires: {
            byGender: {
              male: '',
              female: '',
            },
            byAge: {
              low: '',
              medium: '',
              high: '',
            },
          },
          turnover: {
            byGender: {
              male: '',
              female: '',
            },
            byAge: {
              low: '',
              medium: '',
              high: '',
            },
          },
        },
        fields: {
          country: {
            type: 'select',
            options: countries,
            optionsPrefix: 'countries',
          },
          hires: {
            type: 'section',
            fields: {
              byGender: {
                type: 'section',
                fields: {
                  male: { type: 'int' },
                  female: { type: 'int' },
                },
              },
              byAge: {
                type: 'section',
                fields: {
                  low: { type: 'int' },
                  medium: { type: 'int' },
                  high: { type: 'int' },
                },
              },
            },
          },
          turnover: {
            type: 'section',
            fields: {
              byGender: {
                type: 'section',
                fields: {
                  male: { type: 'int' },
                  female: { type: 'int' },
                },
              },
              byAge: {
                type: 'section',
                fields: {
                  low: { type: 'int' },
                  medium: { type: 'int' },
                  high: { type: 'int' },
                },
              },
            },
          },
        },
      },
    },
  },
})
