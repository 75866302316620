import mapValues from 'lodash.mapvalues'

const addComment = obj => ({
  ...obj,
  initialValues: {
    ...obj.initialValues,
    comment: '',
  },
  fields: {
    ...obj.fields,
    comment: {
      type: 'textArea',
    },
  },
})

const enrichDraft = sections => ({
  managementApproach: {
    type: 'static',
    forms: {
      managementApproach: {
        initialValues: {
          description: '',
          occurrence: '',
          involvement: '',
          management: '',
          purpose: '',
          componentDescription: '',
          evaluation: '',
        },
        fields: {
          description: {
            type: 'textArea',
          },
          occurrence: {
            type: 'textArea',
          },
          involvement: {
            type: 'textArea',
          },
          management: {
            type: 'textArea',
          },
          purpose: {
            type: 'textArea',
          },
          componentDescription: {
            type: 'textArea',
          },
          evaluation: {
            type: 'textArea',
          },
        },
      },
    },
  },
  ...mapValues(sections, section => {
    if (section.type === 'list') {
      return addComment(section)
    }
    return {
      ...section,
      forms: mapValues(section.forms, addComment),
    }
  }),
})

export default enrichDraft
