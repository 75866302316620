export const type = 'list'

export const initialValues = {
  method: null,
  typeOfCarEstimation: null,
  fuel: null,
  typeOfCar: null,
  amount: {
    amount: 0,
    unit: null,
  },
  dataSource: null,
}

// Weights in kg (Same weights as in combustion)
const weights = {
  petrolBio: { tonnes: 2997.5, litres: 2.20904, kWh: 0.23373 },
  petrol: { tonnes: 3152.96, litres: 2.31495, kWh: 0.24099 },
  dieselBio: { tonnes: 3088.23, litres: 2.59411, kWh: 0.24462 },
  diesel: { tonnes: 3205.55, litres: 2.68697, kWh: 0.25267 },
  small: { km: 0.14208, miles: 0.22868 },
  medium: { km: 0.17061, miles: 0.27459 },
  large: { km: 0.20947, miles: 0.33713 },
  average: { km: 0.17336, miles: 0.27901 },
}

// Returns CO2e in tonnes
export const calculateScore = ({ method, amount, fuel, typeOfCar }) => {
  if (['estimated', 'provided'].includes(method)) {
    return amount.amount
  } else if (weights[fuel ?? typeOfCar]?.[amount.unit] != null) {
    return (weights[fuel ?? typeOfCar][amount.unit] * amount.amount) / 1000
  } else {
    console.warn(
      `No weight found for fuel/carType ${fuel ?? typeOfCar} and unit ${
        amount.unit
      }`
    )
    return 0
  }
}

export const format = {
  title: 'leased car',
  score: data => ({
    type: 'quantity',
    unit: 'tonnes CO2e',
    value: calculateScore(data),
  }),
}

const carUnits = {
  distance: ['miles', 'km'],
  fuel: ['kWh', 'tonnes', 'liters'],
  estimated: [
    {
      value: 'tonnes',
      label: 'tonnes',
    },
  ],
}

export const fields = {
  method: {
    type: 'options',
    options: ['calculated', 'provided', 'estimated'],
    visible: () => true,
  },
  typeOfCarEstimation: {
    type: 'options',
    options: ['distance', 'fuel'],
    visible: ({ method }) => ['calculated'].includes(method),
  },
  typeOfCar: {
    type: 'options',
    options: ['small', 'medium', 'large', 'average'],
    visible: ({ typeOfCarEstimation }) => typeOfCarEstimation === 'distance',
  },
  fuel: {
    type: 'options',
    options: ({ activity }) => ['petrolBio', 'petrol', 'dieselBio', 'diesel'],
    optionsPrefix: 'fuels',
    visible: ({ typeOfCarEstimation, method }) =>
      method === 'calculated' && typeOfCarEstimation === 'fuel',
  },
  amount: {
    type: 'amount',
    visible: ({ typeOfCarEstimation, method, fuel }) =>
      ['estimated', 'provided'].includes(method) ||
      typeOfCarEstimation != null ||
      fuel != null,
    options: ({ typeOfCarEstimation }) =>
      carUnits[typeOfCarEstimation ?? 'estimated'],
    optionsPrefix: 'units',
  },
  dataSource: {
    type: 'options',
    options: ['supplier', 'measured', 'estimated'],
    visible: ({ method, amount, rawAmount }) => !!method && amount.amount !== 0,
  },
}
