import _t from 'utils/translation'
import BaseTemplate from '../BaseTemplate.jsx'
import { sections, scoreValues } from '../../Draft/305/meta'

const resultSections = {
  summary: {
    id: 'summary',
    title: 'GHG Emissions in tonnes CO2e',
    type: 'dataTable',
    tableMeta: {
      fields: {
        scope1: { label: 'Scope 1', format: 'number' },
        scope2: { label: 'Scope 2', format: 'number' },
        scope3: { label: 'Scope 3', format: 'number' },
        id: { label: 'Id' },
        draftId: { label: 'Draft' },
        respondentEmail: { label: 'Respondent' },
        type: {
          label: 'Type',
        },
        timestamp: {
          label: 'Timestamp',
        },

        tag: {
          label: 'Tag',
        },
        total: {
          label: 'Total',
          format: 'number',
        },
      },
      columns: ['scope1', 'scope2', 'scope3', 'total'],
      rowKeys: ['tag', 'respondent'],
      columnTotals: true,
    },
    transformData: report =>
      Object.fromEntries(
        scoreValues(report).map((v, i) => [`scope${i + 1}`, v])
      ),
  },
}

const Template = ({ projectId, data }) => (
  <BaseTemplate
    t={_t(`drafts.doc305.sections`)}
    meta={sections}
    resultSections={resultSections}
    data={data}
  />
)

export default Template
