import enrichDraft from 'utils/enrichDraft'
import calculateProgress from 'utils/calculateProgress'
export const title = '302 Energy TR'
export const subtitle = ''

const misc = {
  type: 'static',
  forms: {
    dataSource: {
      score: calculateProgress,
      initialValues: {
        dataCollectionMethod: '',
        assumptions: '',
        dataCollector: {
          name: '',
          function: '',
          email: '',
        },
      },
      fields: {
        dataCollectionMethod: {
          type: 'textArea',
        },
        assumptions: {
          type: 'textArea',
        },
        dataCollector: {
          type: 'section',
          fields: {
            name: { type: 'text' },
            function: { type: 'text' },
            email: { type: 'text' },
          },
        },
      },
    },
  },
}

const fuelUnits = [
  { label: 'litres', value: 'liters' },
  { label: 'm3', value: 'm3' },
]

const fuelConsumption = {
  type: 'list',
  format: {
    score: data => ({
      type: 'quantity',
      value:
        (data.fuelConsumption?.nonRenewables ?? 0) +
        (data.fuelConsumption?.renewables ?? 0),
      unit:
        fuelUnits.find(({ value }) => value === data.fuelConsumption?.unit)
          ?.label ?? 'unknown unit',
    }),
  },
  initialValues: {
    name: '',
    fuelConsumption: {
      nonRenewables: '',
      renewables: '',
      unit: 'm3',
    },
  },
  fields: {
    name: {
      type: 'text',
    },
    fuelConsumption: {
      type: 'section',
      fields: {
        nonRenewables: {
          type: 'number',
        },
        renewables: {
          type: 'number',
        },
        unit: {
          label: 'Unit',
          type: 'select',
          options: ['liters', 'm3'],
        },
      },
    },
  },
}

export const sections = enrichDraft({ misc, fuelConsumption })
