import _t from 'utils/translation'
import BaseTemplate from '../BaseTemplate.jsx'
import { sections } from '../../Draft/GeneralDisclosures/meta'

const resultSections = {
  generalDisclosures: {
    respondentSelect: true,
    forms: {
      organization: { type: 'qualitative' },
      ownershipGovernance: { type: 'qualitative' },
      operations: { type: 'qualitative' },
      supplyChain: { type: 'qualitative' },
      externalInitiativesAndMemberships: { type: 'qualitative' },
      reporting: { type: 'qualitative' },
      ceoStatement: { type: 'qualitative' },
    },
  },
}

const Template = ({ projectId, data }) => (
  <BaseTemplate
    t={_t(`drafts.generalDisclosures.sections`)}
    meta={sections}
    resultSections={resultSections}
    data={data}
  />
)

export default Template
