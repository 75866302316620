import React from 'react'
import styled from 'styled-components'
import { switchProp, withProp } from 'styled-tools'
import { transparentize } from 'polished'
import { Container } from './Layout'
import { media } from 'theme'
import colorsByType from 'utils/colorsByType'

const Wrapper = styled.section`
  position: relative;
`

const MiddleWrapper = styled.div`
  position: relative;
  ${withProp('type', colorsByType)};
  padding: 4.5rem 1.5rem;
  ${media('sm')`
    padding: 2.5rem 2rem;
  `}
`

const InnerWrapper = styled(Container)`
  position: relative;
  z-index: 1;
`

const Glass = styled(MiddleWrapper)`
  position: relative;
  background: ${switchProp(
    'type',
    {
      transparent: 'transparent',
      white: withProp('theme.colors.white', transparentize(0.2)),
      light: withProp('theme.colors.light', transparentize(0.2)),
      dark: withProp('theme.colors.dark', transparentize(0.2)),
    },
    'transparent'
  )};
`

const StyledImage = styled.img`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Block = ({ className, children, image, type, glass, component }) => {
  const Container = image ? Glass : MiddleWrapper
  return (
    <Wrapper as={component}>
      {image && <StyledImage src={image} />}
      <Container type={type} className={className}>
        <InnerWrapper>{children}</InnerWrapper>
      </Container>
    </Wrapper>
  )
}

export default Block
