import enrichDraft from 'utils/enrichDraft'
import calculateProgress from 'utils/calculateProgress'

export const title = '303 Water and Effluents'
export const subtitle = ''
export const description = ''

export const sections = enrichDraft({
  water: {
    type: 'static',
    forms: {
      interactions: {
        score: calculateProgress,
        initialValues: {
          withdrawn: '',
          consumed: '',
          discharged: '',
          otherImpacts: '',
        },
        fields: {
          withdrawn: {
            type: 'textArea',
          },
          consumed: {
            type: 'textArea',
          },
          discharged: {
            type: 'textArea',
          },
          otherImpacts: {
            type: 'textArea',
          },
        },
      },
      identification: {
        score: calculateProgress,
        initialValues: {
          scope: '',
          timeframe: '',
          methodologies: '',
        },
        fields: {
          scope: {
            type: 'textArea',
          },
          timeframe: {
            type: 'textArea',
          },
          methodologies: {
            type: 'textArea',
          },
        },
      },
      addressing: {
        score: calculateProgress,
        initialValues: {
          addressed: '',
          stakeholders: '',
          suppliersCustomers: '',
        },
        fields: {
          addressed: {
            type: 'textArea',
          },
          stakeholders: {
            type: 'textArea',
          },
          suppliersCustomers: {
            type: 'textArea',
          },
        },
      },
      goals: {
        score: calculateProgress,
        initialValues: {
          settingGoals: '',
          publicPolicy: '',
          localContext: '',
        },
        fields: {
          settingGoals: {
            type: 'textArea',
          },
          publicPolicy: {
            type: 'textArea',
          },
          localContext: {
            type: 'textArea',
          },
        },
      },
      discharge: {
        score: calculateProgress,
        initialValues: {
          minimumStandards: '',
          noLocalStandards: '',
          internalStandardsApplicable: null,
          internalStandards: '',
          sectorSpecificStandardsApplicable: null,
          sectorSpecificStandards: '',
          receivingBodyConsiderationsApplicable: null,
          receivingBodyConsiderations: '',
        },
        fields: {
          minimumStandards: {
            type: 'textArea',
          },
          noLocalStandards: {
            type: 'textArea',
          },
          internalStandardsApplicable: {
            type: 'options',
            options: [
              { value: true, label: 'Yes' },
              { value: false, label: 'No' },
            ],
          },
          internalStandards: {
            type: 'textArea',
            visible: ({ internalStandardsApplicable }) =>
              internalStandardsApplicable,
          },
          sectorSpecificStandardsApplicable: {
            type: 'options',
            options: [
              { value: true, label: 'Yes' },
              { value: false, label: 'No' },
            ],
          },
          sectorSpecificStandards: {
            type: 'textArea',
            visible: ({ sectorSpecificStandardsApplicable }) =>
              sectorSpecificStandardsApplicable,
          },
          receivingBodyConsiderationsApplicable: {
            type: 'options',
            options: [
              { value: true, label: 'Yes' },
              { value: false, label: 'No' },
            ],
          },
          receivingBodyConsiderations: {
            type: 'textArea',
            visible: ({ receivingBodyConsiderationsApplicable }) =>
              receivingBodyConsiderationsApplicable,
          },
        },
      },
      withdrawal: {
        initialValues: {
          surfaceWater: { total: '', fresh: '', unit: 'megaliters' },
          groundWater: { total: '', fresh: '', unit: 'megaliters' },
          seaWater: { total: '', fresh: '', unit: 'megaliters' },
          producedWater: { total: '', fresh: '', unit: 'megaliters' },
          thirdPartyWater: { total: '', fresh: '', unit: 'megaliters' },
        },
        score: data => ({
          type: 'quantity',
          value: Object.values(data ?? {})
            .map(item =>
              isNaN(parseFloat(item.total)) ? 0 : parseFloat(item.total) ?? 0
            )
            .reduce((a, b) => a + b, 0),
          unit: 'megaliters',
        }),
        fields: {
          surfaceWater: {
            type: 'section',
            fields: {
              total: {
                type: 'number',
              },
              fresh: {
                type: 'number',
              },
              unit: {
                type: 'select',
                options: ['megaliters'],
              },
            },
          },
          groundWater: {
            type: 'section',
            fields: {
              total: {
                type: 'number',
              },
              fresh: {
                type: 'number',
              },
              unit: {
                type: 'select',
                options: ['megaliters'],
              },
            },
          },
          seaWater: {
            type: 'section',
            fields: {
              total: {
                type: 'number',
              },
              fresh: {
                type: 'number',
              },
              unit: {
                type: 'select',
                options: ['megaliters'],
              },
            },
          },
          producedWater: {
            type: 'section',
            fields: {
              total: {
                type: 'number',
              },
              fresh: {
                type: 'number',
              },
              unit: {
                type: 'select',
                options: ['megaliters'],
              },
            },
          },
          thirdPartyWater: {
            type: 'section',
            fields: {
              total: {
                type: 'number',
              },
              fresh: {
                type: 'number',
              },
              unit: {
                type: 'select',
                options: ['megaliters'],
              },
            },
          },
        },
      },
      withdrawalStress: {
        initialValues: {
          surfaceWater: { total: '', fresh: '', unit: null },
          groundWater: { total: '', fresh: '', unit: null },
          seaWater: { total: '', fresh: '', unit: null },
          producedWater: { total: '', fresh: '', unit: null },
          thirdPartySurfaceWater: { total: '', fresh: '', unit: null },
          thirdPartyGroundWater: { total: '', fresh: '', unit: null },
          thirdPartySeaWater: { total: '', fresh: '', unit: null },
          thirdPartyProducedWater: { total: '', fresh: '', unit: null },
        },
        score: data => ({
          type: 'quantity',
          value: Object.values(data ?? {})
            .map(item =>
              isNaN(parseFloat(item.total)) ? 0 : parseFloat(item.total) ?? 0
            )
            .reduce((a, b) => a + b, 0),
          unit: 'megaliters',
        }),
        fields: {
          surfaceWater: {
            type: 'section',
            fields: {
              total: {
                type: 'number',
              },
              fresh: {
                type: 'number',
              },
              unit: {
                type: 'select',
                options: ['megaliters'],
              },
            },
          },
          groundWater: {
            type: 'section',
            fields: {
              total: {
                type: 'number',
              },
              fresh: {
                type: 'number',
              },
              unit: {
                type: 'select',
                options: ['megaliters'],
              },
            },
          },
          seaWater: {
            type: 'section',
            fields: {
              total: {
                type: 'number',
              },
              fresh: {
                type: 'number',
              },
              unit: {
                type: 'select',
                options: ['megaliters'],
              },
            },
          },
          producedWater: {
            type: 'section',
            fields: {
              total: {
                type: 'number',
              },
              fresh: {
                type: 'number',
              },
              unit: {
                type: 'select',
                options: ['megaliters'],
              },
            },
          },
          thirdPartySurfaceWater: {
            type: 'section',
            fields: {
              total: {
                type: 'number',
              },
              fresh: {
                type: 'number',
              },
              unit: {
                type: 'select',
                options: ['megaliters'],
              },
            },
          },
          thirdPartyGroundWater: {
            type: 'section',
            fields: {
              total: {
                type: 'number',
              },
              fresh: {
                type: 'number',
              },
              unit: {
                type: 'select',
                options: ['megaliters'],
              },
            },
          },
          thirdPartySeaWater: {
            type: 'section',
            fields: {
              total: {
                type: 'number',
              },
              fresh: {
                type: 'number',
              },
              unit: {
                type: 'select',
                options: ['megaliters'],
              },
            },
          },
          thirdPartyProducedWater: {
            type: 'section',
            fields: {
              total: {
                type: 'number',
              },
              fresh: {
                type: 'number',
              },
              unit: {
                type: 'select',
                options: ['megaliters'],
              },
            },
          },
        },
      },
      dataSource: {
        score: calculateProgress,
        initialValues: {
          dataCollectionMethod: '',
        },
        fields: {
          dataCollectionMethod: {
            type: 'textArea',
          },
        },
      },
    },
  },
})
