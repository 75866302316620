import { nanoid } from 'nanoid'

import { useFirestore } from 'providers/Firestore'
import firebase from 'firebase/app'

import Loader from 'components/Loader'
import Template from './Template'

import useAuth from 'providers/useAuth'

const Draft = ({ match }) => {
  const { user } = useAuth()
  const { projectId, draftId } = match.params
  const { data, loading, update } = useFirestore(
    `projects/${projectId}/drafts/${draftId}`
  )

  const { data: project, loading: loadingMeta } = useFirestore(
    `projects/${projectId}`
  )

  const { set: submitReport } = useFirestore(`projects/${projectId}/reports`)

  const submit = ({ id, _status, ...data }, { approved, message } = {}) => {
    if (['new', 'started', 'changeRequired'].includes(_status)) {
      //console.log('Submitting for review')
      update({ _status: 'readyForReview' })
    } else if (user && ['readyForReview', 'reviewing'].includes(_status)) {
      if (approved) {
        const reportId = nanoid()
        submitReport(reportId, {
          id: reportId,
          draftId: id,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          ...data,
        }).catch(e => {
          console.error(e)
        })
        update({
          _status: 'submitted',
          _reviewer: {
            email: user.email,
            name: user.displayName,
            uid: user.uid,
          },
          _reviewerNote: message,
        })
      } else {
        // Reject draft
        update({
          _status: 'changeRequired',
          _reviewer: {
            email: user.email,
            name: user.displayName,
            uid: user.uid,
          },
          _reviewerNote: message,
        })
      }
    } else {
      console.error(
        `Invalid combination of draft status (${_status}) and login status (${
          !user ? 'not ' : ''
        } logged in)`
      )
    }
  }

  const canEdit =
    (!user &&
      [undefined, 'new', 'started', 'changeRequired'].includes(
        data?._status
      )) ||
    (user && ['readyForReview', 'reviewing'].includes(data?._status))

  return loading || loadingMeta ? (
    <Loader />
  ) : data ? (
    <Template
      type="draft"
      data={data}
      update={update}
      projectMeta={project._meta}
      submit={submit}
      disabled={!canEdit}
    />
  ) : (
    <p>Nothing</p>
  )
}

export default Draft
