export const type = 'list'

export const initialValues = {
  method: null,
  activity: null,
  typeOfCarEstimation: null,
  fuel: null,
  typeOfCar: null,
  amount: {
    amount: '',
    unit: null,
  },
  dataSource: null,
}

// Weights in kg
const weights = {
  stove: { naturalGas: { kg: 2.54204, m3: 2.03053, kWh: 0.18385 } },
  car: {
    petrolBio: { tonnes: 2997.5, litres: 2.20904, kWh: 0.23373 },
    petrol: { tonnes: 3152.96, litres: 2.31495, kWh: 0.24099 },
    dieselBio: { tonnes: 3088.23, litres: 2.59411, kWh: 0.24462 },
    diesel: { tonnes: 3205.55, litres: 2.68697, kWh: 0.25267 },
    small: { km: 0.14208, miles: 0.22868 },
    medium: { km: 0.17061, miles: 0.27459 },
    large: { km: 0.20947, miles: 0.33713 },
    average: { km: 0.17336, miles: 0.27901 },
  },
  heating: {
    woodPellets: { kg: 73.13523, kWh: 0.01563 },
    naturalGas: { kg: 2.54204, m3: 2.03053, kWh: 0.18385 },
    fuelOil: { kg: 3.21782, litres: 3.17966, kWh: 0.26782 },
  },
}

// Returns CO2e in tonnes
export const calculateScore = ({
  method,
  amount,
  activity,
  fuel,
  typeOfCar,
}) => {
  if (['estimated', 'provided'].includes(method)) {
    return amount.amount
  } else if (weights[activity]?.[fuel ?? typeOfCar]?.[amount.unit] != null) {
    return (
      (weights[activity][fuel ?? typeOfCar][amount.unit] * amount.amount) / 1000
    )
  } else {
    console.warn(
      `No weight found for activity ${activity}, fuel/carType ${
        fuel ?? typeOfCar
      } and unit ${amount.unit}`
    )
    return 0
  }
}

export const format = {
  score: data => ({
    type: 'quantity',
    unit: 'tonnes CO2e',
    value: calculateScore(data),
  }),
}

const fuelOptions = {
  car: ['petrolBio', 'petrol', 'dieselBio', 'diesel'],
  stove: ['naturalGas'],
  heating: ['woodPellets', 'naturalGas', 'fuelOil'],
}

const carUnits = {
  distance: ['miles', 'km'],
  fuel: ['kWh', 'tonnes', 'litres'],
  estimated: [
    {
      value: 'tonnes',
      label: 'tonnes',
    },
  ],
}

const fuelUnits = {
  naturalGas: ['kg', 'm3', 'kWh'],
  woodPellets: ['kg', 'kWh'],
  fuelOil: ['kg', 'litres', 'kWh'],
  estimated: ['tonnes'],
}

export const fields = {
  activity: {
    type: 'options',
    options: ['car', 'stove', 'heating'],
    visible: () => true,
  },
  method: {
    type: 'options',
    options: ['calculated', 'provided', 'estimated'],
    visible: ({ activity }) => activity != null,
  },
  typeOfCarEstimation: {
    type: 'options',
    options: ['distance', 'fuel'],
    visible: ({ activity, method }) =>
      activity === 'car' && method === 'calculated',
  },
  typeOfCar: {
    type: 'options',
    options: ['small', 'medium', 'large', 'average'],
    visible: ({ activity, typeOfCarEstimation }) =>
      activity === 'car' && typeOfCarEstimation === 'distance',
  },
  fuel: {
    type: 'options',
    options: ({ activity }) => fuelOptions[activity],
    optionsPrefix: 'fuels',
    visible: ({ activity, typeOfCarEstimation, method }) =>
      method &&
      (['stove', 'heating'].includes(activity) ||
        (activity === 'car' && typeOfCarEstimation === 'fuel')),
  },
  amount: {
    type: 'amount',
    visible: ({ activity, typeOfCarEstimation, method, fuel }) =>
      ['estimated', 'provided'].includes(method) ||
      (activity === 'car' && typeOfCarEstimation != null) ||
      fuel != null,
    options: ({ activity, typeOfCarEstimation, fuel }) =>
      activity === 'car'
        ? carUnits[typeOfCarEstimation ?? 'estimated']
        : fuelUnits[fuel ?? 'estimated'],
    optionsPrefix: 'units',
  },
  dataSource: {
    type: 'options',
    options: ['supplier', 'measured', 'estimated'],
    visible: ({ method, amount, rawAmount }) =>
      method != null && amount.amount !== 0,
  },
}
