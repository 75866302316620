import calculateProgress from '../../../../utils/calculateProgress'

const meta = {
  score: calculateProgress,

  fields: {
    applicable: {
      type: 'options',
      options: [true, false],
    },
    relatedTo: {
      type: 'options',
      visible: data => data.applicable,
      options: ['employees', 'workers'],
    },
    employeesDescription: {
      type: 'textArea',
      visible: data => data.relatedTo.includes('employees'),
    },
    workersDescription: {
      type: 'textArea',
      visible: data => data.relatedTo.includes('workers'),
    },
  },

  initialValues: {
    applicable: null,
    relatedTo: [],
    employeesDescription: '',
    workersDescription: '',
  },
}

export default meta
