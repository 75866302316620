import enrichDraft from 'utils/enrichDraft'
import countries from '../countries'
import t from 'utils/translation'

const getCurrency = ({ country }) => [
  t(`options.countryToCurrency.${country}`) ?? 'USD',
]

const getNumber = x => (isFinite(x) ? Number(x) : 0)

export const sections = enrichDraft({
  diversity: {
    type: 'static',
    forms: {
      diversity: {
        score: ({ employeesByGender } = {}) => {
          const sum = employeesByGender
            ? Object.values(employeesByGender).reduce(
                (acc, group) =>
                  acc +
                  Object.values(group).reduce(
                    (groupAcc, gender) =>
                      groupAcc + (isFinite(gender) ? Number(gender) : 0),
                    0
                  ),
                0
              )
            : 0
          return {
            type: 'quantity',
            value: sum,
            unit: 'people',
          }
        },
        initialValues: {
          employeesByGender: {
            management: {
              male: '',
              female: '',
            },
            managers: {
              male: '',
              female: '',
            },
            employees: {
              male: '',
              female: '',
            },
          },
          employeesByAge: {
            management: {
              low: '',
              medium: '',
              high: '',
            },
            managers: {
              low: '',
              medium: '',
              high: '',
            },
            employees: {
              low: '',
              medium: '',
              high: '',
            },
          },
        },
        fields: {
          employeesByGender: {
            type: 'section',
            fields: {
              management: {
                type: 'section',
                fields: {
                  male: { type: 'int' },
                  female: { type: 'int' },
                },
              },
              managers: {
                type: 'section',
                fields: {
                  male: { type: 'int' },
                  female: { type: 'int' },
                },
              },
              employees: {
                type: 'section',
                fields: {
                  male: { type: 'int' },
                  female: { type: 'int' },
                },
              },
            },
          },
          employeesByAge: {
            type: 'section',
            fields: {
              management: {
                type: 'section',
                fields: {
                  low: { type: 'int' },
                  medium: { type: 'int' },
                  high: { type: 'int' },
                },
              },
              managers: {
                type: 'section',
                fields: {
                  low: { type: 'int' },
                  medium: { type: 'int' },
                  high: { type: 'int' },
                },
              },
              employees: {
                type: 'section',
                fields: {
                  low: { type: 'int' },
                  medium: { type: 'int' },
                  high: { type: 'int' },
                },
              },
            },
          },
        },
      },
      salaries: {
        score: ({ managers, employees } = {}) => {
          const groups = [managers, employees]
          let male = 0,
            female = 0
          console.log(managers, employees)
          if (managers && employees) {
            groups.forEach(group => {
              male +=
                getNumber(group.male.salary.amount) +
                getNumber(group.male.remuneration.amount)
              female +=
                getNumber(group.female.salary.amount) +
                getNumber(group.female.remuneration.amount)
            })
          }
          return {
            type: 'quantity',
            value: male !== 0 || female !== 0 ? (male / female) * 100 : '-',
            unit: '% Male / Female ratio',
          }
        },
        initialValues: {
          country: null,
          managers: {
            male: {
              salary: {
                amount: '',
                unit: null,
              },
              remuneration: {
                amount: '',
                unit: null,
              },
            },
            female: {
              salary: {
                amount: '',
                unit: null,
              },
              remuneration: {
                amount: '',
                unit: null,
              },
            },
          },
          employees: {
            male: {
              salary: {
                amount: '',
                unit: null,
              },
              remuneration: {
                amount: '',
                unit: null,
              },
            },
            female: {
              salary: {
                amount: '',
                unit: null,
              },
              remuneration: {
                amount: '',
                unit: null,
              },
            },
          },
        },
        fields: {
          country: {
            type: 'select',
            options: countries,
            optionsPrefix: 'countries',
          },
          managers: {
            type: 'section',
            fields: {
              male: {
                type: 'section',
                fields: {
                  salary: {
                    type: 'amount',
                    options: getCurrency,
                    optionsPrefix: 'currencies',
                  },
                  remuneration: {
                    type: 'amount',
                    options: getCurrency,
                    optionsPrefix: 'currencies',
                  },
                },
              },
              female: {
                type: 'section',
                fields: {
                  salary: {
                    type: 'amount',
                    options: getCurrency,
                    optionsPrefix: 'currencies',
                  },
                  remuneration: {
                    type: 'amount',
                    options: getCurrency,
                    optionsPrefix: 'currencies',
                  },
                },
              },
            },
          },
          employees: {
            type: 'section',
            fields: {
              male: {
                type: 'section',
                fields: {
                  salary: {
                    type: 'amount',
                    options: getCurrency,
                    optionsPrefix: 'currencies',
                  },
                  remuneration: {
                    type: 'amount',
                    options: getCurrency,
                    optionsPrefix: 'currencies',
                  },
                },
              },
              female: {
                type: 'section',
                fields: {
                  salary: {
                    type: 'amount',
                    options: getCurrency,
                    optionsPrefix: 'currencies',
                  },
                  remuneration: {
                    type: 'amount',
                    options: getCurrency,
                    optionsPrefix: 'currencies',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
})
