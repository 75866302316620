import calculateProgress from '../../../../utils/calculateProgress'

const meta = {
  score: calculateProgress,

  fields: {
    healthCare: {
      type: 'section',
      fields: {
        applicable: {
          type: 'options',
          options: [true, false],
        },
        typeOfAccess: {
          type: 'textArea',
          visible: data => data.healthCare.applicable,
        },
      },
    },
    wellness: {
      type: 'section',
      fields: {
        applicable: {
          type: 'options',
          options: [true, false],
          visible: () => true,
        },
        risksAddressed: {
          type: 'textArea',
          visible: data => data.wellness.applicable,
        },
        description: {
          type: 'textArea',
          visible: data => data.wellness.applicable,
        },
      },
    },
  },

  initialValues: {
    healthCare: {
      applicable: null,
      type: '',
    },
    wellness: {
      applicable: null,
      risksAddressed: '',
      description: '',
    },
  },
}

export default meta
