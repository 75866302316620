import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import useAuth, { logout } from 'providers/useAuth'
import { IconButton } from 'components/Button'
import Icon from 'components/Icon'
import { Container } from 'components/Layout'

import { media } from 'theme'
import Logo from './Logo'

const StyledHeader = styled.header`
  position: relative;
  background-color: ${theme('colors.dark')};
  color: white;
  z-index: 1003;
  padding: 1rem;
  width: 100%;
  a {
    color: currentColor;
  }
  ${Container} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
`

const HomeLink = styled(Link)`
  display: block;
`

const LoginLink = styled(Link)`
  display: block;
`

const StyledLogo = styled(Logo)`
  position: relative;
  z-index: 1003;
  height: 2rem;
  ${media('sm')`
    height: 3rem;
  `}
  margin: 0 auto;
  ${media('sm')`
    margin: 0;
  `}
`

const UserWrapper = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  label {
    margin: 0 1rem 0 0;
    font-size: 1.2rem;
  }
`

const HeaderButton = styled(IconButton)`
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
`

const User = props => {
  const { user } = useAuth()
  const location = useLocation()

  return user ? (
    <UserWrapper>
      <label>{user.email}</label>
      <HeaderButton onClick={logout} title="Log out" icon="logout" />
    </UserWrapper>
  ) : (
    <UserWrapper>
      <label>Anonymous</label>
      <LoginLink
        to={{
          pathname: '/login',
          state: { redirect: location.pathname },
        }}
        title="Log in"
      >
        <Icon type="login" />
      </LoginLink>
    </UserWrapper>
  )
}

const Header = ({ siteTitle }) => {
  return (
    <StyledHeader>
      <Container>
        <HomeLink to="/">
          <StyledLogo inverted tagline />
        </HomeLink>
        <User />
      </Container>
    </StyledHeader>
  )
}

export default Header
