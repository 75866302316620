import _t from 'utils/translation'
import BaseTemplate from '../BaseTemplate.jsx'
import { sections } from '../../Draft/403/meta'

const resultSections = {
  qualitative: {
    id: 'healthAndSafety',
    respondentSelect: true,
    forms: {
      healthAndManagement: { type: 'qualitative' },
      hazardRiskIncident: { type: 'qualitative' },
      occupationalHealthAndSafety: { type: 'qualitative' },
      workerParticipation: { type: 'qualitative' },
      workerTraining: { type: 'qualitative' },
      promotionOfWorkerHealth: { type: 'qualitative' },
      preventionAndMitigation: { type: 'qualitative' },
      workerCoverage: { type: 'qualitative' },
      workRelatedInjuries: { type: 'qualitative' },
      workRelatedIllHealth: { type: 'qualitative' },
    },
  },
}

const Template = ({ projectId, data }) => (
  <BaseTemplate
    meta={sections}
    resultSections={resultSections}
    data={data}
    t={_t(`drafts.doc403.sections`)}
  />
)

export default Template
