import t from 'utils/translation'

export const formatSubtitle = tree => data => {
  let subTree = tree
  while ((subTree = subTree[data[subTree._key]])) {
    if (typeof subTree === 'function') {
      return `${subTree(data)} – from ${t(
        'options.dataSource.' + data.dataSource
      )}`
    }
  }
  return 'Unknown'
}
