import { useEffect } from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import { Formik, Form } from 'formik'
import { nanoid } from 'nanoid'
import merge from 'utils/merge'

import BlockContent from 'components/BlockContent'
import FormField from './form/FormField'
import Button from './Button'

const Title = styled.h2`
  margin-bottom: 1rem;
  line-height: 1.3;
`

const Subtitle = styled.p`
  font-size: 0.8rem;
  font-weight: 300;
  white-space: pre-wrap;
  color: ${theme('colors.medium')};
`

const StyledButton = styled(Button)`
  width: 100%;
  margin: 1rem 0;
`

const RefreshValues = ({ values, initialValues, setValues }) => {
  useEffect(() => {
    setValues(merge({}, initialValues, values))
  }, [values, initialValues, setValues])
  return null
}

const renderFormField =
  (values, projectMeta, t, disabled) =>
  ([
    key,
    {
      type,
      label,
      options,
      isCreatable,
      description,
      visible,
      fields,
      optionsPrefix,
    },
  ]) =>
    visible === undefined || visible(values, projectMeta) ? (
      <FormField
        key={key}
        name={key}
        label={label}
        type={type}
        description={description}
        fields={fields}
        options={options}
        optionsPrefix={optionsPrefix}
        isCreatable={isCreatable}
        visible={visible}
        projectMeta={projectMeta}
        t={t?.(key)}
        disabled={disabled}
      />
    ) : null

const MetaForm = ({
  title,
  subtitle,
  description,
  meta,
  initialValues: inheritedValues,
  save,
  className,
  projectMeta,
  t,
  disabled,
}) => {
  return (
    <Formik
      initialValues={meta.initialValues}
      onSubmit={(data, { setSubmitting }) => {
        save({ id: nanoid(), ...data })
        setSubmitting(false)
      }}
      validate={meta.validate}
    >
      {({ isSubmitting, values, errors, setValues }) => (
        <Form className={className}>
          <RefreshValues
            initialValues={meta.initialValues}
            values={inheritedValues}
            setValues={setValues}
          />
          {title && <Title>{title}</Title>}
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
          {description && <BlockContent blocks={description} />}
          {Object.entries(meta.fields).map(
            renderFormField(
              values,
              projectMeta,
              t?.('fields', values),
              disabled
            )
          )}
          {!disabled && (
            <StyledButton
              disabled={isSubmitting || Object.keys(errors).length}
              type="submit"
            >
              Save
            </StyledButton>
          )}
          {/*          <h3>Debug</h3>
          <pre style={{ textAlign: 'left' }}>
            {JSON.stringify(values, null, 2)}
          </pre>
          <pre style={{ textAlign: 'left' }}>
            {JSON.stringify(errors, null, 2)}
          </pre>*/}
        </Form>
      )}
    </Formik>
  )
}

export default MetaForm
