import React, { useEffect } from 'react'
import styled from 'styled-components'
import { ifProp, theme } from 'styled-tools'
import { useField, useFormikContext } from 'formik'

import _t from 'utils/translation'
import BlockContent from 'components/BlockContent'
import Options from './Options'
import Select from './Select'
import Checkbox from './Checkbox'
import TextArea from './TextArea'
import Amount from './Amount'
import Icon from '../Icon'

const Wrapper = styled.div`
  position: relative;
  & + & {
    margin-top: 2rem;
  }
`

const Label = styled.label`
  font-size: 1.2rem;
  line-height: 1.3;
`

const Legend = styled.legend`
  font-size: 1.2rem;
  margin-bottom: 0.6rem;
`

const Description = styled(BlockContent)`
  font-size: 0.8rem;
`

const ErrorMessage = styled.p`
  font-size: 0.8rem;
  text-align: left;
  color: ${theme('colors.warning')};
`

const FieldSet = styled.fieldset`
  border: none;
  padding: 0.7rem;
  margin: 0;
  border: 1px solid #eee;
  border-radius: 4px;

  ${Label} {
    font-size: 1rem;
  }
`

const StyledInput = styled.input`
  &[disabled] {
    background-color: transparent;
  }
`

const ValidIcon = styled(Icon).attrs(() => ({ type: 'check' }))`
  color: ${ifProp('isValid', theme('colors.success'), theme('colors.light'))};
  display: inline-block;
  width: 1.2em;
  height: 1.2em;
  margin: 0 0 -0.25em 0.1em;
`

const getType = type =>
  ['int', 'float'].includes(type) ? 'number' : type || 'text'

const FormField = ({
  className,
  label,
  description,
  name,
  type,
  placeholder,
  disabled,
  options,
  optionsPrefix,
  isCreatable,
  fields,
  projectMeta,
  t,
}) => {
  const { values } = useFormikContext()
  const [field, meta, { setValue }] = useField(name)
  //eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => setValue(meta.initialValue), [])

  const labelStr = t?.('label') ?? label
  const descriptionStr = t?.('description')

  if (type === 'section') {
    return (
      <Wrapper>
        {labelStr && (
          <Legend>
            <span>{labelStr}</span>
            <ValidIcon isValid={!meta.error} />
          </Legend>
        )}
        {descriptionStr && <Description blocks={descriptionStr} />}
        <FieldSet>
          {fields &&
            Object.entries(fields).map(([key, item]) =>
              item.visible === undefined ||
              item.visible(values, projectMeta) ? (
                <FormField
                  className={className}
                  key={key}
                  label={item.label}
                  name={`${name}.${key}`}
                  type={item.type}
                  fields={item.fields}
                  description={item.description}
                  options={item.options}
                  optionsPrefix={item.optionsPrefix}
                  t={t?.(`fields.${key}`)}
                  disabled={disabled}
                />
              ) : null
            )}
        </FieldSet>
      </Wrapper>
    )
  }
  let mappedOptions =
    typeof options === 'function' ? options(values, projectMeta) : options

  const stringify = o =>
    typeof o === 'string'
      ? o
      : typeof o + o.toString()[0].toUpperCase() + o.toString().slice(1)

  mappedOptions = mappedOptions?.map(o => {
    if (o.value !== undefined) {
      return o
    }
    const key = stringify(o)
    return {
      value: o,
      label:
        t?.(`options.${key}`) ??
        _t(`options.${optionsPrefix ?? 'default'}.${key}`),
    }
  })

  return (
    <Wrapper className={className}>
      {type !== 'checkbox' && labelStr && (
        <Label htmlFor={name}>
          <span>{labelStr}</span>
          <ValidIcon isValid={!meta.error} />
        </Label>
      )}
      {descriptionStr && <Description blocks={descriptionStr} />}
      {type === 'options' ? (
        <Options
          options={mappedOptions}
          {...field}
          isMulti={Array.isArray(meta.initialValue)}
          disabled={disabled}
        />
      ) : type === 'select' ? (
        <Select
          options={mappedOptions}
          {...field}
          isCreatable={isCreatable}
          isMulti={Array.isArray(meta.initialValue)}
          disabled={disabled}
        />
      ) : type === 'checkbox' ? (
        <Checkbox
          name={name}
          label={labelStr ?? ''}
          disabled={disabled}
          {...field}
        />
      ) : type === 'textArea' ? (
        <TextArea name={name} disabled={disabled} {...field} />
      ) : type === 'amount' ? (
        <Amount name={name} options={mappedOptions} disabled={disabled} />
      ) : (
        <StyledInput
          type={getType(type)}
          pattern={type === 'int' ? '\\d*' : null}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          {...field}
        />
      )}
      {meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
    </Wrapper>
  )
}
export default FormField
