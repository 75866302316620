import _t from 'utils/translation'
import BaseTemplate from '../BaseTemplate.jsx'
import { sections } from '../../Draft/306/meta'

const resultSections = {
  qualitative: {
    id: 'waste',
    respondentSelect: true,
    forms: {
      generation: { type: 'qualitative' },
      management: { type: 'qualitative' },
      dataSource: { type: 'qualitative' },
    },
  },
  generated: {
    id: 'waste.forms.generated',
    type: 'dataTable',
    tableMeta: {
      fields: {
        hazardous: { label: 'Hazardous waste (tonnes)', format: 'number' },
        nonHazardous: {
          label: 'Non-hazardous waste (tonnes)',
          format: 'number',
        },

        id: { label: 'Id' },
        draftId: { label: 'Draft' },
        respondentEmail: { label: 'Respondent' },
        type: {
          label: 'Type',
        },
        timestamp: {
          label: 'Timestamp',
        },

        tag: {
          label: 'Tag',
        },
        total: {
          label: 'Total',
          format: 'number',
        },
      },
      columns: ['hazardous', 'nonHazardous', 'total'],
      rowKeys: ['tag', 'respondent'],
      columnTotals: true,
    },
    transformData: report => ({
      hazardous: report.generated.hazardous.amount,
      nonHazardous: report.generated.nonHazardous.amount,
    }),
  },
}

const Template = ({ projectId, data }) => (
  <BaseTemplate
    t={_t(`drafts.doc306.sections`)}
    meta={sections}
    resultSections={resultSections}
    data={data}
  />
)

export default Template
