import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import { nanoid } from 'nanoid'
import BaseBlockContent from '@sanity/block-content-to-react'

const Wrapper = styled.div`
  color: ${theme('colors.medium')};
  margin: 0.5em 0;
  p {
    font-size: 1em;
    margin: 0.625em 0.125em;
  }
  ul {
    list-style: initial;
    padding-left: 2rem;
  }
`

const wrap = str =>
  str.split('\n').map(line => ({
    _type: 'block',
    _key: nanoid(),
    style: 'normal',
    markDefs: [],
    children: [
      {
        _type: 'span',
        _key: nanoid(),
        text: line,
      },
    ],
  }))

const MyBlockContent = ({ className, blocks, ...props }) => {
  const wrappedBlocks = typeof blocks === 'string' ? wrap(blocks) : blocks
  return (
    <Wrapper className={className}>
      <BaseBlockContent blocks={wrappedBlocks} {...props} />
    </Wrapper>
  )
}

export default MyBlockContent
