import { useFirestore } from 'providers/Firestore'
import Loader from 'components/Loader'
import Template from './Template'

const RespondentOverview = ({ match }) => {
  const { projectId } = match.params
  const {
    data: drafts,
    loading: loadingDrafts,
    update,
    set,
    remove,
  } = useFirestore(`projects/${projectId}/drafts`)

  const {
    data: project,
    set: setMeta,
    loading: loadingProject,
  } = useFirestore(`projects/${projectId}`)

  return loadingDrafts || loadingProject ? (
    <Loader />
  ) : drafts ? (
    <Template
      drafts={drafts}
      update={update}
      set={set}
      setProjectMeta={(data, ...args) => setMeta({ _meta: data }, ...args)}
      remove={remove}
      projectMeta={project._meta}
    />
  ) : (
    <p>Nothing</p>
  )
}

export default RespondentOverview
