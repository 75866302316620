import enrichDraft from 'utils/enrichDraft'
export const sections = enrichDraft({
  supplier: {
    type: 'static',
    forms: {
      screening: {
        score: data => {
          if (data === undefined) {
            return
          }
          const { newSuppliers } = data
          const sum = [
            'environmental',
            'labour',
            'humanRights',
            'antiCorruption',
          ]
            .map(key => Number(newSuppliers[key]))
            .reduce((acc, cur) => acc + cur)

          return {
            type: 'quantity',
            value: sum,
            unit: 'screened suppliers',
          }
        },
        initialValues: {
          numberOfSuppliers: '',
          currentSuppliersScreened: null,
          newSuppliersScreened: null,
          newSuppliers: {
            total: '',
            environmental: '',
            labour: '',
            humanRights: '',
            antiCorruption: '',
          },
          screeningProcess: '',
          otherProcess: '',
        },
        fields: {
          numberOfSuppliers: { type: 'int' },
          currentSuppliersScreened: { type: 'options', options: [true, false] },
          newSuppliersScreened: { type: 'options', options: [true, false] },
          newSuppliers: {
            type: 'section',
            visible: ({ newSuppliersScreened }) => newSuppliersScreened,
            fields: {
              total: { type: 'int' },
              environmental: { type: 'int' },
              labour: { type: 'int' },
              humanRights: { type: 'int' },
              antiCorruption: { type: 'int' },
            },
          },
          screeningProcess: { type: 'textArea' },
          otherProcess: { type: 'textArea' },
        },
      },
      assessment: {
        score: data => {
          if (data === undefined) {
            return
          }
          const { numberOfAssessedSuppliers } = data
          const sum = [
            'environmental',
            'labour',
            'humanRights',
            'antiCorruption',
          ]
            .map(key => Number(numberOfAssessedSuppliers[key]))
            .reduce((acc, cur) => acc + cur)

          return {
            type: 'quantity',
            value: sum,
            unit: 'screened suppliers',
          }
        },
        initialValues: {
          numberOfAssessedSuppliers: {
            environmental: '',
            labour: '',
            humanRights: '',
            antiCorruption: '',
          },
          assessmentProcess: '',
          numberOfImprovingSuppliers: {
            environmental: '',
            labour: '',
            humanRights: '',
            antiCorruption: '',
          },
          numberOfImpactedSuppliers: {
            environmental: '',
            labour: '',
            humanRights: '',
            antiCorruption: '',
          },
          numberOfTerminatedSuppliers: {
            environmental: '',
            labour: '',
            humanRights: '',
            antiCorruption: '',
          },
          terminatedSuppliersDescription: '',
        },
        fields: {
          numberOfAssessedSuppliers: {
            type: 'section',
            fields: {
              environmental: { type: 'int' },
              labour: { type: 'int' },
              humanRights: { type: 'int' },
              antiCorruption: { type: 'int' },
            },
          },
          assessmentProcess: { type: 'textArea' },
          numberOfImpactedSuppliers: {
            type: 'section',
            fields: {
              environmental: { type: 'int' },
              labour: { type: 'int' },
              humanRights: { type: 'int' },
              antiCorruption: { type: 'int' },
            },
          },
          impactDescription: { type: 'textArea' },
          numberOfImprovingSuppliers: {
            type: 'section',
            fields: {
              environmental: { type: 'int' },
              labour: { type: 'int' },
              humanRights: { type: 'int' },
              antiCorruption: { type: 'int' },
            },
          },
          numberOfTerminatedSuppliers: {
            type: 'section',
            fields: {
              environmental: { type: 'int' },
              labour: { type: 'int' },
              humanRights: { type: 'int' },
              antiCorruption: { type: 'int' },
            },
          },
          terminatedSuppliersDescription: { type: 'textArea' },
        },
      },
    },
  },
})
