import calculateProgress from '../../../../utils/calculateProgress'

const meta = {
  title: 'Workers covered by an occupational health and safety system (403-8)',
  subtitle: '',
  score: calculateProgress,

  fields: {
    applicable: {
      type: 'options',
      options: [true, false],
      visible: () => true,
    },
    relatedTo: {
      type: 'options',
      visible: data => data.applicable,
      options: ['employees', 'workers'],
    },
    numberOfEmployees: {
      type: 'section',
      visible: data => data.relatedTo.includes('employees'),
      fields: {
        covered: {
          type: 'int',
        },
        auditedInternally: {
          type: 'int',
        },
        auditedExternally: {
          type: 'int',
        },
      },
    },
    numberOfWorkers: {
      type: 'section',
      visible: data => data.relatedTo.includes('workers'),
      fields: {
        covered: {
          type: 'int',
        },
        auditedInternally: {
          type: 'int',
        },
        auditedExternally: {
          type: 'int',
        },
      },
    },
    workersExcluded: {
      type: 'options',
      options: [true, false],
    },
    workersExcludedDescription: {
      type: 'textArea',
      visible: data => data.workersExcluded,
    },
    dataSource: {
      type: 'textArea',
    },
  },

  initialValues: {
    applicable: null,
    relatedTo: [],
    numberOfEmployees: {
      covered: '',
      auditedInternally: '',
      auditedExternally: '',
    },
    numberOfWorkers: {
      covered: '',
      auditedInternally: '',
      auditedExternally: '',
    },
    workersExcluded: null,
    workersExcludedDescription: '',
    dataSource: '',
  },
}

export default meta
