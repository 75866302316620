import t from 'utils/translation'

const fuelT = (key, ...args) => t(`options.fuels.${key}`, ...args)
const unitT = (key, ...args) => t(`options.units.${key}`, ...args)
const countryT = (key, ...args) => t(`options.countries.${key}`, ...args)

const _t = (section, key, value) =>
  t(`drafts.doc305.sections.${section}.fields.${key}.options.${value}`)

export const combustion = {
  _key: 'method',
  estimated: data =>
    `Estimated to ${data.amount.amount} ${t(data.amount.unit)}`,
  provided: data => `Provided as ${data.amount.amount} ${t(data.amount.unit)}`,
  calculated: {
    _key: 'activity',
    car: {
      _key: 'typeOfCarEstimation',
      fuel: data =>
        `Calculated from ${data.amount.amount} ${unitT(
          data.amount.unit
        )} of ${fuelT(data.fuel)}`,
      distance: data =>
        `Calculated from ${data.amount.amount} ${unitT(
          data.amount.unit
        )} with ${_t('combustion', 'typeOfCar', data.typeOfCar)} car`,
    },
    stove: data =>
      `Calculated from ${data.amount.amount} ${unitT(
        data.amount.unit
      )} of ${fuelT(data.fuel)}`,
    heating: data =>
      `Calculated from ${data.amount.amount} ${unitT(
        data.amount.unit
      )} of ${fuelT(data.fuel)}`,
  },
}

export const electricityHeatingCooling = {
  _key: 'method',
  estimated: data =>
    `Estimated to ${data.amount.amount} ${unitT(data.amount.unit)}`,
  provided: data =>
    `Provided as ${data.amount.amount} ${unitT(data.amount.unit)}`,
  calculated: data =>
    data.emissionFactor !== 0
      ? `Calculated from ${data.amount.amount} ${unitT(
          data.amount.unit
        )} with ${data.emissionFactor} kg CO2e/kWh`
      : `Calculated from ${data.amount.amount} ${unitT(
          data.amount.unit
        )} in ${countryT(data.country)}`,
}

const typeT = (key, ...args) =>
  t(`drafts.doc305.sections.travel.fields.type.options.${key}`, ...args)
const modeT = (key, ...args) =>
  t(`drafts.doc305.sections.travel.fields.mode.options.${key}`, ...args)

export const travel = {
  _key: 'method',
  estimated: data =>
    `Estimated to ${data.amount.amount} ${unitT(data.amount.unit)}`,
  provided: data =>
    `Provided as ${data.amount.amount} ${unitT(data.amount.unit)}`,
  calculated: {
    _key: 'activity',
    air: data =>
      `Calculated from ${data.amount.amount} ${unitT(
        data.amount.unit
      )} for ${typeT(data.type, data)} on ${modeT(data.mode)} flight`,
    land: {
      _key: 'mode',
      rail: data =>
        `Calculated from ${data.amount.amount} ${unitT(
          data.amount.unit
        )} for ${typeT(data.type, data)} rail`,
      bus: data =>
        `Calculated from ${data.amount.amount} ${unitT(
          data.amount.unit
        )} for ${typeT(data.type, data)}`,
      rentedCar: data =>
        `Calculated from ${data.amount.amount} ${unitT(
          data.amount.unit
        )} for ${typeT(data.type)} rented car`,
      taxi: data =>
        `Calculated from ${data.amount.amount} ${unitT(
          data.amount.unit
        )} for ${typeT(data.type)}`,
    },
    sea: data =>
      `Calculated from ${data.amount.amount} ${unitT(
        data.amount.unit
      )} by ${typeT(data.type, data)}`,
  },
}

export const leasedCars = {
  _key: 'method',
  estimated: data =>
    `Estimated to ${data.amount.amount} ${unitT(data.amount.unit)}`,
  provided: data =>
    `Provided as ${data.amount.amount} ${unitT(data.amount.unit)}`,
  calculated: {
    _key: 'typeOfCarEstimation',
    fuel: data =>
      `Calculated from ${data.amount.amount} ${unitT(
        data.amount.unit
      )} of ${fuelT(data.fuel)}`,
    distance: data =>
      `Calculated from ${data.amount.amount} ${unitT(
        data.amount.unit
      )} with ${typeT(data.typeOfCar)}`,
  },
}
