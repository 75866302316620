const meta = {
  fields: {
    contact: {
      type: 'section',
      fields: {
        name: {
          type: 'text',
        },
        position: {
          type: 'text',
        },
        email: {
          type: 'text',
        },
      },
    },
    time: {
      type: 'section',
      fields: {
        cycle: {
          type: 'text',
        },
        period: {
          type: 'section',
          fields: {
            from: {
              type: 'date',
            },
            to: {
              type: 'date',
            },
          },
        },
      },
    },
    questions: {
      type: 'section',
      fields: {
        isFirst: {
          type: 'options',
          options: [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ],
        },
        mostRecentReportDate: {
          type: 'date',
          visible: data => data.questions.isFirst === false,
        },
        changes: {
          type: 'textArea',
          visible: data => data.questions.isFirst === false,
        },
        needsCorrections: {
          type: 'options',
          options: [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ],
          visible: data => data.questions.isFirst === false,
        },
        corrections: {
          type: 'textArea',
          visible: data => data.questions.needsCorrections,
        },
        appliesPrecautionaryApproach: {
          type: 'options',
          options: [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ],
        },
        precautionaryApproach: {
          type: 'textArea',
          visible: data => data.questions.appliesPrecautionaryApproach,
        },
      },
    },
    principles: {
      type: 'section',
      fields: {
        principles: {
          type: 'options',
          options: [
            { value: 'stakeholder', label: 'Stakeholder inclusiveness' },
            { value: 'sustainability', label: 'Sustainability context' },
            { value: 'materiality', label: 'Materiality' },
            { value: 'completeness', label: 'Completeness' },
            { value: 'accuracy', label: 'Accuracy' },
            { value: 'balance', label: 'Balance' },
            { value: 'clarity', label: 'Clarity' },
            { value: 'comparability', label: 'Comparability' },
            { value: 'reliability', label: 'Reliability' },
            { value: 'timeliness', label: 'Timeliness' },
          ],
        },
        stakeholder: {
          type: 'textArea',
          visible: data => data.principles.principles.includes('stakeholder'),
        },
        sustainability: {
          type: 'textArea',
          visible: data =>
            data.principles.principles.includes('sustainability'),
        },
        materiality: {
          type: 'textArea',
          visible: data => data.principles.principles.includes('materiality'),
        },
        completeness: {
          type: 'textArea',
          visible: data => data.principles.principles.includes('completeness'),
        },
        accuracy: {
          type: 'textArea',
          visible: data => data.principles.principles.includes('accuracy'),
        },
        balance: {
          type: 'textArea',
          visible: data => data.principles.principles.includes('balance'),
        },
        clarity: {
          type: 'textArea',
          visible: data => data.principles.principles.includes('clarity'),
        },
        comparability: {
          type: 'textArea',
          visible: data => data.principles.principles.includes('comparability'),
        },
        reliability: {
          type: 'textArea',
          visible: data => data.principles.principles.includes('reliability'),
        },
        timeliness: {
          type: 'textArea',
          visible: data => data.principles.principles.includes('timeliness'),
        },
      },
    },
  },
  initialValues: {
    contact: {
      name: '',
      position: '',
      email: '',
    },
    time: {
      cycle: '',
      period: {
        from: '',
        to: '',
      },
    },
    questions: {
      isFirst: null,
      mostRecentReportDate: '',
      changes: '',
      needsCorrections: null,
      corrections: '',
      appliesPrecautionaryApproach: null,
      precautionaryApproach: '',
    },
    principles: {
      principles: [],
      stakeholder: '',
      sustainability: '',
      materiality: '',
      completeness: '',
      accuracy: '',
      balance: '',
      clarity: '',
      comparability: '',
      reliability: '',
      timeliness: '',
    },
  },
}

export default meta
