// From Excel
const exchangeRatesToSek = {
  SEK: 1,
  AFN: 0.1007,
  EUR: 10.1469,
  ALL: 0.08338,
  DZD: 0.0638,
  USD: 8.614,
  AOA: 72.01,
  XCD: 3.1665,
  ARS: 0.0876,
  AMD: 106.22,
  AWG: 4.6809,
  AUD: 6.296,
  AZN: 5.0518,
  BSD: 8.6129,
  BHD: 22.8489,
  BDT: 0.1009,
  BBD: 4.2223,
  BYN: 3.4607,
  BZD: 4.2361,
  XOF: 0.001779,
  BMD: 8.6129,
  INR: 0.1172,
  BTN: 3.1,
  BOB: 1.2339,
  BOV: 9.3,
  BAM: 5.1627,
  BWP: 0.7744,
  NOK: 1.0019,
  BRL: 1.6383,
  BND: 6.3989,
  BGN: 5.1885,
  BIF: 0.004306,
  CVE: 0.09121,
  KHR: 0.002106,
  XAF: 0.01507,
  CAD: 6.8068,
  KYD: 10.32,
  CLP: 0.01099,
  CLF: 23.07,
  CNY: 1.3333,
  COP: 0.002255,
  KMF: 0.02049,
  CDF: 0.004325,
  NZD: 6.0893,
  CRC: 0.01373,
  HRK: 1.3511,
  CUP: 0.9,
  CUC: 14.99,
  ANG: 0.23,
  CZK: 0.4005,
  DKK: 1.3649,
  DJF: 0.04824,
  DOP: 0.1517,
  EGP: 0.5465,
  SVC: 10.83,
  ERN: 0.06601,
  SZL: 0.5884,
  ETB: 0.1848,
  FKP: 1.3795,
  FJD: 4.0903,
  XPF: 0.08485,
  GMD: 0.1625,
  GEL: 2.7521,
  GHS: 1.4098,
  GIP: 7.38,
  GTQ: 1.1115,
  GBP: 11.8744,
  GNF: 0.0008766,
  GYD: 0.004752,
  HTG: 0.0879,
  HNL: 0.3566,
  HKD: 1.1066,
  HUF: 0.02888,
  ISK: 0.067,
  IDR: 0.000605,
  XDR: 12.2545,
  IRR: 20.5695,
  IQD: 0.005895,
  ILS: 2.6842,
  JMD: 0.05775,
  JPY: 0.0783,
  JOD: 12.132,
  KZT: 0.02023,
  KES: 0.07809,
  KRW: 0.00732,
  KWD: 28.5953,
  KGS: 0.065,
  LAK: 0.000895,
  LBP: 0.005673,
  LSL: 0.5882,
  ZAR: 0.5885,
  LRD: 0.04997,
  LYD: 1.9032,
  CHF: 9.2893,
  MOP: 1.0727,
  MKD: 0.1645,
  MGA: 0.002153,
  MWK: 0.01048,
  MYR: 2.0642,
  MVR: 0.5557,
  MRU: 0.2358,
  MUR: 0.1998,
  XUA: 40.1064,
  MXN: 0.4317,
  MDL: 0.4866,
  MNT: 0.0003472,
  MAD: 0.96,
  MZN: 0.1325,
  MMK: 0.004486,
  NAD: 0.5884,
  NPR: 0.07325,
  NIO: 0.245,
  NGN: 0.02091,
  OMR: 22.372,
  PKR: 0.05132,
  PAB: 8.6129,
  PGK: 2.3901,
  PYG: 0.001248,
  PEN: 2.0909,
  PHP: 0.1724,
  PLN: 2.2155,
  QAR: 2.3649,
  RON: 2.0498,
  RUB: 0.1185,
  RWF: 0.008444,
  SHP: 11.87,
  WST: 454.47,
  STN: 0.4059,
  SAR: 2.2965,
  RSD: 0.08622,
  SCR: 0.6675,
  SLL: 0.000814,
  SGD: 6.3989,
  XSU: 44.81,
  SBD: 1.0465,
  SOS: 0.0148,
  SSP: 17.33,
  LKR: 0.04307,
  SDG: 21.4,
  SRD: 0.04629,
  CHE: 478.11,
  CHW: 10.32,
  SYP: 0.003426,
  TWD: 0.3107,
  TJS: 0.0882,
  TZS: 0.003706,
  THB: 0.2593,
  TOP: 3.7518,
  TTD: 1.2618,
  TND: 3.0922,
  TRY: 1.0029,
  TMT: 2.4608,
  UGX: 0.002438,
  UAH: 0.3228,
  AED: 2.3449,
  UYU: 0.2018,
  UZS: 0.000805,
  VUV: 0.00895,
  VES: 0.000002,
  VND: 0.00004391,
  YER: 0.03439,
  ZMW: 0.5244,
}

export const convert = (amount, sourceCurrency, targetCurrency) =>
  (amount * exchangeRatesToSek[sourceCurrency]) /
  exchangeRatesToSek[targetCurrency]
