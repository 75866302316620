import get from 'lodash.get'
import drafts from './drafts'
import options from './options'
import respondentOverview from './respondentOverview.json'
import projectOverview from './projectOverview.json'
import override from './overrides'
import otherOptions from './options.json'

const translations = override({
  ...options,
  drafts,
  respondentOverview,
  projectOverview,
  options: otherOptions,
})
//console.log(translations)
const translate = (key, values = {}, projectMeta = {}) => {
  const item = get(translations, key)
  if (typeof item === 'object' && !Array.isArray(item)) {
    return (nextKey, nextValues, nextProjectMeta) =>
      translate(
        `${key}.${nextKey}`,
        nextValues ?? values,
        nextProjectMeta ?? projectMeta
      )
  }
  const val = typeof item === 'function' ? item(values, projectMeta) : item
  //console.debug('translating', key, val ?? translations, values)
  if (val === undefined) {
    //console.warn(`No translator found for key ${key}`)
  }
  return val
}

export default translate
