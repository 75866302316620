const config = {
  firestore: {
    apiKey: 'AIzaSyCf87aNVEUdp5W8pMLWucN01rk_ZJrnDes',
    authDomain: 'ethos-62d11.firebaseapp.com',
    projectId: 'ethos-62d11',
    storageBucket: 'ethos-62d11.appspot.com',
    messagingSenderId: '530885018052',
    appId: '1:530885018052:web:d58b5970d6e66b4a79e491',
  },
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
}

export default config
