import styled from 'styled-components'
import { theme } from 'styled-tools'

const statusColors = {
  new: theme('colors.medium'),
  started: theme('colors.primary'),
  readyForReview: theme('colors.orange'),
  reviewing: theme('colors.orange'),
  changeRequired: theme('colors.warning'),
  submitted: theme('colors.success'),
}

const statusLabels = {
  new: 'New',
  started: 'Started',
  readyForReview: 'Ready For Review',
  reviewing: 'Reviewing',
  changeRequired: 'Changes required',
  submitted: 'Submitted',
}

const StatusText = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  margin-right: 2rem;
  color: ${props => statusColors[props.status] ?? `red`};
  }
`

export const getNextStatus = (currentStatus, isLoggedIn) => {
  if (isLoggedIn && ['readyForReview', 'reviewing'].includes(currentStatus)) {
    return 'reviewing'
  } else if (
    !isLoggedIn &&
    [undefined, 'new', 'started', 'changeRequired'].includes(currentStatus)
  ) {
    return 'started'
  } else {
    console.error(
      `Illegal status/login combination (${currentStatus} / ${isLoggedIn})`
    )
  }
}

const Status = ({ status }) => (
  <StatusText status={status ?? 'new'}>
    {statusLabels[status] ?? 'new'}
  </StatusText>
)

export default Status
