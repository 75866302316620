import doc205 from './doc205.json'
import doc301 from './doc301.json'
import doc302 from './doc302.json'
import doc303 from './doc303.json'
import doc305 from './doc305.json'
import doc306 from './doc306.json'
import doc308 from './doc308.json'
import doc401 from './doc401.json'
import doc403 from './doc403.json'
import doc404 from './doc404.json'
import doc405 from './doc405.json'
import doc416 from './doc416.json'
import doc418 from './doc418.json'
import generalDisclosures from './generalDisclosures.json'

const exports = {
  doc205,
  doc301,
  doc302,
  doc303,
  doc305,
  doc306,
  doc308,
  doc403,
  doc401,
  doc404,
  doc405,
  doc416,
  doc418,
  generalDisclosures,
}

export default exports
