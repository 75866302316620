import enrichDraft from 'utils/enrichDraft'

export const sections = enrichDraft({
  customerHealthAndSafety: {
    type: 'static',
    forms: {
      customerHealthAndSafety: {
        initialValues: {
          doAssess: null,
          definitionOfSignificant: '',
          numberOfSignificantItems: '',
          numberOfAssessedSignificantItems: '',
        },
        fields: {
          doAssess: {
            type: 'options',
            options: [true, false],
          },
          definitionOfSignificant: {
            type: 'textArea',
          },
          numberOfSignificantItems: {
            type: 'int',
          },
          numberOfAssessedSignificantItems: {
            type: 'int',
          },
        },
      },
      incidents: {
        initialValues: {
          occurred: null,
          numberOfIncidents: {
            resultingInPenalty: '',
            resultingInWarning: '',
            voluntaryCodes: '',
          },
        },
        fields: {
          occurred: {
            type: 'options',
            options: [true, false],
          },
          numberOfIncidents: {
            type: 'section',
            visible: ({ occurred }) => occurred,
            fields: {
              resultingInPenalty: {
                type: 'int',
              },
              resultingInWarning: {
                type: 'int',
              },
              voluntaryCodes: {
                type: 'int',
              },
            },
          },
        },
      },
    },
  },
})
