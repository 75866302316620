import _t from 'utils/translation'
import BaseTemplate from '../BaseTemplate.jsx'
import { sections } from '../../Draft/303/meta'

const resultSections = {
  quantitative: {
    id: 'water',
    forms: {
      withdrawal: {
        type: 'dataTable',
        tableMeta: {
          fields: {
            surfaceWater: { label: 'Surface Water', format: 'number' },
            surfaceWaterFresh: {
              label: 'Surface Water (fresh)',
              format: 'number',
            },
            groundWater: { label: 'Ground Water', format: 'number' },
            groundWaterFresh: {
              label: 'Ground Water (fresh)',
              format: 'number',
            },
            respondentEmail: { label: 'Respondent' },
            tag: { label: 'Tag' },
          },
          columns: [
            'surfaceWater',
            'surfaceWaterFresh',
            'groundWater',
            'groundWaterFresh',
          ],
          columnTotals: true,
          rowKeys: ['tag', 'respondent'],
        },
        transformData: (report, formId) => ({
          ...Object.fromEntries(
            Object.entries(report[formId]).map(([k, v]) => [k, v.total])
          ),
          ...Object.fromEntries(
            Object.entries(report[formId]).map(([k, v]) => [
              `${k}Fresh`,
              v.fresh,
            ])
          ),
        }),
      },
    },
  },
  qualitative: {
    respondentSelect: true,
    id: 'water',
    forms: {
      interactions: { type: 'qualitative' },
      identification: { type: 'qualitative' },
      addressing: { type: 'qualitative' },
      goals: { type: 'qualitative' },
      discharge: { type: 'qualitative' },
    },
  },
}

const Template = ({ projectId, data }) => (
  <BaseTemplate
    meta={sections}
    resultSections={resultSections}
    data={data}
    t={_t(`drafts.doc303.sections`)}
  />
)

export default Template
