import _t from 'utils/translation'
import BaseTemplate from '../BaseTemplate.jsx'
import { sections } from '../../Draft/205/meta'

const resultSections = {
  incidentCount: {
    id: 'corruption',
    type: 'dataTable',
    title: 'Confirmed Incidents of Corruption',
    tableMeta: {
      fields: {
        total: {
          label: 'Total confirmed incidents',
          format: 'number',
        },
        employeesDismissed: {
          label: 'Incidents with employees dismissed',
          format: 'number',
        },
        partnersDismissed: {
          label: 'Incidents with partner relationships affected ',
          format: 'number',
        },
        respondentEmail: { label: 'Respondent' },
        tag: { label: 'Tag' },
      },
      columns: ['total', 'employeesDismissed', 'partnersDismissed'],
      columnTotals: true,
      rowKeys: ['tag', 'respondent'],
    },
    transformData: (report, formId) => ({
      total: report.corruption.numberOfIncidents,
      employeesDismissed: report.corruption.numberOfEmployeeActions,
      partnersDismissed: report.corruption.numberOfContractActions,
    }),
  },

  legalAction: {
    id: 'corruption',
    respondentSelect: true,
    forms: {
      corruption: {
        type: 'qualitative',
        fields: {
          applicable: { visible: false },
          numberOfIncidents: { visible: false },
          numberOfEmployeeActions: { visible: false },
          numberOfContractActions: { visible: false },
        },
      },
    },
  },
}

const Template = ({ projectId, data }) => (
  <BaseTemplate
    t={_t(`drafts.doc205.sections`)}
    meta={sections}
    resultSections={resultSections}
    data={data}
  />
)

export default Template
