import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { QueryClient, QueryClientProvider } from 'react-query'
import config from 'config'
import { theme, GlobalStyle } from 'theme'
import { FirestoreProvider } from 'providers/Firestore'

const queryClient = new QueryClient()

const Root = ({ children }) => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyle />
      <QueryClientProvider client={queryClient}>
        <FirestoreProvider config={config.firestore}>
          <Router>{children}</Router>
        </FirestoreProvider>
      </QueryClientProvider>
    </>
  </ThemeProvider>
)

export default Root
