import _t from 'utils/translation'
import BaseTemplate from '../BaseTemplate.jsx'
import { sections } from '../../Draft/301/meta'

const resultSections = {
  materialsUsed: {
    id: 'materials.forms.materialsUsed',
    type: 'dataTable',
    tableMeta: {
      fields: {
        nonRenewable: {
          label: 'Non-renewable materials (kg)',
          format: 'number',
        },
        renewable: {
          label: 'Renewable materials (kg)',
          format: 'number',
        },
        recycled: {
          label: 'Recycled materials (kg)',
          format: 'number',
        },
        renewablePercent: {
          label: '% renewable',
          format: 'percentage',
          fn: ({ renewable, nonRenewable }) =>
            renewable / (renewable + nonRenewable),
        },
        respondentEmail: { label: 'Respondent' },
        tag: { label: 'Tag' },
        total: {
          label: 'Total',
          format: 'number',
        },
      },
      columns: [
        'nonRenewable',
        'renewable',
        'renewablePercent',
        'recycled',
        'total',
      ],
      rowKeys: ['tag', 'respondent'],
      columnTotals: true,
    },
    transformData: report => ({
      nonRenewable: report.materialsUsed.materialsUsed.nonRenewable.amount,
      renewable: report.materialsUsed.materialsUsed.renewable.amount,
      recycled: report.recycledMaterialsUsed.materialsUsed.amount,
    }),
  },
  qualitative: {
    id: 'materials',
    respondentSelect: true,
    forms: {
      materialsUsed: {
        type: 'qualitative',
        fields: {
          materialsUsed: { visible: false },
        },
      },
      recycledMaterialsUsed: {
        type: 'qualitative',
        fields: {
          materialsUsed: { visible: false },
        },
      },
    },
  },
}

const Template = ({ projectId, data }) => (
  <BaseTemplate
    t={_t(`drafts.doc301.sections`)}
    meta={sections}
    resultSections={resultSections}
    data={data}
  />
)

export default Template
