import { createElement } from 'react'
import styled from 'styled-components'
import { theme, withProp } from 'styled-tools'
import { tint } from 'polished'

const formatNumber = (number, decimals = 2) =>
  typeof number !== 'number'
    ? number
    : number
        .toLocaleString('en-US', {
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals,
        })
        .replaceAll(',', ' ')

const Wrapper = styled.div`
  padding: 0.2rem 1rem;
  flex-shrink: 0;
  text-align: right;
`

const Value = styled.span`
  display: block;
  font-size: 2.5rem;
  line-height: 1;
  font-weight: 600;
  color: ${theme('colors.primary')};
  margin: 0;
`

const Label = styled.span`
  display: block;
  font-size: 0.8rem;
  color: ${theme('colors.medium')};
  margin: 0.1rem 0 0;
`

const Quantity = ({ value, unit, decimals }) => (
  <Wrapper>
    <Value>{formatNumber(value, decimals)}</Value>
    <Label>{unit}</Label>
  </Wrapper>
)

const SVG = styled.svg`
  fill: ${theme('colors.primary')};
  circle {
    fill: ${withProp('theme.colors.primary', tint(0.85))}
  }
  width: 2.5rem;
  height 2.5rem;
  display: block;
  margin: 0 auto;
`
const Circle = ({ amount, className }) => (
  <SVG className={className} viewBox="-1 -1 2 2">
    <circle r="1" />
    <path
      d={`
        M 0 -1
        ${amount > 0.5 ? 'A 1 1  0 0 1 0 1' : ''}
        A 1 1 0 0 1 ${Math.sin(2 * Math.PI * amount)} ${-Math.cos(
        2 * Math.PI * amount
      )} L 0 0 z`}
    />
  </SVG>
)

const CenteredLabel = styled(Label)`
  text-align: center;
`

const Progress = ({ value }) => (
  <Wrapper>
    <Circle amount={value} />
    <CenteredLabel>{formatNumber(value * 100)} %</CenteredLabel>
  </Wrapper>
)

const Text = ({ value }) => (
  <Wrapper>
    <Label>{value}</Label>
  </Wrapper>
)

const StyledA = styled.a``

const Url = ({ value, label }) => (
  <Wrapper>
    <StyledA href={value}>{label}</StyledA>
  </Wrapper>
)

const List = ({ items }) => (
  <Wrapper>
    {items.map((p, i) => (
      <MetricSummary key={i} {...p} />
    ))}
  </Wrapper>
)

const Custom = ({ render }) => <Wrapper>{render()}</Wrapper>

const displays = {
  quantity: Quantity,
  progress: Progress,
  text: Text,
  url: Url,
  list: List,
  custom: Custom,
}

const MetricSummary = ({ type, ...props }) =>
  displays[type] ? createElement(displays[type], props) : null

export default MetricSummary
