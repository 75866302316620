import defaultsDeep from 'lodash.defaultsdeep'
import mapValues from 'lodash.mapvalues'
import get from 'lodash.get'
import {
  combustion,
  electricityHeatingCooling,
  travel,
  leasedCars,
} from './subtitleTrees'
import { formatSubtitle } from 'utils/formatSubtitle'
import t from 'utils/translation'

const override = old => {
  const managementApproaches = {
    drafts: mapValues(old.drafts, () => ({
      sections: {
        managementApproach: {
          title: 'Management approach',
          forms: {
            managementApproach: {
              title: 'Management approach',
              fields: {
                description: {
                  label:
                    'Describe why your organisation consider this topic to be material',
                  description: `
To be material means that something is important and relevant for the organisation to manage, and a topic that the organisation should report on.
Describe the type of impact (positive and negative) that is caused by your organisation's actitivies.  Describe what significant impacts  (for example: ""the manufacturing of our textiles require large quantities of water and effluents"") that have been identified, as well as the process used to identify the impact, for example a due diligence, an impact analysis or roundtable discussion with managers. The impact can be positive or negative.
`.trim(),
                },
                occurrence: {
                  label: 'Describe where the impact occurs',
                  description:
                    `The impact can occur either through the organisation's own activities (such as at company HQs, company owned and controlled factories, or at a subsidiary) or as a result of business relationships along the value chain, such as "our chemical suppliers in region X"`.trim(),
                },
                involvement: {
                  label:
                    'Describe how the organisation is involved with the impact',
                  description:
                    `This question is connected to the previous question, which requested information about where (geographical location and/or part of value chain). Now we would like to know how the organisation is involved. For example, if the impact has been identified as taking place in the supply chain in region/country X, what is your organisation's contribution? Is it indirect or direct? Does the organisation have any leverage over the impact?`.trim(),
                },
                management: {
                  label: 'Describe how the organisation manages the topic',
                  description:
                    `The description should be sufficient for the reader to understand how the organisational approach and management of the topic. It could include systematic performance reviews, risk analysis, follow-up on targets and the establishment of new targets, and so on.`.trim(),
                },
                purpose: {
                  label: 'Describe the purpose of the management approach',
                  description:
                    `An approach to a topic could have the purpose to: to reduce negative impact, mitigate risks, enhance positive impacts.`.trim(),
                },
                componentDescription: {
                  label:
                    'If included in the management approach, describe each of the following components:',
                  description: `
• policies
• commitments
• goals and targets
• responsibilities
• resources
• grievance mechanisms
• specific actions such as processes, projects, programs and initiatives
Do not leave any field blank. If a component is not included in the management approach, please include a short description of it, such as "it is the first time that we are measuring this, and we have not had the time to define goals and targets yet. This will be addressed in the upcoming year"`.trim(),
                },
                evaluation: {
                  label:
                    'Describe how the organisation evaluates its management approach',
                  description:
                    `The description should be sufficient for the reader to understand how the organisation evaluates the management approach, and if needed, adjusts it. This could include an annual follow-up on targets, a performance survey, or dialogue with concerned stakeholders.`.trim(),
                },
              },
            },
          },
        },
      },
    })),
  }

  const comments = {
    drafts: mapValues(old.drafts, draft => ({
      sections: mapValues(draft.sections, section =>
        section.fields
          ? {
              fields: {
                comment: {
                  label: 'Comment',
                },
              },
            }
          : {
              forms: mapValues(section.forms, form => ({
                fields: {
                  comment: {
                    label: 'Comment',
                  },
                },
              })),
            }
      ),
    })),
  }

  return defaultsDeep(
    managementApproaches,
    comments,
    {
      drafts: {
        doc305: {
          sections: {
            summary: {
              format: {
                title: data => data.title,
                subtitle: data => data.subtitle,
              },
            },
            combustion: {
              format: {
                title: data =>
                  t(
                    `drafts.doc305.sections.combustion.fields.activity.options.${data.activity}`
                  ),
                subtitle: data => formatSubtitle(combustion)(data),
              },
              fields: {
                fuel: {
                  options: {
                    petrolBio: 'Petrol biofuel blend',
                    petrol: 'Petrol non biofuel blend',
                    dieselBio: 'Diesel biofuel blend',
                    diesel: 'Diesel non biofuel blend',
                  },
                },
              },
            },
            electricityHeatingCooling: {
              format: {
                title: data =>
                  t(
                    `drafts.doc305.sections.electricityHeatingCooling.fields.activity.options.${data.activity}`
                  ),
                subtitle: data =>
                  formatSubtitle(electricityHeatingCooling)(data),
              },
            },
            travel: {
              format: {
                title: data =>
                  t(
                    `drafts.doc305.sections.travel.fields.activity.options.${data.activity}`
                  ),
                subtitle: formatSubtitle(travel),
              },
              fields: {
                amount: {
                  label: ({ method }) =>
                    method === 'calculated' ? 'Total distance' : 'Total CO2e',
                },
                type: {
                  options: {
                    economy: 'Economy Class',
                    premium: 'Premium Economy Class',
                    business: 'Business Class',
                    first: 'First Class',
                    localNotLondon: 'Local bus (not London)',
                    localLondon: 'Local bus (London)',
                    localAverage: 'Average Local Bus',
                    coach: 'Coach',
                    national: 'National Rail',
                    international: 'International Rail',
                    light: 'Light rail and tram',
                    underground: 'London underground',
                    small: 'Small car',
                    medium: 'Medium car',
                    large: 'Large car',
                    regular: 'Regular taxi',
                    black: 'Black cab',
                    foot: 'Foot ferry',
                    car: 'Car ferry',
                    average: data => {
                      const map = {
                        air: 'passenger',
                        land: 'car',
                        sea: 'ferry',
                      }
                      return `Average ${map[data.activity]}`
                    },
                  },
                },
                mode: {
                  options: {
                    domestic: 'Domestic',
                    shortHaul: 'Short Haul',
                    longHaul: 'Long Haul',
                    international: 'International',
                    bus: 'Bus',
                    rail: 'Rail',
                    rentedCar: 'Rented Car',
                    taxi: 'Taxi',
                    ferry: 'Ferry',
                  },
                },
              },
            },
            leasedCars: {
              format: {
                title: 'Leased car',
                subtitle: formatSubtitle(leasedCars),
              },
              fields: {
                amount: {
                  label: ({ typeOfCarEstimation }) =>
                    `Total ${typeOfCarEstimation ?? 'CO2e'}`,
                },
              },
            },
          },
        },
        doc303: {
          sections: {
            water: {
              forms: {
                interactions: {
                  subtitle: data => data.withdrawn,
                },
              },
            },
          },
        },
        doc302: {
          sections: {
            fuelConsumption: {
              format: {
                title: data => data.name,
                subtitle: data =>
                  `of which ${data.fuelConsumption?.renewables ?? 0} ${t(
                    data.fuelConsumption?.unit
                  )} renewables`,
              },
            },
          },
        },
        doc405: {
          sections: {
            salaries: {
              format: {
                title: data =>
                  `Salaries in ${t(`options.countries.${data.country}`)}`,
              },
            },
          },
        },
      },
      respondentOverview: {
        sections: Object.fromEntries(
          Object.keys(old.drafts).map(key => [
            key,
            {
              title: get(old, `drafts.${key}.title`),
              subtitle: get(old, `drafts.${key}.subtitle`),
              format: {
                title: ({ label, name }) => label ?? name ?? 'No name',
                subtitle: ({ email }) => email ?? 'No email',
              },
              fields: {
                label: {
                  label: 'Title (shown in overview)',
                },
                name: {
                  label: 'Respondent name',
                },
                title: {
                  label: 'Respondent title',
                },
                email: {
                  label: 'Respondent email',
                },
                tags: {
                  label: 'Tags',
                },
              },
            },
          ])
        ),
      },
      projectOverview: {
        sections: {
          projects: {
            format: {
              id: ({ id }) => id,
              title: ({ name, id }) => name ?? `[${id}]`,
            },
            fields: {
              indicators: {
                options: Object.fromEntries(
                  Object.keys(old.drafts).map(key => {
                    return [key, () => t(`drafts.${key}.title`)]
                  })
                ),
              },
            },
          },
        },
      },
    },
    old
  )
}
export default override
