import _t from 'utils/translation'
import BaseTemplate from '../BaseTemplate.jsx'
import { sections } from '../../Draft/303/meta'
import { convert } from 'utils/currency'

const globalCurrency = 'SEK'
const convertToGlobalCurrency = ({ amount, unit }) =>
  convert(amount, unit, globalCurrency)

const resultSections = {
  employees: {
    id: 'salaries',
    type: 'dataTable',
    title: 'Employee Salaries',
    tableMeta: {
      fields: {
        country: { label: 'Country' },
        basicSalaryMale: { label: 'Male - Basic Salary', format: 'number' },
        basicSalaryFemale: { label: 'Female - Basic Salary', format: 'number' },
        remunerationMale: { label: 'Male - Remuneration', format: 'number' },
        remunerationFemale: {
          label: 'Female - Remuneration',
          format: 'number',
        },
        femaleToMaleRatioBasicSalary: {
          label: 'F/M ratio - Basic Salary',
          format: 'percentage',
          fn: ({ basicSalaryMale, basicSalaryFemale }) =>
            basicSalaryFemale / basicSalaryMale,
        },
        femaleToMaleRatioRemuneration: {
          label: 'F/M ratio - Remuneration',
          format: 'percentage',
          fn: ({ remunerationMale, remunerationFemale }) =>
            remunerationFemale / remunerationMale,
        },
        id: { label: 'Id' },
        draftId: { label: 'Draft' },
        respondentEmail: { label: 'Respondent' },
        type: {
          label: 'Type',
        },
        timestamp: {
          label: 'Timestamp',
        },

        tag: {
          label: 'Tag',
        },
      },
      columns: [
        'country',
        'basicSalaryMale',
        'basicSalaryFemale',
        'femaleToMaleRatioBasicSalary',
        'remunerationMale',
        'remunerationFemale',
        'femaleToMaleRatioRemuneration',
      ],
      rowKeys: ['respondentEmail', 'tag'],
      columnTotals: true,
    },
    transformData: (report, formId) => {
      const absoluteData = {
        basicSalaryMale: convertToGlobalCurrency(
          report.salaries.employees.male.salary
        ),
        basicSalaryFemale: convertToGlobalCurrency(
          report.salaries.employees.female.salary
        ),
        remunerationMale: convertToGlobalCurrency(
          report.salaries.employees.male.remuneration
        ),
        remunerationFemale: convertToGlobalCurrency(
          report.salaries.employees.female.remuneration
        ),
      }
      return {
        country: report.salaries.country,
        ...absoluteData,
      }
    },
  },
  managers: {
    id: 'salaries',
    type: 'dataTable',
    title: 'Management Salaries',
    tableMeta: {
      fields: {
        country: { label: 'Country' },
        basicSalaryMale: { label: 'Male - Basic Salary', format: 'number' },
        basicSalaryFemale: { label: 'Female - Basic Salary', format: 'number' },
        remunerationMale: { label: 'Male - Remuneration', format: 'number' },
        remunerationFemale: {
          label: 'Female - Remuneration',
          format: 'number',
        },
        femaleToMaleRatioBasicSalary: {
          label: 'F/M ratio - Basic Salary',
          format: 'percentage',
          fn: ({ basicSalaryMale, basicSalaryFemale }) =>
            basicSalaryFemale / basicSalaryMale,
        },
        femaleToMaleRatioRemuneration: {
          label: 'F/M ratio - Remuneration',
          format: 'percentage',
          fn: ({ remunerationMale, remunerationFemale }) =>
            remunerationFemale / remunerationMale,
        },
        id: { label: 'Id' },
        draftId: { label: 'Draft' },
        respondentEmail: { label: 'Respondent' },
        type: {
          label: 'Type',
        },
        timestamp: {
          label: 'Timestamp',
        },

        tag: {
          label: 'Tag',
        },
      },
      columns: [
        'country',
        'basicSalaryMale',
        'basicSalaryFemale',
        'femaleToMaleRatioBasicSalary',
        'remunerationMale',
        'remunerationFemale',
        'femaleToMaleRatioRemuneration',
      ],
      rowKeys: ['respondentEmail', 'tag'],
      columnTotals: true,
    },
    transformData: (report, formId) => ({
      country: report.salaries.country,
      basicSalaryMale: convertToGlobalCurrency(
        report.salaries.employees.male.salary
      ),
      basicSalaryFemale: convertToGlobalCurrency(
        report.salaries.employees.female.salary
      ),
      remunerationMale: convertToGlobalCurrency(
        report.salaries.employees.male.remuneration
      ),
      remunerationFemale: convertToGlobalCurrency(
        report.salaries.employees.female.remuneration
      ),
    }),
  },
  diversityGender: {
    id: 'diversity',
    type: 'dataTable',
    title: 'Diversity of Employees - Gender',
    tableMeta: {
      fields: {
        managementMale: { label: 'Mgmt Team: Male', format: 'number' },
        managementFemale: { label: 'Mgmt Team: Female', format: 'number' },
        managementFPercent: {
          label: 'Mgmt Team % female',
          format: 'percentage',
          fn: ({ managementMale, managementFemale }) =>
            managementFemale / (managementFemale + managementMale),
        },

        managersMale: { label: 'Managers: Male', format: 'number' },
        managersFemale: { label: 'Managers: Female', format: 'number' },
        managersFPercent: {
          label: 'Managers % female',
          format: 'percentage',
          fn: ({ managersMale, managersFemale }) =>
            managersFemale / (managersFemale + managersMale),
        },

        employeesMale: { label: 'Employees: Male', format: 'number' },
        employeesFemale: { label: 'Employees: Female', format: 'number' },
        employeesFPercent: {
          label: 'Employees % female',
          format: 'percentage',
          fn: ({ employeesMale, employeesFemale }) =>
            employeesFemale / (employeesFemale + employeesMale),
        },
        id: { label: 'Id' },
        draftId: { label: 'Draft' },
        respondentEmail: { label: 'Respondent' },
        type: {
          label: 'Type',
        },
        timestamp: {
          label: 'Timestamp',
        },

        tag: {
          label: 'Tag',
        },
      },
      columns: [
        // 'managementMale',
        // 'managementFemale',
        'managementFPercent',
        // 'managersMale',
        // 'managersFemale',
        'managersFPercent',
        // 'employeesMale',
        // 'employeesFemale',
        'employeesFPercent',
      ],
      rowKeys: ['respondentEmail', 'tag'],
      columnTotals: true,
    },
    transformData: (report, formId) => ({
      managementMale: report.diversity.employeesByGender.management.male,
      managementFemale: report.diversity.employeesByGender.management.female,
      managersMale: report.diversity.employeesByGender.managers.male,
      managersFemale: report.diversity.employeesByGender.managers.female,
      employeesMale: report.diversity.employeesByGender.employees.male,
      employeesFemale: report.diversity.employeesByGender.employees.female,
    }),
  },
  diversityAge: {
    id: 'diversity',
    type: 'dataTable',
    title: 'Diversity of Employees - Age',
    tableMeta: {
      fields: {
        managementHigh: { format: 'number' },
        managementMedium: { format: 'number' },
        managementLow: { format: 'number' },
        managementTotal: { format: 'number' },
        managementHighP: {
          label: '>50 Mgmt Team',
          format: 'percentage',
          fn: ({ managementHigh, managementTotal }) =>
            managementHigh / managementTotal,
        },
        managementMediumP: {
          label: '30-50 Mgmt Team',
          format: 'percentage',
          fn: ({ managementMedium, managementTotal }) =>
            managementMedium / managementTotal,
        },
        managementLowP: {
          label: '<30 Mgmt Team',
          format: 'percentage',
          fn: ({ managementLow, managementTotal }) =>
            managementLow / managementTotal,
        },

        managersHigh: { format: 'number' },
        managersMedium: { format: 'number' },
        managersLow: { format: 'number' },
        managersTotal: { format: 'number' },
        managersHighP: {
          label: ' >50 Managers',
          format: 'percentage',
          fn: ({ managersHigh, managersTotal }) => managersHigh / managersTotal,
        },
        managersMediumP: {
          label: '30-50 Managers',
          format: 'percentage',
          fn: ({ managersMedium, managersTotal }) =>
            managersMedium / managersTotal,
        },
        managersLowP: {
          label: '<30 Managers',
          format: 'percentage',
          fn: ({ managersLow, managersTotal }) => managersLow / managersTotal,
        },

        employeesHigh: { format: 'number' },
        employeesMedium: { format: 'number' },
        employeesLow: { format: 'number' },
        employeesTotal: { format: 'number' },
        employeesHighP: {
          label: '>50 Employees',
          format: 'percentage',
          fn: ({ employeesHigh, employeesTotal }) =>
            employeesHigh / employeesTotal,
        },
        employeesMediumP: {
          label: '30-50 Employees',
          format: 'percentage',
          fn: ({ employeesMedium, employeesTotal }) =>
            employeesMedium / employeesTotal,
        },
        employeesLowP: {
          label: '<30 Employees',
          format: 'percentage',
          fn: ({ employeesLow, employeesTotal }) =>
            employeesLow / employeesTotal,
        },
        id: { label: 'Id' },
        draftId: { label: 'Draft' },
        respondentEmail: { label: 'Respondent' },
        type: {
          label: 'Type',
        },
        timestamp: {
          label: 'Timestamp',
        },

        tag: {
          label: 'Tag',
        },
      },
      columns: [
        'managementHighP',
        'managementMediumP',
        'managementLowP',

        'managersHighP',
        'managersMediumP',
        'managersLowP',

        'employeesHighP',
        'employeesMediumP',
        'employeesLowP',
      ],
      rowKeys: ['respondentEmail', 'tag'],
      columnTotals: true,
    },
    transformData: (report, formId) => ({
      managementHigh: report.diversity.employeesByAge.management.high,
      managementMedium: report.diversity.employeesByAge.management.medium,
      managementLow: report.diversity.employeesByAge.management.low,
      managementTotal:
        report.diversity.employeesByAge.management.high +
        report.diversity.employeesByAge.management.medium +
        report.diversity.employeesByAge.management.low,

      managersHigh: report.diversity.employeesByAge.managers.high,
      managersMedium: report.diversity.employeesByAge.managers.medium,
      managersLow: report.diversity.employeesByAge.managers.low,
      managersTotal:
        report.diversity.employeesByAge.managers.high +
        report.diversity.employeesByAge.managers.medium +
        report.diversity.employeesByAge.managers.low,

      employeesHigh: report.diversity.employeesByAge.employees.high,
      employeesMedium: report.diversity.employeesByAge.employees.medium,
      employeesLow: report.diversity.employeesByAge.employees.low,
      employeesTotal:
        report.diversity.employeesByAge.employees.high +
        report.diversity.employeesByAge.employees.medium +
        report.diversity.employeesByAge.employees.low,
    }),
  },
}

const Template = ({ projectId, data }) => (
  <BaseTemplate
    meta={sections}
    resultSections={resultSections}
    data={data}
    t={_t(`drafts.doc303.sections`)}
  />
)

export default Template
