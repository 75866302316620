const summarizeListItem = (data, sectionId, meta, projectMeta, t) => {
  const score = meta[sectionId].format.score
  return Object.entries(data[sectionId]?.items ?? []).map(([id, item]) => {
    return {
      id,
      title: t(`sections.${sectionId}.format.title`, item, projectMeta),
      subtitle: t(`sections.${sectionId}.format.subtitle`, item, projectMeta),
      score: typeof score === 'function' ? score(item, projectMeta) : score,
      timestamp: item.timestamp,
    }
  })
}

const summarizeStaticItem = (data, sectionId, meta, projectMeta, t) =>
  Object.entries(meta[sectionId].forms).map(([id, meta]) => ({
    id,
    title: t(`sections.${sectionId}.forms.${id}.title`, data[id], projectMeta),
    subtitle: t(
      `sections.${sectionId}.forms.${id}.subtitle`,
      data[id],
      projectMeta
    ),
    score:
      typeof meta.score === 'function'
        ? meta.score(data[id], projectMeta)
        : meta.score,
  }))

const summarizeItem = (data, sectionId, meta, projectMeta, t) =>
  meta[sectionId].type === 'list'
    ? summarizeListItem(data, sectionId, meta, projectMeta, t)
    : summarizeStaticItem(data, sectionId, meta, projectMeta, t)

export default summarizeItem
