import { createElement } from 'react'
import styled from 'styled-components'
import Trash from './Trash'
import Pencil from './Pencil'
import Plus from './Plus'
import Question from './Question'
import Close from './Close'
import Check from './Check'
import Filter from './Filter'
import ArrowUp from './ArrowUp'
import ArrowDown from './ArrowDown'
import Sheet from './Sheet'
import Login from './Login'
import Logout from './Logout'
import Form from './Form'
import Graph from './Graph'

const icons = {
  trash: Trash,
  pencil: Pencil,
  plus: Plus,
  question: Question,
  close: Close,
  check: Check,
  filter: Filter,
  arrowUp: ArrowUp,
  arrowDown: ArrowDown,
  sheet: Sheet,
  login: Login,
  logout: Logout,
  form: Form,
  graph: Graph,
}

const filter = props =>
  Object.fromEntries(
    Object.entries(props).filter(([key]) => !['isValid'].includes(key))
  )

const Icon = ({ type, ...props }) =>
  createElement(icons[type ?? 'question'], filter(props))

export default styled(Icon)`
  width: 1.5rem;
  height: 1.5rem;
  display: block;
  margin: auto;
`
