import enrichDraft from 'utils/enrichDraft'

import healthAndManagement from './403-1_healthAndManagement'
import hazardRiskIncident from './403-2_hazardRiskIncident'
import occupationalHealthAndSafety from './403-3_occupationalHealthAndSafety'
import workerParticipation from './403-4_workerParticipation'
import workerTraining from './403-5_workerTraining'
import promotionOfWorkerHealth from './403-6_promotionOfWorkerHealth'
import preventionAndMitigation from './403-7_preventionAndMitigation'
import workerCoverage from './403-8_workerCoverage'
import workRelatedInjuries from './403-9_workRelatedInjuries'
import workRelatedIllHealth from './403-10_workRelatedIllHealth'

export const title = '403 Health and Safety'

export const sections = enrichDraft({
  healthAndSafety: {
    title: '403 Health and Safety',
    type: 'static',
    forms: {
      healthAndManagement,
      hazardRiskIncident,
      occupationalHealthAndSafety,
      workerParticipation,
      workerTraining,
      promotionOfWorkerHealth,
      preventionAndMitigation,
      workerCoverage,
      workRelatedInjuries,
      workRelatedIllHealth,
    },
  },
})
