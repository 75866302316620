import styled from 'styled-components'
import { BaseTemplate } from 'components/Template'
import { sections } from './meta'

const StyledBaseTemplate = styled(BaseTemplate)`
  && aside {
    width: 40rem;
    max-width: 100%;

    form fieldset fieldset {
      display: flex;
      align-items: flex-end;
      justify-content: stretch;
      > div {
        flex-grow: 1;
      }
      > div + div {
        margin-top: 0;
        margin-left: 1rem;
      }
    }
  }
`
const Template = props => {
  return <StyledBaseTemplate meta={sections} {...props} />
}
export default Template
