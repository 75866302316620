import React from 'react'
import styled from 'styled-components'
import Card from 'components/Card'
import List from 'components/List'
import { IconButton } from 'components/Button'

const AddButton = styled(IconButton)`
  margin-right: 2rem;
  margin-left: auto;
  display: block;
  width: 3rem;
  height: 3rem;
  padding: 0;
`

const StaticSection = ({
  id,
  title,
  subtitle,
  description,
  data,
  onEdit,
  disabled,
}) => {
  return (
    <Card key={id} title={title} subtitle={subtitle} description={description}>
      <List data={data} onEdit={onEdit} />
    </Card>
  )
}

const ListSection = ({
  id,
  title,
  subtitle,
  description,
  data,
  onEdit,
  onDelete,
  disabled,
}) => {
  return (
    <Card key={id} title={title} subtitle={subtitle} description={description}>
      <List
        data={data.sort((a, b) => a.timestamp - b.timestamp)}
        onEdit={onEdit}
        onDelete={disabled ? undefined : onDelete}
      />
      {onEdit && !disabled && (
        <AddButton icon="plus" onClick={() => onEdit()} />
      )}
    </Card>
  )
}

const UnknownSection = props => (
  <Card key={props.type} title={`Unknown section type ${props.type}`} />
)

const sections = {
  list: ListSection,
  static: StaticSection,
}

const Section = props => {
  return React.createElement(sections[props.type] ?? UnknownSection, props)
}

export default Section
