import calculateProgress from '../../../../utils/calculateProgress'

const meta = {
  score: calculateProgress,

  fields: {
    applicable: {
      type: 'options',
      options: [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
      ],
    },
    description: {
      type: 'textArea',
      visible: data => data.applicable,
    },
    relatedRisks: {
      type: 'textArea',
      visible: data => data.applicable,
    },
  },

  initialValues: {
    applicable: null,
    description: '',
    relatedRisks: '',
  },
}

export default meta
