import React from 'react'
import styled from 'styled-components'

const Logo = ({ className, tagline }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 115 79.977"
    className={className}
  >
    <g strokeWidth=".28097464000000005">
      <path d="M90.537 3.912c12.26 26.191 17.414 32.996 22.812 43.153-8.412-2.687-18.404-17.53-25.926-18.424-6.578-.782-18.658 20.906-35.75 28.38C67.728 41.475 72.9 20.71 84.706 3.691c3.183-5.094 3.657-4.427 5.831.22z" />
      <path d="M70.322 34.795c-1.886 1.902-7.499 13.613-8.093 11.778-1.168-3.606-6.19-9.391-8.303-9.473-7.74-.301-10.408 8.354-22.422 20.565C18.977 70.396 8.247 76.4.084 79.848c18.128-13.391 34.715-35.165 51.66-61.878 4.725-7.492 5.148-3.605 9.515 2.478 5.768 8.035 6.96 11.276 9.063 14.347z" />
    </g>
  </svg>
)

export default styled(Logo)`
  display: block;
  stroke: currentColor;
  fill: currentColor;
`
