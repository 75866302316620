import Wrapper from './Wrapper'
import Main from './Main'
import { Container } from 'components/Layout'
import Card from 'components/Card'

const UnknownTemplate = () => (
  <Wrapper>
    <Main>
      <Container>
        <Card title="Template Not Found"></Card>
      </Container>
    </Main>
  </Wrapper>
)

export default UnknownTemplate
