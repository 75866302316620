import _t from 'utils/translation'
import BaseTemplate from '../BaseTemplate.jsx'
import { sections } from '../../Draft/401/meta'

const resultSections = {
  employeeHires: {
    id: 'employeeHiresAndTurnover',
    title: 'New Employee Hires',
    forms: {
      byGender: {
        type: 'dataTable',
        title: 'New Hires By Gender',
        tableMeta: {
          fields: {
            country: { label: 'Country' },
            newHiresMale: { label: 'Male', format: 'number' },
            newHiresFemale: { label: 'Female', format: 'number' },
            newHiresMalePercent: {
              label: '% Male',
              format: 'percentage',
              fn: ({ newHiresMale, newHiresFemale }) =>
                newHiresMale / (newHiresMale + newHiresFemale),
            },
            newHiresFemalePercent: {
              label: '% Female',
              format: 'percentage',
              fn: ({ newHiresMale, newHiresFemale }) =>
                newHiresFemale / (newHiresMale + newHiresFemale),
            },
            total: { label: 'total', format: 'number' },
            respondentEmail: { label: 'Respondent' },
            tag: { label: 'Tag' },
          },
          columns: [
            'country',
            'newHiresMale',
            'newHiresFemale',
            'newHiresMalePercent',
            'newHiresFemalePercent',
            'total',
          ],
          rowKeys: ['respondentEmail', 'tag'],
          columnTotals: true,
        },
        transformData: report => ({
          newHiresMale: report.employeeHiresAndTurnover.hires.byGender.male,
          newHiresFemale: report.employeeHiresAndTurnover.hires.byGender.female,
          country: report.employeeHiresAndTurnover.country,
        }),
      },
      byAge: {
        type: 'dataTable',
        title: 'New Hires By Age',
        tableMeta: {
          fields: {
            country: { label: 'Country' },
            newHiresOld: { label: '>50', format: 'number' },
            newHiresMedium: { label: '30-50', format: 'number' },
            newHiresYoung: { label: '<30', format: 'number' },
            total: { label: 'total', format: 'number' },
            respondentEmail: { label: 'Respondent' },
            tag: { label: 'Tag' },
          },
          columns: [
            'country',
            'newHiresYoung',
            'newHiresMedium',
            'newHiresOld',
            'total',
          ],
          rowKeys: ['respondentEmail', 'tag'],
          columnTotals: true,
        },
        transformData: report => ({
          newHiresYoung: report.employeeHiresAndTurnover.hires.byAge.low,
          newHiresMedium: report.employeeHiresAndTurnover.hires.byAge.medium,
          newHiresOld: report.employeeHiresAndTurnover.hires.byAge.high,
          country: report.employeeHiresAndTurnover.country,
        }),
      },
    },
  },
  employeeTurnover: {
    id: 'employeeHiresAndTurnover',
    title: 'Employee Turnover',
    forms: {
      byGender: {
        type: 'dataTable',
        title: 'Employee Turnover by Gender',
        tableMeta: {
          fields: {
            country: { label: 'Country' },
            turnoverMale: { label: 'Male', format: 'number' },
            turnoverFemale: { label: 'Female', format: 'number' },
            turnoverMalePercent: {
              label: '% Male',
              format: 'percentage',
              fn: ({ turnoverMale, turnoverFemale }) =>
                turnoverMale / (turnoverMale + turnoverFemale),
            },
            turnoverFemalePercent: {
              label: '% Female',
              format: 'percentage',
              fn: ({ turnoverMale, turnoverFemale }) =>
                turnoverFemale / (turnoverMale + turnoverFemale),
            },
            total: { label: 'total', format: 'number' },
            respondentEmail: { label: 'Respondent' },
            tag: { label: 'Tag' },
          },
          columns: [
            'country',
            'turnoverMale',
            'turnoverFemale',
            'turnoverMalePercent',
            'turnoverFemalePercent',
            'total',
          ],
          rowKeys: ['respondentEmail', 'tag'],
          columnTotals: true,
        },
        transformData: report => ({
          turnoverMale: report.employeeHiresAndTurnover.turnover.byGender.male,
          turnoverFemale:
            report.employeeHiresAndTurnover.turnover.byGender.female,
          country: report.employeeHiresAndTurnover.country,
        }),
      },
      byAge: {
        type: 'dataTable',
        title: 'Turnover by Age',
        tableMeta: {
          fields: {
            country: { label: 'Country' },
            turnoverOld: { label: '>50', format: 'number' },
            turnoverMedium: { label: '30-50', format: 'number' },
            turnoverYoung: { label: '<30', format: 'number' },
            total: { label: 'total', format: 'number' },
            respondentEmail: { label: 'Respondent' },
            tag: { label: 'Tag' },
          },
          columns: [
            'country',
            'turnoverYoung',
            'turnoverMedium',
            'turnoverOld',
            'total',
          ],
          rowKeys: ['respondentEmail', 'tag'],
          columnTotals: true,
        },
        transformData: report => ({
          turnoverYoung: report.employeeHiresAndTurnover.turnover.byAge.low,
          turnoverMedium: report.employeeHiresAndTurnover.turnover.byAge.medium,
          turnoverOld: report.employeeHiresAndTurnover.turnover.byAge.high,
          country: report.employeeHiresAndTurnover.country,
        }),
      },
    },
  },
}

const Template = ({ projectId, data }) => (
  <BaseTemplate
    t={_t(`drafts.doc401.sections`)}
    meta={sections}
    resultSections={resultSections}
    data={data}
  />
)

export default Template
