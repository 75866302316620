import calculateProgress from '../../../../utils/calculateProgress'

const meta = {
  score: calculateProgress,

  fields: {
    illHealth: {
      type: 'section',
      fields: {
        applicable: {
          type: 'options',
          options: [true, false],
        },
        relatedTo: {
          type: 'options',
          visible: data => data.illHealth.applicable,
          options: ['employees', 'workers'],
        },
        numberOfEmployees: {
          type: 'section',
          visible: data => data.illHealth.relatedTo.includes('employees'),
          fields: {
            fatalities: {
              type: 'int',
            },
            total: {
              type: 'int',
            },
            description: {
              type: 'textArea',
            },
          },
        },
        numberOfWorkers: {
          type: 'section',

          visible: data => data.illHealth.relatedTo.includes('workers'),
          fields: {
            fatalities: {
              type: 'int',
            },
            total: {
              type: 'int',
            },
            description: {
              type: 'textArea',
            },
          },
        },
      },
    },
    hoursWorked: {
      type: 'section',
      fields: {
        employees: {
          type: 'int',
        },
        workers: {
          type: 'int',
        },
      },
    },
    hazards: {
      type: 'section',

      fields: {
        applicable: {
          type: 'options',
          options: [true, false],
        },
        description: {
          type: 'textArea',
          visible: data => data.hazards.applicable,
        },
        process: {
          type: 'textArea',
          visible: data => data.hazards.applicable,
        },
        contribution: {
          type: 'textArea',
          visible: data => data.hazards.applicable,
        },
        mitigation: {
          type: 'textArea',
          visible: data => data.hazards.applicable,
        },
      },
    },
    workersExcluded: {
      type: 'options',
      options: [true, false],
    },
    workersExcludedDescription: {
      type: 'textArea',
      visible: ({ workersExcluded }) => workersExcluded,
    },
    dataSource: {
      type: 'textArea',
    },
  },

  initialValues: {
    illHealth: {
      applicable: null,
      relatedTo: [],
      numberOfEmployees: {
        fatalities: '',
        total: '',
        description: '',
      },
      numberOfWorkers: {
        fatalities: '',
        total: '',
        description: '',
      },
    },
    hoursWorked: {
      employees: '',
      workers: '',
    },
    hazards: {
      applicable: null,
      description: '',
      process: '',
      contribution: '',
      mitigation: '',
    },
    workersExcluded: null,
    workersExcludedDescription: '',
    dataSource: '',
  },
}

export default meta
