const getListAsideItem = (aside, data, meta, projectMeta, t) => ({
  title: t(`sections.${aside.sectionId}.title`, data, projectMeta),
  subtitle: t(`sections.${aside.sectionId}.subtitle`, data, projectMeta),
  description: t(`sections.${aside.sectionId}.description`, data, projectMeta),
  initialValues: data[aside.sectionId]?.items[aside.id],
  meta: meta[aside.sectionId],
  projectMeta,
  t: t(`sections.${aside.sectionId}`, data, projectMeta),
})

const getStaticAsideItem = (aside, data, meta, projectMeta, t) => ({
  title: t(
    `sections.${aside.sectionId}.forms.${aside.id}.title`,
    data,
    projectMeta
  ),
  subtitle: t(
    `sections.${aside.sectionId}.forms.${aside.id}.subtitle`,
    data,
    projectMeta
  ),
  description: t(
    `sections.${aside.sectionId}.forms.${aside.id}.description`,
    data,
    projectMeta
  ),
  initialValues: data[aside.id],
  meta: meta[aside.sectionId].forms[aside.id],
  projectMeta,
  t: t(`sections.${aside.sectionId}.forms.${aside.id}`, data, projectMeta),
})

const getAsideItem = (aside, data, meta, projectMeta, t) =>
  meta[aside.sectionId].type === 'list'
    ? getListAsideItem(aside, data, meta, projectMeta, t)
    : getStaticAsideItem(aside, data, meta, projectMeta, t)

export default getAsideItem
