import _t from 'utils/translation'
import BaseTemplate from '../BaseTemplate.jsx'
import { sections } from '../../Draft/418/meta'

const resultSections = {
  quantitative: {
    id: 'customerPrivacy',
    type: 'dataTable',
    tableMeta: {
      fields: {
        numberOfComplaintsOutsideParties: {
          label: '# outside party complaints',
          format: 'number',
        },
        numberOfComplaintsRegulatoryBodies: {
          label: '# regulatory body complaints',
          format: 'number',
        },
        numberOfLeaks: { label: '# leaks', format: 'number' },
        respondentEmail: { label: 'Respondent' },
        tag: { label: 'Tag' },
      },
      columns: [
        'numberOfComplaintsOutsideParties',
        'numberOfComplaintsRegulatoryBodies',
        'numberOfLeaks',
      ],
      rowKeys: ['respondentEmail', 'tag'],
      columnTotals: true,
    },
    transformData: report => ({
      numberOfComplaintsOutsideParties:
        report.customerPrivacy.numberOfComplaints.fromOutsideParties,
      numberOfComplaintsRegulatoryBodies:
        report.customerPrivacy.numberOfComplaints.fromRegulatoryBodies,
      numberOfLeaks: report.customerPrivacy.numberOfLeaks,
    }),
  },

  qualitative: {
    id: 'customerPrivacy',
    respondentSelect: true,
    forms: {
      customerPrivacy: {
        type: 'qualitative',
        fields: {
          numberOfComplaints: { visible: false },
          numberOfLeaks: { visible: false },
        },
      },
    },
  },
}

const Template = ({ projectId, data }) => (
  <BaseTemplate
    t={_t(`drafts.doc418.sections`)}
    meta={sections}
    resultSections={resultSections}
    data={data}
  />
)

export default Template
