import { useState } from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import Status from './Status'
import Button from 'components/Button'
import TextArea from 'components/form/TextArea'

const SubmissionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 2rem;
`
const DraftData = styled.div``

const Row = styled.div`
  display: flex;
  margin-bottom: 1rem;
  font-size: 1.2rem;
`

const Label = styled.div`
  width: 9rem;
  flex-shrink: 0;
  white-space: nowrap;
`

const Value = styled.div``

const ButtonRow = styled.div`
  display: flex;
  margin-top: 0;
  margin-bottom: 1rem;
  justify-content: flex-end;
`

const Warning = styled.div`
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: bold;
  color: ${theme('colors.medium')};
`

const AcceptButton = styled(Button)`
  background-color: ${theme('colors.success')};
`

const RejectButton = styled(Button)`
  background-color: ${theme('colors.warning')};
`

const HalfWrapper = styled.div`
  width: 50%;
  ${Button} {
    font-size: 1rem;
    margin-top: 0;
    margin-left: 1rem;
    margin-right: 0;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const StyledTextArea = styled(TextArea)`
  height: 4rem;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
`

const ReviewBox = ({ submit, user }) => {
  const [message, setMessage] = useState('')

  return (
    <HalfWrapper>
      <StyledTextArea
        value={message}
        onChange={e => setMessage(e.target.value)}
        placeholder="Review comments"
        height={50}
      />
      <ButtonWrapper>
        <AcceptButton
          onClick={submit({ approved: true, message })}
          disabled={!user}
        >
          Accept
        </AcceptButton>
        <RejectButton
          onClick={submit({ approved: false, message })}
          disabled={!user}
        >
          Reject
        </RejectButton>
      </ButtonWrapper>
    </HalfWrapper>
  )
}

const canSubmit = (status, user) =>
  (!user && ['started', 'changeRequired'].includes(status)) ||
  (user && ['readyForReview', 'reviewing'].includes(status))

const SubmissionControl = ({
  respondent,
  status,
  reviewer,
  reviewerNote,
  user,
  submit,
}) => (
  <SubmissionWrapper>
    <DraftData>
      <Row>
        <Label>Status: </Label>
        <Value>
          <Status status={status} />
        </Value>
      </Row>
      {respondent && (
        <>
          <Row>
            <Label>Respondent: </Label>
            <Value>
              <a href={`mailto:${respondent.email}`}>{respondent.email}</a>
            </Value>
          </Row>
          <Row>
            <Label>Tags: </Label>
            <Value>{respondent.tags.join(', ')}</Value>
          </Row>
        </>
      )}
    </DraftData>
    {!user && status === 'started' && (
      <HalfWrapper>
        <ButtonRow>
          <Button onClick={submit()} disabled={!canSubmit(status, user)}>
            Submit for review
          </Button>
        </ButtonRow>
      </HalfWrapper>
    )}
    {!user && status === 'changeRequired' && (
      <HalfWrapper>
        <Row>
          <Label>Reviewer Note:</Label>
          <Value>{reviewerNote}</Value>
        </Row>
        <Row>
          <Label>Reviewer:</Label>
          <Value>
            <a href={`mailto:${reviewer.email}`}>{reviewer.email}</a>
          </Value>
        </Row>
        <ButtonRow>
          <Button onClick={submit()} disabled={!canSubmit(status, user)}>
            Submit for review
          </Button>
        </ButtonRow>
      </HalfWrapper>
    )}
    {user && [undefined, 'new', 'started', 'changeRequired'].includes(status) && (
      <HalfWrapper>
        <Warning>
          You are logged in as reviewer, but this draft is not in review.
          Editing is disabled.
        </Warning>
      </HalfWrapper>
    )}
    {user && ['readyForReview', 'reviewing'].includes(status) && (
      <ReviewBox submit={submit} user={user} />
    )}
    {!user && ['readyForReview', 'reviewing'].includes(status) && (
      <HalfWrapper>
        <Warning>
          This draft is in review, but you are not logged in as reviewer.
          Editing is disabled.
        </Warning>
      </HalfWrapper>
    )}
  </SubmissionWrapper>
)

export default SubmissionControl
