import useAuth from 'providers/useAuth'
import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = props => {
  const { user, isLoading } = useAuth()
  if (isLoading) {
    return <div></div>
  } else if (!user) {
    return (
      <Redirect
        to={{
          pathname: `/login`,
          state: { redirect: props.location.pathname },
        }}
      />
    )
  } else {
    return <Route {...props} />
  }
}

export default PrivateRoute
