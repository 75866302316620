import styled from 'styled-components'
import { useState } from 'react'
import { ifProp, withProp, theme } from 'styled-tools'
import { lighten } from 'polished'

import { IconButton } from 'components/Button'
import Filter from './Filter'
import { formatNumber } from 'utils/reportingTools'

import { getTableData, exportExcel } from './data.js'

const Wrapper = styled.div`
  margin: 1rem 0;
`

const Table = styled.table`
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;

  tbody td,
  tbody th {
    border-top: 1px solid ${theme('colors.border')};
    font-weight: normal;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  tfoot td,
  tfoot th {
    font-weight: bold;
    border-top: 2px solid ${theme('colors.primary')};
  }
`

const RowTh = styled.th`
  background-color: ${theme('colors.light')};
`

const Td = styled.td`
  padding: 0.3rem 0.5rem;
  border-left-color: ${theme('colors.border')};
  border-left-style: solid;
  border-left-width: ${ifProp('total', `1px`, '0px')};
`

const NumberTd = styled(Td)`
  text-align: right;
  white-space: nowrap;
`

const ExportButton = styled(IconButton).attrs(() => ({
  inverted: true,
  icon: 'sheet',
}))`
  margin: 0.5rem 0rem 0rem auto;
  height: 2.5rem;
  width: 3rem;
  border-bottom: none;
  background: ${withProp('theme.colors.primary', lighten(0.6))};
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`

const NumberCell = ({ value, total }) => (
  <NumberTd total={total}>{formatNumber(value)}</NumberTd>
)

const RowTd = ({ value, format, total }) =>
  format === 'number' ? (
    <NumberCell value={value} total={total} />
  ) : format === 'percentage' ? (
    <NumberTd total={total}>
      {isFinite(value) ? `${(value * 100).toFixed(1)}%` : ''}
    </NumberTd>
  ) : (
    <Td total={total}>{value}</Td>
  )

const Row = ({ data, rowKey }) => (
  <tr>
    <RowTh scope="row">{data[0].value}</RowTh>
    {data.slice(1).map(({ key, value, format }) => (
      <RowTd key={key} value={value} format={format} total={key === 'total'} />
    ))}
  </tr>
)

const ColumnTh = styled.th`
  background-color: ${theme('colors.primary')};
  color: white;
  border-bottom: 1px solid ${theme('colors.dark')};
  padding: 0.3rem 0.5rem;
  > div {
    display: flex;
    justify-content: space-between;
  }
  label {
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
    padding: 0.1rem 0;
    height: auto;
  }
`

const ColumnHeader = ({
  label,
  columnValues,
  currentSortOrder,
  toggleSortOrder,
  currentFilter,
  changeFilter,
  isFilterDialogOpen,
  setFilterDialogOpen,
}) => (
  <ColumnTh scope="col" title={label}>
    <div>
      <label>{label}</label>
      <Filter
        columnValues={columnValues}
        currentSortOrder={currentSortOrder}
        toggleSortOrder={toggleSortOrder}
        currentFilter={currentFilter}
        changeFilter={changeFilter}
        isFilterDialogOpen={isFilterDialogOpen}
        setFilterDialogOpen={setFilterDialogOpen}
      />
    </div>
  </ColumnTh>
)

const calculateColumnTotals = (rows, meta) => {
  const totals = rows.reduce(
    (acc, cur) =>
      Object.fromEntries(
        cur.map(({ key, value }) => [
          key,
          isFinite(value)
            ? isFinite(acc[key])
              ? value + acc[key]
              : value
            : undefined,
        ])
      ),
    {}
  )
  return meta.columns.map((column, i) => ({
    column,
    format: meta.fields[column].format,
    value: meta.fields[column].fn
      ? meta.fields[column].fn(totals)
      : totals[column],
  }))
}

const DataTable = ({ meta, data, title }) => {
  const [filter, setFilter] = useState({})
  const [sort, setSort] = useState([])
  const [filterDialog, setFilterDialog] = useState()

  const { rows, uniqueValues } = getTableData(data, meta, filter, sort)

  const changeFilter = (column, values) => (label, value) =>
    setFilter({
      ...filter,
      [column]:
        label === 'all'
          ? Object.fromEntries(values.map(v => [v, !value]))
          : { ...filter[column], [label]: !value },
    })

  return (
    <Wrapper>
      <ExportButton
        title="Export as Excel document"
        onClick={() => exportExcel(meta, title)(rows)}
      >
        Export excel
      </ExportButton>
      <Table>
        <thead>
          <tr>
            {[meta.rowKey, ...meta.columns].map(c => (
              <ColumnHeader
                key={c}
                label={meta.fields[c]?.label ?? '??'}
                columnValues={uniqueValues[c]}
                currentSortOrder={sort.find(s => s.column === c)?.order}
                toggleSortOrder={() =>
                  setSort([
                    {
                      column: c,
                      order:
                        sort.find(s => s.column === c)?.order === 'asc'
                          ? 'desc'
                          : 'asc',
                    },
                    ...sort.filter(s => s.column !== c),
                  ])
                }
                currentFilter={filter[c] ?? {}}
                changeFilter={
                  meta.fields[c].format !== 'number'
                    ? changeFilter(c, uniqueValues[c])
                    : undefined
                }
                isFilterDialogOpen={filterDialog === c}
                setFilterDialogOpen={open => setFilterDialog(open ? c : null)}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, i) => (
            <Row key={i} data={row} />
          ))}
        </tbody>
        {meta.columnTotals && (
          <tfoot>
            <tr>
              <RowTh scope="row">Total</RowTh>
              {calculateColumnTotals(rows, meta).map(
                ({ column, format, value }) => (
                  <RowTd
                    key={column}
                    format={format}
                    value={value}
                    total={column === 'total'}
                  />
                )
              )}
            </tr>
          </tfoot>
        )}
      </Table>
    </Wrapper>
  )
}

export default DataTable
