import calculateProgress from '../../../../utils/calculateProgress'

const meta = {
  type: 'static',
  score: calculateProgress,

  fields: {
    applicable: {
      type: 'options',
      options: [true, false],
    },
    reasons: {
      type: 'section',
      visible: data => data.applicable,
      fields: {
        reasons: {
          type: 'options',
          options: ['legal', 'risk', 'guidelines', 'other'],
        },
        legalDescription: {
          type: 'text',
          visible: data => data.reasons.reasons.includes('legal'),
        },
        riskDescription: {
          type: 'text',
          visible: data => data.reasons.reasons.includes('risk'),
        },
        guidelinesDescription: {
          type: 'text',
          visible: data => data.reasons.reasons.includes('guidelines'),
        },
        otherDescription: {
          type: 'text',
          visible: data => data.reasons.reasons.includes('other'),
        },
      },
    },
    notCovered: {
      type: 'options',
      options: [true, false],
    },
    reasonNotCovered: {
      type: 'textArea',
      visible: data => data.notCovered,
    },
  },

  initialValues: {
    applicable: null,
    implemented: null,
    reasons: {
      reasons: [],
      legalDescription: '',
      riskDescription: '',
      guidelinesDescription: '',
      otherDescription: '',
    },
    notCovered: null,
    reasonNotCovered: '',
  },
}

export default meta
