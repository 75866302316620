import { useState } from 'react'
import { Container } from 'components/Layout'
import Card from 'components/Card'
import { Wrapper, Main } from 'components/Template'
import FormResults, { RespondentSelectSection } from './FormResults'

const renderFormResult =
  ({ meta, data, respondent, hasForms, t }) =>
  ([formId, form]) =>
    (
      <FormResults
        key={formId}
        id={formId}
        type={form.type}
        form={form}
        meta={meta?.forms?.[formId] ?? {}}
        data={data}
        respondent={respondent}
        t={hasForms ? t?.(`forms.${formId}`) : t}
      />
    )

const ResultSection = ({ section, data, meta, t }) => {
  const [respondent, setRespondent] = useState()
  const allRespondents = data.map(d => d._respondent.email)
  return (
    <Card title={section.title ?? t?.(`title`) ?? t?.(`label`)}>
      {section.respondentSelect && (
        <RespondentSelectSection
          options={allRespondents}
          value={respondent}
          onSelect={setRespondent}
        />
      )}
      {section.forms
        ? Object.entries(section.forms).map(
            renderFormResult({
              meta,
              data,
              respondent: respondent ?? allRespondents[0],
              hasForms: true,
              t,
            })
          )
        : renderFormResult({
            meta,
            data,
            respondent: respondent ?? allRespondents[0],
            hasForms: false,
            t,
          })([section.id, section])}
    </Card>
  )
}

const BaseTemplate = ({ meta, resultSections, t, data }) => {
  return (
    <Wrapper>
      <Main>
        <Container fullHeight={true}>
          {Object.entries(resultSections).map(([sectionId, section]) => (
            <ResultSection
              key={sectionId}
              section={section}
              data={data}
              meta={meta[section.id ?? sectionId]}
              t={t(`${section.translationKey ?? section.id ?? sectionId}`)}
            />
          ))}
        </Container>
      </Main>
    </Wrapper>
  )
}

export default BaseTemplate
