const meta = {
  fields: {
    ownership: {
      type: 'section',
      fields: {
        description: {
          type: 'textArea',
        },
        entities: {
          type: 'textArea',
        },
      },
    },
    governance: {
      label: 'Governance',
      type: 'section',
      fields: {
        description: {
          type: 'textArea',
        },
      },
    },
  },
  initialValues: {
    ownership: {
      description: '',
      entities: '',
    },
    governenance: {
      description: '',
    },
  },
}

export default meta
