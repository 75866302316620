import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { validateByMeta, required } from 'utils/validation'
import Icon from 'components/Icon'
import Status from 'components/Template/Status'

const StatusWrapper = styled.div`
  display: flex;
`

const createRawSection = () => ({
  type: 'list',
  visible: ({ _type }, { indicators }) => {
    return indicators === undefined || indicators.includes(_type)
  },
  format: {
    score: ({ id, _status, ...data }, { id: projectId }) => ({
      type: 'custom',
      render: props => (
        <StatusWrapper>
          <Status status={_status} />
          <Link to={`/${projectId}/drafts/${id}`}>
            <Icon type="form" />
          </Link>
        </StatusWrapper>
      ),
    }),
  },
  initialValues: {
    label: '',
    name: '',
    title: '',
    email: '',
    tags: [],
  },
  fields: {
    label: {
      type: 'string',
    },
    name: {
      type: 'string',
    },
    title: {
      type: 'string',
    },
    email: {
      type: 'email',
      validate: required,
    },
    tags: {
      type: 'select',
      isCreatable: true,
      options: (values, projectMeta) =>
        projectMeta?.respondentTags
          ? Object.entries(projectMeta.respondentTags).map(
              ([value, label]) => ({
                value,
                label,
              })
            )
          : [],
    },
  },
})

const createValidatedSection = section =>
  Object.assign({ validate: validateByMeta(section.fields) }, section)

const createSection = meta => createValidatedSection(createRawSection(meta))

export const id = 'overview'
export const sections = {
  generalDisclosures: createSection(),
  doc205: createSection(),
  doc301: createSection(),
  doc302: createSection(),
  doc303: createSection(),
  doc305: createSection(),
  doc306: createSection(),
  doc308: createSection(),
  doc401: createSection(),
  doc403: createSection(),
  doc404: createSection(),
  doc405: createSection(),
  doc416: createSection(),
  doc418: createSection(),
}
