export const type = 'list'

export const initialValues = {
  method: null,
  activity: null,
  mode: null,
  type: null,
  amount: {
    amount: '',
    unit: null,
  },
  dataSource: null,
}

// So, in the excel sheets, there are some weights for miles, but the amount
// is always converted to km, and the unit "translated" to km before the calculation/
// Presumably because some weights are missing for miles. Replicating some of the
// mess here for traceability and keeping the miles-weights, even though they're not used.

// Weights in kg
const weights = {
  domestic: { average: { km: 0.25493 } },
  shortHaul: {
    average: { km: 0.15832 },
    economy: { km: 0.15573 },
    business: { km: 0.2336 },
  },
  longHaul: {
    average: { km: 0.19562 },
    economy: { km: 0.14981 },
    premium: { km: 0.2397 },
    business: { km: 0.43446 },
    first: { km: 0.59925 },
  },
  international: {
    average: { km: 0.18078 },
    economy: { km: 0.1384453 },
    premium: { km: 0.22151 },
    business: { km: 0.40149 },
    first: { km: 0.55376 },
  },
  taxi: {
    regular: { km: 0.15018, miles: 0.21024 },
    black: { km: 0.21176, miles: 0.31764 },
  },
  bus: {
    localNotLondon: { km: 0.12076 },
    localLondon: { km: 0.08208 },
    localAverage: { km: 0.10471 },
    coach: { km: 0.02779 },
  },
  rail: {
    national: { km: 0.04115 },
    international: { km: 0.00597 },
    light: { km: 0.03508 },
    underground: { km: 0.03084 },
  },
  rentedCar: {
    small: { km: 0.14208, miles: 0.22868 },
    medium: { km: 0.17061, miles: 0.27459 },
    large: { km: 0.20947, miles: 0.33713 },
    average: { km: 0.17336, miles: 0.27901 },
  },
  ferry: {
    foot: { km: 0.018738 },
    car: { km: 0.129518 },
    average: { km: 0.112863 },
  },
}

const milesToKm = 1.609347219
const amountInKm = amount =>
  amount.unit === 'km' ? amount.amount : amount.amount * milesToKm

// Returns CO2e in tonnes
export const calculateScore = ({ method, mode, type, amount }) => {
  if (['estimated', 'provided'].includes(method)) {
    return amount.amount
  } else if (weights[mode][type]['km'] != null) {
    return (weights[mode][type]['km'] * amountInKm(amount)) / 1000
  } else {
    console.warn(`No weight found for mode ${mode} and type ${type}`)
    return 0
  }
}

export const format = {
  score: data => ({
    type: 'quantity',
    unit: 'tonnes CO2e',
    value: calculateScore(data),
  }),
}

const modes = {
  air: ['domestic', 'shortHaul', 'longHaul', 'international'],
  land: ['bus', 'rail', 'rentedCar', 'taxi'],
  sea: ['ferry'],
}

const amountUnits = {
  calculated: ['km', 'miles'],
  estimated: ['tonnes'],
  provided: ['tonnes'],
}

const types = {
  air: {
    domestic: ['average'],
    shortHaul: ['average', 'economy', 'business'],
    longHaul: ['average', 'economy', 'premium', 'business', 'first'],
    international: ['average', 'economy', 'premium', 'business', 'first'],
  },
  land: {
    bus: ['localNotLondon', 'localLondon', 'localAverage', 'coach'],
    rail: ['national', 'international', 'light', 'underground'],
    rentedCar: ['small', 'medium', 'large', 'average'],
    taxi: ['regular', 'black'],
  },
  sea: {
    ferry: ['foot', 'car', 'average'],
  },
}

export const fields = {
  activity: {
    type: 'options',
    options: ['air', 'land', 'sea'],
    visible: () => true,
  },
  method: {
    type: 'options',
    options: ['calculated', 'provided', 'estimated'],
    visible: ({ activity }) => activity != null,
  },
  mode: {
    description: ({ activity }) =>
      activity === 'air'
        ? `Domestic flight: Travel within the country
Short haul: Travel within Europe
Long haul: Travel to/from Europe from/to destinations outside of Europe
International flight: Travel between two non-european destinations
`.trim()
        : undefined,
    type: 'options',
    options: ({ activity }) => modes[activity],
    visible: ({ activity, method }) => method != null && activity != null,
  },
  type: {
    type: 'options',
    options: ({ activity, mode }) => types[activity]?.[mode] ?? [],
    visible: ({ activity, mode }) => activity != null && mode != null,
  },
  amount: {
    type: 'amount',
    options: ({ method }) => amountUnits[method],
    optionsPrefix: 'units',
    visible: ({ activity, mode, type, method }) =>
      ['estimated', 'provided'].includes(method) ||
      (activity != null && mode != null && type != null),
  },
  dataSource: {
    type: 'options',
    options: ['supplier', 'measured', 'estimated'],
    visible: ({ method, amount }) => method != null && amount.amount !== 0,
  },
}
