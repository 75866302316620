import countries from '../../countries'

const meta = {
  fields: {
    locations: {
      type: 'select',
      options: countries,
      optionsPrefix: 'countries',
    },
    countries: {
      type: 'select',
      options: countries,
      optionsPrefix: 'countries',
    },
    sectors: {
      type: 'textArea',
    },
    description: {
      type: 'textArea',
    },
    bannedItems: {
      type: 'section',
      fields: {
        applicable: {
          type: 'options',
          options: [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ],
        },
        description: {
          type: 'textArea',
          visible: data => data.bannedItems.applicable,
        },
      },
    },
    primary: {
      type: 'textArea',
    },
  },
  initialValues: {
    locations: [],
    countries: [],
    sectors: '',
    description: '',
    bannedItems: {
      applicable: null,
    },
    primary: '',
  },
}

export default meta
