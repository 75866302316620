import styled from 'styled-components'
import Card from 'components/Card'
import List from 'components/List'
import t from 'utils/translation'

import { BaseTemplate } from 'components/Template'
import { sections, createSummary } from './meta'

const StyledBaseTemplate = styled(BaseTemplate)``

const Template = props => {
  const summary = createSummary(props.data).map(item => ({
    t: t('drafts.doc305.sections.summary.format', item),
    ...item,
  }))
  return (
    <StyledBaseTemplate meta={sections} {...props}>
      <Card title="0. Summary">
        <List data={summary} />
      </Card>
    </StyledBaseTemplate>
  )
}

export default Template
