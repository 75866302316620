import { Container } from 'components/Layout'
import { Wrapper, Main, Section } from 'components/Template'
import _t from 'utils/translation'

const Template = ({ projectId, data: reports }) => {
  const map = {}
  reports.forEach(report => {
    const type = isFinite(report._type) ? `doc${report._type}` : report._type
    map[type] ??= { drafts: {}, type }
    map[type].drafts[report.draftId] ??= {}
    map[type].drafts[report.draftId][report.id] = report.id
  })

  const data = Object.entries(map)
    .map(([type, data]) => ({
      id: type,
      title: _t(`drafts.${type}.title`),
      subtitle: `Based on ${Object.keys(data.drafts).length} respondents`,
      score: {
        type: 'url',
        value: `${window.location.origin}/${projectId}/results/${type}`,
        label: type,
      },
    }))
    .sort((a, b) =>
      a.id === 'generalDisclosures'
        ? -1
        : b.id === 'generalDisclosures'
        ? 1
        : a.id.localeCompare(b.id)
    )

  return (
    <Wrapper>
      <Main>
        <Container fullHeight={true}>
          <Section
            title={`Results by sheet type from ${projectId}`}
            type="list"
            data={data}
          />
        </Container>
      </Main>
    </Wrapper>
  )
}

export default Template
