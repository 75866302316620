import React from 'react'
import t from 'utils/translation'
import Template205 from './205'
import Template301 from './301'
import Template302 from './302'
import Template303 from './303'
import Template305 from './305'
import Template306 from './306'
import Template308 from './308'
import Template401 from './401'
import Template403 from './403'
import Template404 from './404'
import Template405 from './405'
import Template416 from './416'
import Template418 from './418'
import GeneralDisclosures from './GeneralDisclosures'
import { UnknownTemplate } from 'components/Template'

const templates = {
  doc205: Template205,
  doc301: Template301,
  doc302: Template302,
  doc303: Template303,
  doc305: Template305,
  doc306: Template306,
  doc308: Template308,
  doc401: Template401,
  doc403: Template403,
  doc404: Template404,
  doc405: Template405,
  doc416: Template416,
  doc418: Template418,
  generalDisclosures: GeneralDisclosures,
  default: UnknownTemplate,
}

const Template = props => {
  const type = isFinite(props.type) ? `doc${props.type}` : props.type

  return React.createElement(templates[type] ?? UnknownTemplate, {
    ...props,
    t: t(`drafts.${type}`),
  })
}

export default Template
