import enrichDraft from 'utils/enrichDraft'
import * as combustion from './combustion'
import * as electricityHeatingCooling from './electricityHeatingCooling'
import * as travel from './travel'
import * as leasedCars from './leasedCars'

export const sections = enrichDraft({
  combustion,
  electricityHeatingCooling,
  travel,
  leasedCars,
})

export const scoreValues = data => [
  Object.values(data.combustion?.items ?? {})
    .map(sections.combustion.calculateScore)
    .reduce((a, b) => a + b, 0),

  Object.values(data.electricityHeatingCooling?.items ?? {})
    .map(sections.electricityHeatingCooling.calculateScore)
    .reduce((a, b) => a + b, 0),
  Object.values(data.travel?.items ?? {})
    .map(sections.travel.calculateScore)
    .reduce((a, b) => a + b, 0) +
    Object.values(data.leasedCars?.items ?? {})
      .map(sections.leasedCars.calculateScore)
      .reduce((a, b) => a + b, 0),
]

export const createSummary = data =>
  scoreValues(data).map((total, i) => ({
    id: i,
    title: `Scope ${i + 1}`,
    subtitle: `Total scope ${i + 1} emissions in tonnes CO2e`,
    score: {
      value: total,
      type: 'quantity',
      unit: 'tonnes CO2e',
    },
  }))
