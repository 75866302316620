import styled from 'styled-components'
import { BaseTemplate } from 'components/Template'
import { sections } from './meta'

import Introduction from './Introduction'

const StyledBaseTemplate = styled(BaseTemplate)`
  aside {
    max-width: 60vw;
    width: 60rem;

    form {
      fieldset {
        display: flex;
        align-items: flex-end;
        > * {
          flex-grow: 1;
          margin-right: 1rem;
          &:last-child {
            margin-right: 0;
          }
        }
        &:last-of-type {
          margin-bottom: 1rem;
        }
      }
    }
  }
  && form fieldset > div + div {
    margin-top: 0;
  }
`

const Template = props => (
  <StyledBaseTemplate meta={sections} {...props}>
    <Introduction />
  </StyledBaseTemplate>
)

export default Template
