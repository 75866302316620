import { tint, transparentize } from 'polished'

const colors = {
  black: '#000000',
  white: '#FFFFFF',
  olive: '#688155',
  green: '#00AD6B',
  red: '#EB2638',
  blue: '#1f497d',
  orange: '#e18841',
}

const contextualColors = {
  primary: colors.blue,
  secondary: colors.orange,
  primaryText: tint(0.15, colors.black),
  dark: tint(0.15, colors.black),
  medium: tint(0.5, colors.black),
  mediumLight: tint(0.7, colors.black),
  light: tint(0.9, colors.black),
  background: tint(0.95, colors.black),
  border: tint(0.8, colors.black),
  shadow: transparentize(0.9, colors.black),
  success: colors.green,
  warning: colors.red,
}

const theme = {
  typography: {
    fontFamily: `Open Sans, sans-serif`,
    fontSize: '0.8rem',
  },
  sizes: {
    sm: '100%',
    md: '1024px',
    lg: '1024px',
  },
  breakpoints: {
    sm: '640px',
    md: '1024px',
    lg: '1280px',
  },
  gutterWidth: '16px',
  colors: {
    ...colors,
    ...contextualColors,
  },
}

export default theme
