import { useFirestore } from 'providers/Firestore'
import styled from 'styled-components'
import _t from 'utils/translation'
import Loader from 'components/Loader'
import { BaseTemplate } from 'components/Template'

const StyledBaseTemplate = styled(BaseTemplate)`
  aside {
  }
`

const allIndicators = [
  'doc205',
  'doc301',
  'doc302',
  'doc303',
  'doc305',
  'doc306',
  'doc308',
  'doc403',
  'doc401',
  'doc404',
  'doc405',
  'doc416',
  'doc418',
  'generalDisclosures',
]

const sections = {
  projects: {
    type: 'list',
    title: 'Projects',
    format: {
      title: 'foo',
      score: ({ id }) => ({
        type: 'list',
        items: [
          {
            type: 'url',
            value: `${window.location.origin}/${id}/respondents`,
            label: 'Data collection',
          },
          {
            type: 'url',
            value: `${window.location.origin}/${id}/results`,
            label: 'Results',
          },
        ],
      }),
    },
    initialValues: {
      name: '',
      description: '',
      indicators: allIndicators,
    },
    fields: {
      name: { type: 'string' },
      description: { type: 'textArea' },
      indicators: {
        type: 'options',
        options: allIndicators,
      },
    },
  },
}

const projectTemplate = ({ id, name, description, indicators }) => ({
  _acl: {},
  _meta: {
    id,
    name,
    description,
    indicators,
  },
})

const Template = ({ projects, className, updateProject }) => {
  const t = _t('projectOverview')
  const data = {
    _type: 'projectOverview',
    projects: {
      items: projects.map(({ _meta }) => ({
        id: _meta.id,
        name: _meta.name,
        description: _meta.description,
        indicators: _meta.indicators ?? allIndicators,
      })),
    },
  }

  const save = sectionType => formKey => data => {
    updateProject(data.id, projectTemplate(data), { merge: true })
  }

  return (
    <StyledBaseTemplate
      meta={sections}
      data={data}
      save={save}
      deleteItem={false}
      projectMeta={{}}
      t={t}
    ></StyledBaseTemplate>
  )
}

const Home = () => {
  const { data, loading, set } = useFirestore('projects')

  return loading ? (
    <Loader />
  ) : data ? (
    <Template projects={data} updateProject={set} />
  ) : (
    <p>Nothing</p>
  )
}

export default Home
