import _t from 'utils/translation'
import BaseTemplate from '../BaseTemplate.jsx'
import { sections } from '../../Draft/308/meta'

const criteria = {
  environmental: 'Environmental',
  labour: 'Labour Rights',
  humanRights: 'Human Rights',
  antiCorruption: 'Anti-corruption',
}

const createFormTable = (title, form, subForm) => ({
  type: 'dataTable',
  title,
  tableMeta: {
    fields: {
      respondentEmail: { label: 'Respondent' },
      tag: { label: 'Tag' },
      total: {
        label: 'Total',
        format: 'number',
      },

      ...Object.fromEntries(
        Object.entries(criteria).map(([criterion, label]) => [
          criterion,
          { label, format: 'number' },
        ])
      ),
    },
    columns: Object.keys(criteria),
    rowKeys: ['tag', 'respondent'],
    columnTotals: true,
  },
  transformData: report =>
    Object.fromEntries(
      Object.keys(criteria).map(criterion => [
        criterion,
        report[form][subForm][criterion].amount ??
          report[form][subForm][criterion],
      ])
    ),
})

const resultSections = {
  screeningQuantitative: {
    id: 'supplier.forms.screening',
    type: 'dataTable',
    tableMeta: {
      fields: {
        numberOfSuppliers: {
          label: 'Total suppliers',
          format: 'number',
        },
        newSuppliers: {
          label: 'New suppliers',
          format: 'number',
        },
        screenedEnvironmental: {
          label: 'Environmental criteria',
          format: 'number',
        },
        screenedLabor: {
          label: 'Labor rights criteria',
          format: 'number',
        },
        screenedHR: {
          label: 'Human rights criteria',
          format: 'number',
        },
        screenedAntiCorruption: {
          label: 'Anti-corruption criteria',
          format: 'number',
        },
        respondentEmail: { label: 'Respondent' },
        tag: { label: 'Tag' },
      },
      columns: [
        'numberOfSuppliers',
        'newSuppliers',
        'screenedEnvironmental',
        'screenedLabor',
        'screenedHR',
        'screenedAntiCorruption',
      ],
      rowKeys: ['tag', 'respondent'],
      columnTotals: true,
    },
    transformData: report => ({
      numberOfSuppliers: report.screening.numberOfSuppliers,
      newSuppliers: report.screening.newSuppliers.total,
      screenedEnvironmental: report.screening.newSuppliers.environmental,
      screenedLabor: report.screening.newSuppliers.labour,
      screenedHR: report.screening.newSuppliers.humanRights,
      screenedAntiCorruption: report.screening.newSuppliers.antiCorruption,
    }),
  },

  qualitativeScreening: {
    id: 'supplier',
    title: 'Supplier Screening (qualitative)',
    respondentSelect: true,
    forms: {
      screening: {
        title: 'Screening',
        type: 'qualitative',
      },
    },
  },

  quantitativeAssessment: {
    id: 'supplier',
    title: 'Supplier Assessment (quantitative)',
    forms: {
      assessed: createFormTable(
        'Number of assessed suppliers',
        'assessment',
        'numberOfAssessedSuppliers'
      ),
      impacted: createFormTable(
        'Number of suppliers with negative impacts',
        'assessment',
        'numberOfImpactedSuppliers'
      ),
      improving: createFormTable(
        'Number of suppliers which have agreed to improvements',
        'assessment',
        'numberOfImprovingSuppliers'
      ),
      terminated: createFormTable(
        'Number of terminated suppliers',
        'assessment',
        'numberOfTerminatedSuppliers'
      ),
    },
  },
  qualitativeAssessment: {
    id: 'supplier',
    title: 'Supplier Assessment (qualitative)',
    respondentSelect: true,
    forms: {
      assessment: {
        title: 'Assessment',
        type: 'qualitative',
      },
    },
  },
}

const Template = ({ projectId, data }) => (
  <BaseTemplate
    t={_t(`drafts.doc308.sections`)}
    meta={sections}
    resultSections={resultSections}
    data={data}
  />
)

export default Template
