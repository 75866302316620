import styled from 'styled-components'
import { BaseTemplate } from 'components/Template'
import { sections } from './meta'

const StyledBaseTemplate = styled(BaseTemplate)`
  aside {
    max-width: 60vw;
    width: 30rem;
  }
`

const Template = props => (
  <StyledBaseTemplate meta={sections} {...props}></StyledBaseTemplate>
)

export default Template
