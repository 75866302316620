import calculateProgress from '../../../../utils/calculateProgress'
const meta = {
  score: calculateProgress,
  fields: {
    identification: {
      type: 'section',
      fields: {
        applicable: {
          type: 'options',
          options: [true, false],
          visible: () => true,
        },
        description: {
          type: 'textArea',
          visible: data => data.identification.applicable,
        },
        responsible: {
          type: 'textArea',
          visible: data => data.identification.applicable,
        },
        qualityAssurance: {
          type: 'textArea',
          visible: data => data.identification.applicable,
        },
        usage: {
          type: 'textArea',
          visible: data => data.identification.applicable,
        },
      },
    },
    reporting: {
      type: 'section',
      fields: {
        applicable: {
          type: 'options',
          options: [true, false],
        },
        description: {
          type: 'textArea',
          visible: data => data.reporting.applicable,
        },
      },
    },
    removal: {
      type: 'section',
      fields: {
        applicable: {
          type: 'options',
          options: [true, false],
        },
        description: {
          type: 'textArea',
          visible: data => data.removal.applicable,
        },
      },
    },
    repraisalProtection: {
      type: 'textArea',
    },
    investigation: {
      type: 'textArea',
    },
  },

  initialValues: {
    identification: {
      applicable: null,
      description: '',
      responsible: '',
      qualityAssurance: '',
      usage: '',
    },
    reporting: {
      applicable: null,
      description: '',
    },
    removal: {
      applicable: null,
      description: '',
    },
    repraisalProtection: '',
    investigation: '',
  },
}

export default meta
