import calculateProgress from 'utils/calculateProgress'
import enrichDraft from 'utils/enrichDraft'

export const sections = enrichDraft({
  corruption: {
    type: 'static',
    forms: {
      corruption: {
        score: calculateProgress,
        initialValues: {
          applicable: null,
          numberOfIncidents: '',
          numberOfEmployeeActions: '',
          numberOfContractActions: '',
          anyLegalCases: null,
          description: '',
        },
        fields: {
          applicable: { type: 'options', options: [true, false] },
          numberOfIncidents: {
            type: 'int',
            visible: ({ applicable }) => applicable,
          },
          numberOfEmployeeActions: {
            type: 'int',
            visible: ({ applicable }) => applicable,
          },
          numberOfContractActions: {
            type: 'int',
            visible: ({ applicable }) => applicable,
          },
          anyLegalCases: {
            type: 'options',
            options: [true, false],
            visible: ({ applicable }) => applicable,
          },
          description: {
            type: 'textArea',
            visible: ({ applicable, anyLegalCases }) =>
              applicable && anyLegalCases,
          },
        },
      },
    },
  },
})
