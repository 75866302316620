import styled from 'styled-components'
import { theme } from 'styled-tools'
import BlockContent from 'components/BlockContent'

const Section = styled.section`
  background: ${theme('colors.white')};
  border-radius: 12px;
  padding: 1rem 1.5rem;
  margin: 1rem 0;
  box-shadow: 0px 0px 8px ${theme('colors.shadow')};
`

const TitleGroup = styled.hgroup`
  text-align: left;
  border-bottom: 1px solid ${theme('colors.primary')};
  padding: 0.2rem 0.7rem 0.7rem;
  margin: 0rem -0.7rem 0;
`

const Title = styled.h2``

const Subtitle = styled.h3`
  color: ${theme('colors.medium')};
  margin-top: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
`

const Card = ({ title, subtitle, description, children }) => (
  <Section>
    <TitleGroup>
      <Title>{title}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </TitleGroup>
    {description && <BlockContent blocks={description} />}
    {children}
  </Section>
)

export default Card
