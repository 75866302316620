import enrichDraft from 'utils/enrichDraft'

export const sections = enrichDraft({
  main: {
    type: 'static',
    forms: {
      trainingHours: {
        score: ({ hoursOfTraining } = {}) => {
          const sum = ['categoryX', 'categoryY', 'categoryZ'].reduce(
            (acc, category) =>
              acc +
              ['male', 'female'].reduce(
                (genderAcc, gender) =>
                  genderAcc +
                  (isFinite(hoursOfTraining?.[category]?.[gender])
                    ? Number(hoursOfTraining[category][gender])
                    : 0),
                0
              ),
            0
          )
          return {
            type: 'quantity',
            value: sum,
            unit: 'hours',
          }
        },
        initialValues: {
          hoursOfTraining: {
            categoryX: {
              male: '',
              female: '',
            },
            categoryY: {
              male: '',
              female: '',
            },
            categoryZ: {
              male: '',
              female: '',
            },
          },
          typeOfTraining: '',
          providesSustainabilityTraining: null,
          sustainabilityTrainingProvided: '',
        },
        fields: {
          hoursOfTraining: {
            type: 'section',
            fields: {
              categoryX: {
                type: 'section',
                fields: {
                  male: {
                    type: 'number',
                  },
                  female: {
                    type: 'number',
                  },
                },
              },
              categoryY: {
                type: 'section',
                fields: {
                  male: {
                    type: 'number',
                  },
                  female: {
                    type: 'number',
                  },
                },
              },
              categoryZ: {
                type: 'section',
                fields: {
                  male: {
                    type: 'number',
                  },
                  female: {
                    type: 'number',
                  },
                },
              },
            },
          },
          typeOfTraining: {
            type: 'textArea',
          },
          providesSustainabilityTraining: {
            type: 'options',
            options: [true, false],
          },
          sustainabilityTrainingProvided: {
            type: 'textArea',
            visible: ({ providesSustainabilityTraining }) =>
              providesSustainabilityTraining,
          },
        },
      },
      performanceReview: {
        score: ({ numberOfEmployees } = {}) => {
          const sum = Object.values(numberOfEmployees ?? {}).reduce(
            (acc, category) =>
              acc +
              Object.values(category).reduce(
                (genderAcc, gender) =>
                  genderAcc + (isFinite(gender) ? Number(gender) : 0),
                0
              ),
            0
          )
          return {
            type: 'quantity',
            value: sum,
            unit: 'employees',
          }
        },
        initialValues: {
          numberOfEmployees: {
            categoryX: {
              male: '',
              female: '',
            },
            categoryY: {
              male: '',
              female: '',
            },
            categoryZ: {
              male: '',
              female: '',
            },
          },
        },
        fields: {
          numberOfEmployees: {
            type: 'section',
            fields: {
              categoryX: {
                type: 'section',
                fields: {
                  male: {
                    type: 'int',
                  },
                  female: {
                    type: 'int',
                  },
                },
              },
              categoryY: {
                type: 'section',
                fields: {
                  male: {
                    type: 'int',
                  },
                  female: {
                    type: 'int',
                  },
                },
              },
              categoryZ: {
                type: 'section',
                fields: {
                  male: {
                    type: 'int',
                  },
                  female: {
                    type: 'int',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
})
