import styled from 'styled-components'
import { theme } from 'styled-tools'
import { IconButton } from 'components/Button'
import Checkbox from 'components/form/Checkbox'

const FilterWrapper = styled.div`
  position: relative;
`

const FilterDialogWrapper = styled.div`
  text-align: left;
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${theme('colors.white')};
  color: black;
  font-weight: normal;
  padding: 1.5rem 0.5rem 0.3rem 0.5rem;
  border-radius: 2px;
  box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.5);
`

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 0.1rem;
  right: 0.1rem;
  height: auto;
  width: auto;
  margin: 0;
  padding: 0;
  svg {
    height: 0.8rem;
    width: 0.8rem;
  }
`

const SortSection = styled.div`
  display: flex;
  align-items: center;
  label {
    display: block;
    margin: 0;
    margin-right: 2rem;
  }
`

const SortButton = styled(IconButton)`
  height: auto;
  width: auto;
  margin: 0;
  padding: 0.1rem;
  svg {
    height: 0.8rem;
    width: 0.8rem;
  }
`

const FilterSection = styled.div`
  white-space: nowrap;
  h2 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    padding-bottom: 0.2rem;
    font-size: 0.8rem;
    text-transform: none;
    font-weight: bold;
    border-bottom: 1px solid ${theme('colors.border')};
  }
`

const FilterButton = styled(IconButton)`
  height: 1rem;
  width: auto;
  margin: 0;
  margin-left: 1rem;
  padding: 0;
  svg {
    height: 0.8rem;
    width: 0.8rem;
  }
`

const CheckList = ({ values, onChange }) => (
  <ul>
    <li>
      <Checkbox
        name="all"
        label="Check all"
        value={values.find(v => v.value === false) === undefined}
        onChange={e =>
          onChange('all', values.find(v => v.value === false) === undefined)
        }
      />
    </li>
    {values.map(v => (
      <li key={v.label}>
        <Checkbox
          name={v.label}
          label={v.label}
          value={v.value}
          onChange={e => onChange(v.label, v.value)}
        />
      </li>
    ))}
  </ul>
)

const FilterDialog = ({
  columnValues,
  currentSortOrder,
  toggleSortOrder,
  currentFilter,
  changeFilter,
  close,
}) => {
  return (
    <FilterDialogWrapper>
      <CloseButton inverted={true} icon="close" onClick={close} />
      <SortSection>
        <label>Sort</label>
        <SortButton
          inverted={currentSortOrder === undefined}
          icon={currentSortOrder === 'desc' ? 'arrowUp' : 'arrowDown'}
          onClick={toggleSortOrder}
        />
      </SortSection>
      {changeFilter && (
        <FilterSection>
          <h2>Filter</h2>
          <CheckList
            values={columnValues.map(v => ({
              label: v,
              value: currentFilter[v] ?? true,
            }))}
            onChange={changeFilter}
          />
        </FilterSection>
      )}
    </FilterDialogWrapper>
  )
}

const Filter = ({
  columnValues,
  currentSortOrder,
  toggleSortOrder,
  currentFilter,
  changeFilter,
  close,
  isFilterDialogOpen,
  setFilterDialogOpen,
}) => (
  <FilterWrapper>
    <FilterButton
      icon="filter"
      inverted={
        currentFilter === undefined ||
        Object.values(currentFilter).find(v => v === false) === undefined
      }
      onClick={() => setFilterDialogOpen(!isFilterDialogOpen)}
    />
    {isFilterDialogOpen && (
      <FilterDialog
        columnValues={columnValues}
        currentSortOrder={currentSortOrder}
        toggleSortOrder={toggleSortOrder}
        currentFilter={currentFilter}
        changeFilter={changeFilter}
        close={() => setFilterDialogOpen(false)}
      />
    )}
  </FilterWrapper>
)

export default Filter
