import _t from 'utils/translation'
import BaseTemplate from '../BaseTemplate.jsx'
import { sections } from '../../Draft/404/meta'

const resultSections = {
  trainingHours: {
    id: 'main',
    type: 'dataTable',
    tableMeta: {
      fields: {
        catXMale: { label: 'Male X', format: 'number' },
        catXFemale: { label: 'Female X', format: 'number' },
        catYMale: { label: 'Male Y', format: 'number' },
        catYFemale: { label: 'Female Y', format: 'number' },
        catZMale: { label: 'Male Z', format: 'number' },
        catZFemale: { label: 'Female Z', format: 'number' },
        total: { label: 'total', format: 'number' },
        respondentEmail: { label: 'Respondent' },
        tag: { label: 'Tag' },
      },
      columns: [
        'catXMale',
        'catXFemale',
        'catYMale',
        'catYFemale',
        'catZMale',
        'catZFemale',
        'total',
      ],
      rowKeys: ['respondentEmail', 'tag'],
      columnTotals: true,
    },
    transformData: report => ({
      catXMale: report.trainingHours.hoursOfTraining.categoryX.male,
      catXFemale: report.trainingHours.hoursOfTraining.categoryX.female,
      catYMale: report.trainingHours.hoursOfTraining.categoryY.male,
      catYFemale: report.trainingHours.hoursOfTraining.categoryY.female,
      catZMale: report.trainingHours.hoursOfTraining.categoryZ.male,
      catZFemale: report.trainingHours.hoursOfTraining.categoryZ.female,
    }),
  },

  qualitative: {
    id: 'main',
    title: 'Training Details',
    respondentSelect: true,
    forms: {
      trainingHours: {
        title: 'Types of training',
        type: 'qualitative',
        fields: {
          hoursOfTraining: { visible: false },
        },
      },
    },
  },

  perfReviews: {
    id: 'main',
    title: 'Number of Performance Reviews',
    type: 'dataTable',
    tableMeta: {
      fields: {
        catXMale: { label: 'Male X', format: 'number' },
        catXFemale: { label: 'Female X', format: 'number' },
        catYMale: { label: 'Male Y', format: 'number' },
        catYFemale: { label: 'Female Y', format: 'number' },
        catZMale: { label: 'Male Z', format: 'number' },
        catZFemale: { label: 'Female Z', format: 'number' },
        total: { label: 'total', format: 'number' },
        respondentEmail: { label: 'Respondent' },
        tag: { label: 'Tag' },
      },
      columns: [
        'catXMale',
        'catXFemale',
        'catYMale',
        'catYFemale',
        'catZMale',
        'catZFemale',
        'total',
      ],
      rowKeys: ['respondentEmail', 'tag'],
      columnTotals: true,
    },
    transformData: report => ({
      catXMale: report.performanceReview.numberOfEmployees.categoryX.male,
      catXFemale: report.performanceReview.numberOfEmployees.categoryX.female,
      catYMale: report.performanceReview.numberOfEmployees.categoryY.male,
      catYFemale: report.performanceReview.numberOfEmployees.categoryY.female,
      catZMale: report.performanceReview.numberOfEmployees.categoryZ.male,
      catZFemale: report.performanceReview.numberOfEmployees.categoryZ.female,
    }),
  },
}

const Template = ({ projectId, data }) => (
  <BaseTemplate
    t={_t(`drafts.doc404.sections`)}
    meta={sections}
    resultSections={resultSections}
    data={data}
  />
)

export default Template
